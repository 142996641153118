/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef, useContext, useEffect } from "react";
import { useGLTF, useAnimations, Html } from "@react-three/drei";
import useSound from 'use-sound';
import { MathUtils } from 'three';
import { useFrame, useThree } from '@react-three/fiber'
import { MediaContext } from '../../App/App';
import { AppContext } from '../../App/App';


import themodel from '../../../assets/models/Origami.glb';

import LabelOrigami from '../../LabelOrigami/LabelOrigami'
import UnfoldingAnimation from '../../../assets/sounds/Unfolding_Animation.mp3';


export default function Model(props) {
  const group = useRef();
  const OBJ = useRef();
  const { nodes, materials, animations } = useGLTF(themodel);
  const { actions } = useAnimations(animations, group);
  const { stateMedia } = useContext(MediaContext);
  const { state } = useContext(AppContext);
  const { viewport } = useThree()




  //the Rotation of the Origami (from the menu and theme buttons)
  useFrame(() => {
    group.current.rotation.y = MathUtils.lerp(group.current.rotation.y, (props.grRot), 0.1);
  })





  //Sound of the unfolding Origami
  const [unfoldingSound] = useSound(
    UnfoldingAnimation,
    {
      volume: 0.5,
      interrupt: true,
      soundEnabled: stateMedia.isSoundEnabled
    }
  );


  useFrame(() => {
    if (props.index === '' || state.isAnimationDone === true) { return }
    //console.log(actions[props.index]);
    actions[props.index].repetitions = 1
    actions[props.index].play();
    actions[props.index].timeScale = 0;



    if (actions[props.index].time > 1) {

      actions[props.index].timeScale = 1;
    }


    if (actions[props.index].time > 6 || state.isAnimationDone === true) {
      actions[props.index].timeScale = 0;
      actions[props.index].time = 10;
      props.isDone.setDone(true)
    }


  })


  //control if the user reload the page 

    useEffect(() => {
    
    if (props.location !== '/' || state.isAnimationDone === true) { 
      
        actions['Animation'].play();
        actions['Animation'].timeScale = 0;
        actions['Animation'].time = 10;
        props.isDone.setDone(true)
    }
  }, [props.location])



  useEffect(() => {

    if (props.index === '' || state.isAnimationDone === true) { return }
    if (actions[props.index].timeScale === 1 && actions[props.index].time > 1) {
      unfoldingSound()
    }

    const setFromEvent = (e) => {

      if (props.isRunning === true) {
        if (((e.movementX < 0) || (e.deltaY < 0)) && actions[props.index].time < 1) {
          actions[props.index].time += 0.08;
        }

        if (((e.movementX > 0) || (e.deltaY > 0)) && actions[props.index].time < 1) {
          actions[props.index].time -= 0.08;
        }

        //Touch Events
        if (e.touches) {
          if (((e.touches[0].pageX - props.touchStart) < 0) && actions[props.index].time < 1) {
            actions[props.index].time += 0.08;
          }

          if ((((e.touches[0].pageX - props.touchStart) > 0)) && actions[props.index].time < 1) {
            actions[props.index].time -= 0.08;
          }
        }

      }

    }




    window.addEventListener("mousemove", setFromEvent);
    window.addEventListener("touchmove", setFromEvent);
    window.addEventListener("wheel", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
      window.addEventListener("touchmove", setFromEvent);
      window.removeEventListener("wheel", setFromEvent);

    };
  }, [props.index, props.isRunning, actions]);



  //<group name="Scene" rotation={[0, Math.PI / 2, 0]} >
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene" rotation={[0, Math.PI / 2, 0]}

        scale={(viewport.height < viewport.width) ? viewport.height / 8.2 : viewport.width / 8.2}

      >

        <Html
          zIndexRange={[10, 0]}
          style={{ pointerEvents: 'none' }}
          occlude={[OBJ]}
          position={[props.mousePosition.x, props.mousePosition.y, props.mousePosition.z]}
          className="html-story-label">

          <LabelOrigami labelText={props.labelText} isHover={props.isHover} />


        </Html>

        <group
          name="Armature-Origami"
          position={[0.21, -0.17, -0.66]}
          rotation={[0.49, 0.48, -0.35]}
          scale={[1.21, 1.21, 1.21]}
          userData={{ name: "Armature-Origami" }}
        >
          <primitive object={nodes.Bone001} />
          <primitive object={nodes.Bone007} />
          <primitive object={nodes.Bone002} />
          <primitive object={nodes.Bone006} />
          <primitive object={nodes.Bone003} />
          <primitive object={nodes.Bone005} />
          <primitive object={nodes.Bone004} />
          <primitive object={nodes.Bone008} />
          <primitive object={nodes.Bone} />
          <group ref={OBJ} name="Origami" userData={{ name: "Origami" }}>
            <skinnedMesh
              name="Origami_1"
              geometry={nodes.Origami_1.geometry}
              material={materials.Origami}
              skeleton={nodes.Origami_1.skeleton}
            />
            <skinnedMesh
              name="Origami_2"
              geometry={nodes.Origami_2.geometry}
              material={materials.Cover}
              skeleton={nodes.Origami_2.skeleton}
            />
          </group>
        </group>
        <mesh
          name="SHD_plane"
          castShadow
          receiveShadow
          geometry={nodes.SHD_plane.geometry}
          material={materials.Material}
          rotation={[0, -Math.PI / 2, 0]}
          scale={0}
          userData={{ name: "SHD_plane" }}
        />
        <mesh
          name="obj_bucket"
          castShadow
          receiveShadow
          geometry={nodes.obj_bucket.geometry}
          material={materials.Bucket}
          position={[0.71, 0.25, 0.3]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_bucket" }}
        />
        <mesh
          name="obj_guitar"
          castShadow
          receiveShadow
          geometry={nodes.obj_guitar.geometry}
          material={materials.Guitar}
          position={[-0.46, 0.06, 0.79]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_guitar" }}
        />
        <mesh
          name="obj_lego"
          castShadow
          receiveShadow
          geometry={nodes.obj_lego.geometry}
          material={materials.Lego}
          position={[-0.78, 0.07, -0.7]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_lego" }}
        />
        <mesh
          name="obj_tent"
          castShadow
          receiveShadow
          geometry={nodes.obj_tent.geometry}
          material={materials.Tent}
          position={[-0.37, 0.17, -0.33]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_tent" }}
        />
        <mesh
          name="obj_flag"
          castShadow
          receiveShadow
          geometry={nodes.obj_flag.geometry}
          material={materials.Clue}
          position={[0.72, 0.19, 0.8]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_flag" }}
        />
        <mesh
          name="obj_pencil"
          castShadow
          receiveShadow
          geometry={nodes.obj_pencil.geometry}
          material={materials.Crayon}
          position={[-0.36, 0.04, -0.87]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_pencil" }}
        />
        <mesh
          name="obj_saucepan"
          castShadow
          receiveShadow
          geometry={nodes.obj_saucepan.geometry}
          material={materials.Saucepan}
          position={[0.23, 0.11, -0.38]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_saucepan" }}
        />
        <mesh
          name="obj_boots"
          castShadow
          receiveShadow
          geometry={nodes.obj_boots.geometry}
          material={materials.Boots}
          position={[0.28, 0.15, 0.59]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_boots" }}
        />
        <mesh
          name="obj_book"
          castShadow
          receiveShadow
          geometry={nodes.obj_book.geometry}
          material={materials.Book}
          position={[-0.25, 0.02, 0.29]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_book" }}
        />
        <mesh
          name="obj_hats"
          castShadow
          receiveShadow
          geometry={nodes.obj_hats.geometry}
          material={materials.Hats}
          position={[-0.7, 0.05, 0.39]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_hats" }}
        />
        <mesh
          name="obj_cards"
          castShadow
          receiveShadow
          geometry={nodes.obj_cards.geometry}
          material={materials.Cards}
          position={[0.46, 0.01, -0.72]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_cards" }}
        />
        <mesh
          name="obj_computer"
          castShadow
          receiveShadow
          geometry={nodes.obj_computer.geometry}
          material={materials.Computer}
          position={[0.68, 0.08, -0.29]}
          rotation={[-Math.PI, 0, -Math.PI]}
          scale={0}
          userData={{ name: "obj_computer" }}
        />
      </group>
    </group>
  );
}

useGLTF.preload(themodel);







import React, { useReducer, useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import useSound from 'use-sound';

import turn_Origami from '../../assets/sounds/Turn_Origami.mp3';

import './App.css';

import { Box, Grid, Footer, Text, Stack, Image } from 'grommet';
import styled, { css } from 'styled-components';

import { textObj } from '../../assets/text/text';


import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Breakpoint } from 'react-socks';
import { useMatomo } from '@datapunt/matomo-tracker-react'


import FrameObject from '../FrameObject/FrameObject';
import SideMenu from '../SideMenu/SideMenu';
import Activities from '../Activities/Activities';
import About from '../About/About';

import OrigamiAnimation from '../LandingPage/OrigamiAnimation'

import Backdrop from '../../assets/images/BK_DropPaper.svg'
import BottomCard from '../../assets/images/MainPage/ThemeMessage/Bottom.svg'
import TopCard from '../../assets/images/MainPage/ThemeMessage/Top.svg'
import BK_LandingPage_Text from '../../assets/images/LandingPage_Rectangle.svg'
import ArrowOpen from '../../assets/images/MainPage/ArrowOpen.svg'
//import TextDragOpen from '../../assets/images/MainPage/TextDragOpen.svg'
//import TextDragRotate from '../../assets/images/MainPage/TextDragRotate.svg'
import TextDragOpen from '../../assets/images/MainPage/TextDragOpen.png'
import TextDragRotate from '../../assets/images/MainPage/TextDragRotate.png'


import Gallery from '../Gallery/Gallery'; //Gallery from the Focused Objet label

import ThemeButtons from '../ThemeButtons/ThemeButtons';
import LowerButtons from '../LowerButtons/LowerButtons';

import LogoPITP from '../../assets/images/logo/VA_PITP_Logo.svg'


//The AppContext is used to control the states of the panels
export const AppContext = React.createContext({ state: true });
const initialState = {
  isAnimationDone: false,
  isFrameOpen: false,
  isCardOpen: false,
  isGalleryOpen: false,
};

function reducer(state, action) {
  switch (action.type) {

    case 'UPDATE_FRAME':
      return {
        ...state,
        isAnimationDone: action.isAnimationDone,
        isFrameOpen: action.isFrameOpen,
        isCardOpen: action.isCardOpen,
        isGalleryOpen: action.isGalleryOpen,
      };

    default:
      return initialState;
  }
}

//The ObjContext is used to control the text from the JSON and the various components
export const ObjContext = React.createContext({ stateObj: true });

const initialStateObj = {
  idTheme: undefined,
  idObj: undefined,
  annotation: undefined,
  data: undefined
};

function objReducer(stateObj, action) {

  switch (action.type) {
    case 'OBJ_UPDATE':
      return {
        ...stateObj,
        idTheme: action.idTheme,
        idObj: action.idObj,
        annotation: action.annotation,
        data: action.data
      };

    default:
      return initialStateObj;
  }
}

//The Meida Context is used to control the sounds and other media element in the App
export const MediaContext = React.createContext({ stateMedia: true });

const initialStateMedia = {
  isSoundEnabled: true
};

function mediaReducer(stateMedia, action) {

  switch (action.type) {
    case 'MEDIA_UPDATE':
      return {
        ...stateMedia,
        isSoundEnabled: action.isSoundEnabled
      };

    default:
      return initialStateMedia;
  }
}


//FiberContext is used to control the general theme and the rotation of the Origami
export const FiberContext = React.createContext({ stateFiber: true });

const initialView = {
  helperScaleOrigami: [1, 1, 1],
  helperScaleObj: [1, 1, 1],
  yRotation: 0, 
  idTheme: "0",
  themeName: "Constructing"
};

function changeCamera(stateFiber, action) {
  switch (action.type) {


    case 'ROTATE_MODEL':
      return {
        ...stateFiber,
        yRotation: action.data.value,
        idTheme: action.data.id,
        themeName: action.data.name,
        helperScaleOrigami: stateFiber.helperScaleOrigami,
        helperScaleObj: stateFiber.helperScaleObj
      };

    case 'HIDE_OBJ':
      return {
        ...stateFiber,
        yRotation: stateFiber.yRotation,
        idTheme: stateFiber.idTheme,
        themeName: stateFiber.themeName,
        helperScaleOrigami: action.helperScaleOrigami,
        helperScaleObj: action.helperScaleObj
      };




    case 'CHANGEVIEW': //used with the perspective camera
      return {
        ...stateFiber,
        yRotation: action.data.value,
        idTheme: action.data.id,
        themeName: action.data.name,

      };

    default:
      return initialView;
  }
}

//Router context
export const RouterContext = React.createContext({ stateRouter: true });


/*Custom Styles*/
const BoxText = styled(Text)
  `
  font-family: Source Sans Pro;   
  color: #E1F19; 
   font-weight: 700;
   font-size: 2.25rem;
   line-height: 2rem;
   margin-bottom:1rem;
   padding-left:0.9375rem;
    
   ${props => props.primary && css`
   font-weight: 400;
   font-size: 1.25rem;
   line-height: 1.5rem;
   padding-right:1.25rem;
   padding-left:1.25rem;
   `}
  `

function App() {

  const [state, dispatch] = useReducer(reducer, initialState);
  const [stateFiber, dispatchFiber] = useReducer(changeCamera, initialView);
  const [stateObj, dispatchObj] = useReducer(objReducer, initialStateObj);
  const [stateMedia, dispatchMedia] = useReducer(mediaReducer, initialStateMedia);


  const [stateBkg, SetStateBkgr] = useState(false);
  const [page, setPage] = useState('');

  // Track MatomoView
  const { enableLinkTracking,trackPageView } = useMatomo()
    
  enableLinkTracking()

  // Track page view
React.useEffect(() => {
  trackPageView()
}, [])


  const location = useLocation()
  const navigate = useNavigate()

  function navigateClick() { //Used for the logo
    dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
    navigate("/enter/Constructing");

  }

  //Effect used to link location to rotation of the model
  useEffect(() => {

    switch (location.pathname) {
      case '/enter':
        SetStateBkgr(true)
        break;

      case '/enter/Constructing':
        dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "0", "name": "Constructing", "value": 0 } })
        SetStateBkgr(true)
        break;

      case '/enter/Constructing/Dens':
          dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "0", "name": "Constructing", "value": 0 } })
          
          dispatchObj({
            type: 'OBJ_UPDATE',
            data: textObj[stateFiber.idTheme].objects['obj_tent'].annotations,
            idTheme: stateFiber.idTheme,
            idObj: 'obj_tent',
            annotation:'PlayTheory'
        })
        
        dispatch({ type: 'UPDATE_FRAME', isAnimationDone:true, isFrameOpen:true, isCardOpen:undefined, isGalleryOpen:undefined });
          SetStateBkgr(true)
          break;
      
          case '/enter/Constructing/Art':
            dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "0", "name": "Constructing", "value": 0 } })
            
            dispatchObj({
              type: 'OBJ_UPDATE',
              data: textObj[stateFiber.idTheme].objects['obj_pencil'].annotations,
              idTheme: stateFiber.idTheme,
              idObj: 'obj_pencil',
              annotation:'PlayTheory'
          })
          
          dispatch({ type: 'UPDATE_FRAME', isAnimationDone:true, isFrameOpen:true, isCardOpen:undefined, isGalleryOpen:undefined });
            SetStateBkgr(true)
            break;

            case '/enter/Constructing/Small_World_Play':
              dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "0", "name": "Constructing", "value": 0 } })
              
              dispatchObj({
                type: 'OBJ_UPDATE',
                data: textObj[stateFiber.idTheme].objects['obj_lego'].annotations,
                idTheme: stateFiber.idTheme,
                idObj: 'obj_lego',
                annotation:'PlayTheory'
            })
            
            dispatch({ type: 'UPDATE_FRAME', isAnimationDone:true, isFrameOpen:true, isCardOpen:undefined, isGalleryOpen:undefined });
              SetStateBkgr(true)
              break;

      case '/enter/Imagining':
        dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "1", "name": "Imagining", "value": -Math.PI / 2 } })
        SetStateBkgr(true)
        break;

      case '/enter/Exploring':
        dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "2", "name": "Exploring", "value": Math.PI } })
        SetStateBkgr(true)
        break;

      case '/enter/Innovating':
        dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": "3", "name": "Innovating", "value": Math.PI / 2 } })
        SetStateBkgr(true)
        break;

      default:
        console.log('NoPath');
        break;
    }
  }, [location]) // <-- here put the parameter to listen

  const roomArray=['Constructing', 'Imagining', 'Exploring', 'Innovating']

  const [clickSound] = useSound(
    turn_Origami,
    { volume: 0.25,
        soundEnabled: stateMedia.isSoundEnabled
     }
);
  //Swipe function 

  const handlers = useSwipeable({
    onSwipedLeft: () => {
        //console.log('Swipe Left');
        if(location.pathname.includes('enter') && state.isFrameOpen === undefined)
        {
          let idTheme = ''; 
          
          if(parseInt(stateFiber.idTheme)<3){
            idTheme=(parseInt(stateFiber.idTheme)+1).toString();
          }
          else{idTheme="0";}
          navigate("/enter/" + roomArray[parseInt(idTheme)]);
          clickSound()
          //console.log(roomArray[parseInt(idTheme)]);

          dispatchFiber({ type: 'ROTATE_MODEL', data: { "id":idTheme, "name": roomArray[parseInt(idTheme)], "value": (stateFiber.yRotation - Math.PI / 2) } })
        }
    
    },
    onSwipedRight: () => {
      //console.log('Swipe Right');
      if(location.pathname.includes('enter') && state.isFrameOpen === undefined)
      {
        
        let idTheme = ''; 
        
        if(parseInt(stateFiber.idTheme)>0){
          idTheme=(parseInt(stateFiber.idTheme)-1).toString();
        }
        else{idTheme='3'}

        navigate("/enter/" + roomArray[parseInt(idTheme)]);
        clickSound()
      dispatchFiber({ type: 'ROTATE_MODEL', data: { "id":idTheme, "name": roomArray[parseInt(idTheme)], "value": (stateFiber.yRotation + Math.PI / 2) } })
      }
  },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });


/*
  // DEBUG PURPOSES
  useEffect(() => {
    console.log('The Object State:');
    console.log(stateObj);
    console.log('The Panel State:');
    console.log(state);
  }, [stateObj, state])
  */
 
  const exclusionArray = [
    '/activities',
    '/abouts',
  ]

  
  //'#F7F2EE' color background landing page
  return (
    <div className="App" id="outer-container" >
        <AppContext.Provider value={{ state, dispatch }}>
          <MediaContext.Provider value={{ stateMedia, dispatchMedia }}>
            <FiberContext.Provider value={{ stateFiber, dispatchFiber }}>
              <ObjContext.Provider value={{ stateObj, dispatchObj }}>


                <Box id="page-wrap"  className='page' background={stateBkg ? { image: "url('" + Backdrop + "')", size: 'cover', repeat: "no-repeat", } : { image: "url('" + Backdrop + "')", size: 'cover', repeat: "no-repeat", }}>

                  <Grid

                    fill
                    width={'100vw'}
                    height={'100vh'}
                    rows={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                    columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                    //gap="xsmall"

                    areas={[
                      { name: 'leftBorder', start: [0, 0], end: [0, 17] },

                      { name: 'headerMenu', start: [1, 0], end: [2, 3] },
                      { name: 'headerLogo', start: [4, 0], end: [11, 3] },
                      { name: 'menuLeft', start: [1, 3], end: [2, 17] },
                      { name: 'spaceMenu', start: [3, 0], end: [3, 17] },
                      { name: 'main', start: [4, 3], end: [11, 15] },
                      { name: 'contentRight', start: [12, 3], end: [14, 15] },

                      { name: 'rightBorder', start: [15, 0], end: [15, 17] },

                      { name: 'footer', start: [1, 16], end: [14, 17] },
                    ]}

                  >
                    <Box gridArea="headerMenu" direction='row' align='center' >
                      <Routes>
                        <Route path="/enter/*" element={<SideMenu />}></Route>
                      </Routes>

                      <Routes>
                        <Route path="/activities" element=
                          {
                            <Stack fill='horizontal' anchor="top-right">
                              <SideMenu />
                              <Activities />
                            </Stack>
                          }>
                        </Route>

                        <Route path="/about" element=
                          {
                            <Stack fill='horizontal' anchor="top-right">
                              <SideMenu />
                              <About />
                            </Stack>
                          }>
                        </Route>
                      </Routes>
                    </Box>




                    <Box gridArea='headerLogo' direction='row' align='center' >

                      <Box alignContent='center' fill='horizontal' height={{ max: '72px' }}>


                        <Box animation={{ type: 'fadeIn' }}>
                          <Image style={{ zIndex: '450' }} src={LogoPITP} fit='contain' onClick={navigateClick} alt='play in the pandemic logo' />
                        </Box>

                      </Box>

                    </Box>

                    <Box gridArea='leftBorder' fill='horizontal'></Box> {/*empty space*/}
                    <Box gridArea='rightBorder' fill='horizontal'></Box> {/*empty space*/}
                    <Box gridArea='spaceMenu' fill='horizontal'> </Box> {/*empty space*/}


                    <Box gridArea="menuLeft" >
                      <Routes>
                        <Route path="/enter/*" element={
                          <Breakpoint large up>
                            <ThemeButtons />
                          </Breakpoint>
                        }></Route>
                      </Routes>
                    </Box>

                    {/* Main content of the Origami*/}
                    <Box gridArea="main" align='center' alignContent='center' {...handlers} >

                      <Routes>

                        <Route path="/*" element={

                          <Stack fill='horizontal' anchor="bottom">
                            <OrigamiAnimation pageRouter={{ page, setPage }} />


                            {(exclusionArray.indexOf(location.pathname) < 0) && (

                              <FrameObject room={'enter/' + location.pathname} />)}


                            {location.pathname !== '/' ?
                              <Box animation={{ type: 'fadeIn', delay: 3000 }} direction='row' alignContent='center' justify='center' >
                                <Box width={'16.4rem'} height={'1.75rem'} alignSelf='center' >
                                </Box>
                                <Box width={'31.25rem'} height={'9.25rem'} alignSelf='center' justify='center'
                                  background={{ image: "url('" + ArrowOpen + "')", backgroundPosition: 'center center', backgroundSize: 'contain', repeat: "no-repeat", }}  >
                                </Box>
                                <Box width={'17.4rem'} height={'1.75rem'} alignSelf='center' >
                                  <Image src={TextDragRotate} fit='contain' alignSelf='end' alt='click and drag to rotate'  />
                                </Box>
                              </Box>
                              :
                              <Box>


                                <Box animation={{ type: 'fadeIn', delay: 3000 }} direction='row' alignContent='center' justify='center' >
                                  <Box width={'16.4rem'} height={'1.75rem'} alignSelf='center' >
                                  </Box>
                                  <Box width={'31.25rem'} height={'9.25rem'} alignSelf='center' justify='center'
                                    background={{ image: "url('" + ArrowOpen + "')", backgroundPosition: 'center center', backgroundSize: 'contain', repeat: "no-repeat", }}  >
                                  </Box>
                                  <Box width={'16.4rem'} height={'1.75rem'} alignSelf='center' >
                                    <Image src={TextDragOpen} fit='contain' alignSelf='end' alt='click and drag to open' />
                                  </Box>
                                </Box>



                                <Box animation={{ type: 'fadeIn' }} width={'45.75rem'} height={'6.75rem'} alignSelf='center'
                                  background={{ image: "url('" + BK_LandingPage_Text + "')", backgroundPosition: 'center center', backgroundSize: 'cover', repeat: "no-repeat", }}  >
                                  <BoxText primary margin={{ left: '2.25rem', right: '2.25rem', top: '1.375rem' }} textAlign='center'>
                                    Discover how play has empowered children's resilience, resourcefulness, wellbeing, and creativity during the COVID-19 pandemic in 2020-22
                                  </BoxText>
                                </Box>
                              </Box>
                            }

                          </Stack>
                        }>
                        </Route>

                      </Routes>

                      <Gallery />

                    </Box>

                    {/* Content Right Theme Cards */}

                    <Routes>
                      <Route path="/enter/*" element=
                        {
                          <Box gridArea="contentRight" direction="column" gap='0'>

                            <Box animation={{ type: 'fadeIn' }} 
                                width={{ max: '21.375rem' }}
                                //width={{min:'18.75vw' }}
                                >
                              <Box 
                                  width={{ max: '21.375rem' }} 
                                  //width={{min:'18.75vw' }}
                                  height={{min:'75px'}} 
                                  //height={{min:'6.25vw' }}
                                  justify='end' 
                                style={{
                                  zIndex: 10,
                                  WebkitMaskImage: "url('" + TopCard + "')", maskImage: "url('" + TopCard + "')",
                                  WebkitMaskSize: 'contain',
                                  WebkitMaskRepeat: 'no-repeat',
                                  WebkitMaskPosition:'left bottom',
                                }}
                                background={{ color: textObj[stateFiber.idTheme].color, }}>
                              <BoxText textAlign='start'  >{textObj[stateFiber.idTheme].title}</BoxText>  
                              </Box>

                              <Box 
                              width={{ max: '21.375rem' }} 
                              //width={{min:'18.75vw' }}
                              
                              background={{ color: textObj[stateFiber.idTheme].color, }}>

                                <BoxText textAlign='start' primary>{textObj[stateFiber.idTheme].content}</BoxText>
                              </Box>


                              <Box 
                              width={{ max: '21.375rem' }} 
                              //height={'60.81px'}
                              //width={{min:'18.75vw' }}
                              height={{min:'6.25vw' }}

                                style={{
                                  zIndex: 10,
                                  WebkitMaskImage: "url('" + BottomCard + "')", maskImage: "url('" + BottomCard + "')",
                                  WebkitMaskSize: 'contain',
                                  WebkitMaskRepeat: 'no-repeat',
                                  backgroundPosition: 'top'
                                  
                                }}
                                background={{ color: textObj[stateFiber.idTheme].color }}>
                              </Box>
                            </Box>
                          </Box>
                        }>
                      </Route>
                    </Routes>



                    {/* Footer  justify="end" */}
                    <Footer gridArea="footer" >
                      <Routes>

                        <Route path="/" element={<LowerButtons />}></Route>
                       
                        <Route path="/enter/*" element={<LowerButtons />}></Route>

                        <Route path="/activities" element={<LowerButtons />}></Route>

                        <Route path="/about" element={<LowerButtons />}></Route>

                      </Routes>



                    </Footer>

                  </Grid>

                </Box>
              </ObjContext.Provider>
            </FiberContext.Provider>
          </MediaContext.Provider>
        </AppContext.Provider>
    </div >
  );
}

export default App;
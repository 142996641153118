import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { ObjContext } from '../App/App';
import { MediaContext } from '../App/App';

import Credits from '../Credits/Credits';

import BK_Activities from '../../assets/images/BK_Activities.svg'
import Backdrop from '../../assets/images/BK_DropPaper.svg'


import { Layer, Box, Button, Image, Text, Grommet, Grid } from 'grommet';
import BT_BackOrigami from '../../assets/images/buttons/BT_BackOrigami.svg'

import LogoPITP from '../../assets/images/logo/VA_PITP_Logo.svg'
import useSound from 'use-sound';
import UI_Click from '../../assets/sounds/UI_Click.mp3';
import UI_Hover from '../../assets/sounds/UI_Hover.mp3';


import styled, { css } from 'styled-components';

import { useNavigate } from 'react-router-dom';


const About = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const { stateFiber, dispatchFiber } = useContext(FiberContext);
    const { stateObj, dispatchObj } = useContext(ObjContext);
    const { stateMedia} = useContext(MediaContext);

    const [openActivities, setOpenActivities] = useState(true);

    const onOpenActivities = () => setOpenActivities(true);
    const onCloseActivities = () => setOpenActivities(undefined);


    const [UIClick] = useSound(
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );

    

    const navigate = useNavigate();

    function navigateClick() {
        //clickSound
        UIClick()

        dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
        dispatchFiber({
            type: 'HIDE_OBJ',
            helperScaleOrigami:[1,1,1],
            helperScaleObj:[1,1,1],
        })
        navigate("/enter/Constructing");
    }


  function navigateClickMenu() { //Used for the logo
    dispatchFiber({
        type: 'HIDE_OBJ',
        helperScaleOrigami:[1,1,1],
        helperScaleObj:[1,1,1],
    })

    navigate("/enter/Constructing");

  }


    const theme = {
        layer: {
            zIndex: "21",
        }
    };

    const BoxText = styled(Text)
        `
  font-family: Source Sans Pro;   
  color: #E1F19; 
   font-weight: 700;
   font-size: 2.625rem;
   text-align: left;
   line-height: 2rem;
   margin-bottom:1.5rem;
   padding-left:1rem;
   
   ${props => props.primary && css`
   font-weight: 400;
   margin-top:4.6875rem;
   font-size: 1.25rem;
   line-height: 1.5625rem;
   `}
  `

    const BoxTextTeam = styled(Text)
        `
  font-family: Source Sans Pro;   
  color: #E1F19; 
  text-align: left;
  line-height: 2rem;
   
   ${props => props.team && css`
   font-weight: 700;
   font-size: 1.25rem;
   line-height: 1.5625rem;
   `}

   ${props => props.person && css`
   font-weight: 500;
   font-size: 1.25rem;
   line-height: 1.5625rem;
   `}

   ${props => props.role && css`
   font-weight: 400;
   font-size: 1.25rem;
   line-height: 1.5625rem;
   `}

  `



    return (
        <Grommet theme={theme}>
            <Layer
                animate={false}
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: '#F7F2EE',
                    //backgroundImage: "url('"+Backdrop+"')", 
                    //backgroundRepeat: 'no-repeat',
                    //backgroundSize:'cover'
                }}
            >
                <Grid

                    fill
                    width={'100vw'}
                    height={'100vh'}
                    rows={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                    columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                    //gap="xsmall"

                    areas={[
                        { name: 'leftBorder', start: [0, 0], end: [0, 17] },
                        { name: 'headerMenu', start: [1, 0], end: [2, 4] },
                        { name: 'headerLogo', start: [4, 0], end: [11, 3] },
                        { name: 'menuLeft', start: [1, 3], end: [2, 17] },
                        { name: 'main', start: [2, 3], end: [14, 15] },
                        //{ name: 'contentRight', start: [14, 0], end: [14, 15] },

                        { name: 'rightBorder', start: [15, 0], end: [15, 17] },

                        { name: 'footer', start: [0, 16], end: [14, 17] },
                    ]}

                >

                    <Box gridArea='leftBorder'/>
                    <Box gridArea='rightBorder'/>

                    <Box gridArea='headerMenu'/>

                    <Box gridArea='headerLogo' fill='vertical' justify='center'>
                        <Box alignContent='center'  fill='horizontal' height={{ max: '72px' }}>
                            <Box >
                                <Image style={{ zIndex: '40' }} src={LogoPITP} fit='contain' onClick={navigateClickMenu} />
                            </Box>
                        </Box>
                    </Box>



                    {/*Back button to change with Origami*/}
                    <Box gridArea='menuLeft'


                        pad={{ left: '0rem', top: '0rem' }} >

                        <Box aria-label="back" 
                            onClick={() => { navigateClick() }} 
                            focusIndicator={false}>
                            <Image className='icon' src={BT_BackOrigami} width={60} alt='back' />
                        </Box>

                    </Box>


                    <Box gridArea='main' >
                        <Box flex >
                            <Credits />
                        </Box>

                    </Box>

                    <Box gridArea='contentRight' direction="column" fill='vertical' alignContent='between' />

                    <Box gridArea='footer' />
                </Grid>
            </Layer>


        </Grommet>
    );
};

export default About;
export const teamText =

{
    "Team": {
      "organisation": [
        {
          "name": "Play in the Pandemic Online Exhibition Team",
          "persons": [
            {
              "name": "Katy Canales",
              "role": "Online Exhibition Producer",
              "organisation": "Young V&A",
              "website": "https://www.vam.ac.uk/info/young"
            },
            {
              "name": "Juliette Coquet, Sindi Breshani and Dimitris Menexopoulos",
              "role": "Online Interaction Designers",
              "organisation": "Episod Studio",
              "website": "https://episod.studio"
            },
            {
              "name": "Marcus Walters",
              "role": "Designer and illustrator",
              "organisation": "Marcus Walters Studio",
              "website": "https://www.marcuswalters.com"
            }
          ]
        },
        {
          "name": "V&A Project Team",
          "persons": [
            {
              "name": "Sophie Sage",
              "role": "Content Manager and Interpretation Producer",
              "organisation": "Young V&A",
              "website": "https://www.vam.ac.uk/info/young"
            },
            {
              "name": "Catherine Ritman-Smith",
              "role": "Head of Learning and Engagement",
              "organisation": "Young V&A",
              "website": "https://www.vam.ac.uk/info/young"
            },
            {
              "name": "Joanna Norman",
              "role": "",
              "organisation": "Director of the V&A Research Institute, NAL and Archives",
              "website": ""
            }
          ]
        },
        {
          "name": "UCL and University of Sheffield Project Team",
          "persons": [
            {
              "name": "Professor John Potter",
              "role": "Project Lead",
              "organisation": "IOE, UCL’s Faculty For Education and Society",
              "website": "https://www.ucl.ac.uk/ioe/"
            },
            {
              "name": "Dr Valerio Signorelli",
              "role": "Co-Investigator",
              "organisation": "UCL Centre for Advanced Spatial Analysis",
              "website": "https://www.ucl.ac.uk/bartlett/casa/"
            },
            {
              "name": "Dr Kate Cowan",
              "role": "Co-Investigator",
              "organisation": "IOE, UCL’s Faculty For Education and Society",
              "website": "https://www.ucl.ac.uk/ioe/"
            },
            {
              "name": "Dr Yinka Olusoga",
              "role": "Co-Investigator",
              "organisation": "School of Education at the University of Sheffield",
              "website": "https://www.sheffield.ac.uk/education"
            },
            {
              "name": "Dr Catherine Bannister",
              "role": "Research Associate",
              "organisation": "School of Education at the University of Sheffield",
              "website": "https://www.sheffield.ac.uk/education"
            },
            {
              "name": "Dr Julia Bishop",
              "role": "Research Associate",
              "organisation": "School of Education at the University of Sheffield",
              "website": "https://www.sheffield.ac.uk/education"
            },
            {
              "name": "Dr Michelle Cannon",
              "role": "Co-Investigator",
              "organisation": "IOE, UCL’s Faculty For Education and Society",
              "website": "https://www.ucl.ac.uk/ioe/"
            }
          ]
        },
        {
          "name": "External Partners",
          "persons": [
            {
              "name": "Luke Turner and Sian Spencer-Little",
              "role": "",
              "organisation": "The Play Team at Great Ormond Street Hospital",
              "website": ""
            },
            {
              "name": "Jonnie Robinson",
              "role": "",
              "organisation": "Lead Curator of Spoken English, British Library",
              "website": ""
            },
            {
              "name": "Steve Roud",
              "role": "Consultant Archivist",
              "organisation": "Folklorist and Librarian",
              "website": ""
            }
          ]
        }
      ]
    }
  }
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Layer } from 'grommet';
import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { ObjContext } from '../App/App';

import { Grid, Box, Text, Image, Stack, Button, Grommet } from 'grommet';
import { Close, LinkPrevious } from 'grommet-icons';
import BkgrMenuBt from '../../assets/images/buttons/BkgrMenuBt.svg'

import styled, { css } from 'styled-components';
import { textObj } from '../../assets/text/text';
import ReactPlayer from 'react-player';

import StackGrid, { transitions } from "react-stack-grid";
import QuoteBkgrd from '../../assets/images/Quote.svg';


import BK_Gallery from '../../assets/images/Gallery.svg'

const BoxText = styled(Text)
    `
   font-family: Source Sans Pro;  
   font-weight: 700;
   font-size: 2.25rem;
   text-align: left;
   line-height: 1.57rem;
   margin-bottom:1rem;
   padding-left:1rem;
   ${props => props.primary && css`
   font-weight: 400;
   font-size: 1.25rem;
   line-height: 1.57rem;
   font-style: normal;
   white-space: pre-line;
   `}
   ${props => props.quote && css`
   font-weight: 400;
   font-size: 1.25rem;
   line-height: 1.57rem;
   `}
   ${props => props.mainQuote && css`
   font-weight: 400;
   font-size: 1.5rem;
   line-height: 2.25rem;
   font-style: italic;
   `}
   ${props => props.quoteGallery && css`
   font-weight: 400;
   font-size: 1.5rem;
   line-height: 2.25rem;
   font-style: italic;
   overflow: hidden;
   `}
  `

const theme = {
    layer: {
        zIndex: "25",
    }
};


const Gallery = () => {
    const { state, dispatch } = useContext(AppContext);
    const { stateObj } = useContext(ObjContext);
    const [isOpenItem, setIsOpenItem] = useState(undefined);
    const [selectedMedia, setSelectedMedia] =useState({mediaUrl:'', itemID:0});

    const { stateFiber } = useContext(FiberContext);
    const { helix } = transitions;

    const grid = useRef(null);

    useEffect(() => {
        if (stateObj.isCardOpen) {
            setTimeout(() => {
                grid.current.updateLayout();
            }, 500);
        }
    }, [stateObj.isCardOpen]);


const StackGridScroll = styled(StackGrid)
        `
::-webkit-scrollbar-thumb {
    background: ${textObj[stateFiber.idTheme].color};
}
`

   


    return (
        <Grommet theme={theme}> 
            {/*{(state.isOpenCard) && (*/}
            {(state.isGalleryOpen) && (
                <Layer
                    style={{ width: '87.5vw', height: '95%' }}
                    background={{
                        //image: "url('/play-pandemic/format/texture/Tx_Green.jpg')", 
                        image: "url('"+BK_Gallery+"')",
                        size: 'cover',
                        repeat: "no-repeat",
                    }}
                    //full={'false'}
                    position={'center'}
                    onEsc={() => dispatch({
                        type: 'UPDATE_FRAME',
                        isAnimationDone:state.isAnimationDone,
                        isFrameOpen: state.isFrameOpen,
                        isCardOpen: state.isCardOpen,
                        isGalleryOpen: undefined
                    })}
                    onClickOutside={() => dispatch({
                        type: 'UPDATE_FRAME',
                        isAnimationDone:state.isAnimationDone,
                        isFrameOpen: state.isFrameOpen,
                        isCardOpen: state.isCardOpen,
                        isGalleryOpen: undefined
                    })}
                >
                    <Grid
                        fill
                        width={'100%'}
                        height={'100%'}
                        rows={['small', 'flex', 'xxsmall']}
                        columns={['6.25vw', '75vw' ,'6.25vw']}
                        gap="none"
                        alignContent='stretch'
                        areas={[
                            { name: 'close', start: [0, 0], end: [0, 0] },
                            { name: 'text', start: [1, 0], end: [1, 0] },
                            { name: 'gallery', start: [1, 1], end: [1, 1] },
                            { name: 'marginRight', start: [2, 1], end: [2, 1] },
                            { name: 'bottomBorder', start: [0, 2], end: [1, 2] },
                        ]}
                    >
                        <Box gridArea='close' pad={{ left: '2rem', top: '3rem' }}  >
                            <Button
                                style={{
                                    zIndex: '30',
                                    WebkitMaskImage: "url('"+BkgrMenuBt+"')",
                                    WebkitMaskSize: 'contain',
                                    WebkitMaskRepeat: 'no-repeat',
                                    backgroundColor: '#DFD5CD'
                                }}
                                icon={<Close size='20' />}
                                onClick={() => {
                                    dispatch({
                                        type: 'UPDATE_FRAME',
                                        isAnimationDone:state.isAnimationDone,
                                        isFrameOpen: state.isFrameOpen,
                                        isCardOpen: state.isCardOpen,
                                        isGalleryOpen: undefined
                                    })
                                }} 
                                focusIndicator={false}
                                />
                        </Box>
                        <Box gridArea='text' pad={{ top: '3rem' }} width="50%">
                            {/*<BoxText onClick={() => { grid.current.updateLayout() }}>{textObj[stateFiber.idTheme].title}</BoxText>
                            <BoxText textAlign='justify' primary>{textObj[stateFiber.idTheme].content}</BoxText>*/}



                            <BoxText onClick={() => { grid.current.updateLayout(); console.log('Update'); }}>{stateObj.data[stateObj.annotation].title}</BoxText>
                            <BoxText textAlign='justify' primary>{stateObj.data[stateObj.annotation].text}</BoxText>


                        </Box>


                        <Box gridArea='gallery'
                            pad={{ right: '1rem' }}
                            
                        >

                            <StackGridScroll style={{ marginTop: '50px', overflowY: 'auto', overflowX:'hidden' }}
                                ref={grid}
                                columnWidth={220}
                                gutterWidth={20}
                                gutterHeight={20}
                                monitorImagesLoaded={true}

                                appear={helix.appear}
                                appeared={helix.appeared}
                                enter={helix.enter}
                                entered={helix.entered}
                                leaved={helix.leaved}
                            >


                                {
                                    stateObj.data[stateObj.annotation].items.map((item, index) => {
                                        let urlMedia = ''
                                        let urlVimeo = ''

                                        if (item.media === 'true') {
                                            urlMedia = "https://play-observatory.com/user/pages/vexhibitmedia/" + stateObj.data[stateObj.annotation].id + "/" + item.file
                                            //console.log(urlMedia.substring(0,urlMedia.lastIndexOf('.'))+'.mp4');
                                        }

                                        if (item.media.includes('video')) {
                                            urlMedia = "https://play-observatory.com/user/pages/vexhibitmedia/" + stateObj.data[stateObj.annotation].id + "/" + item.file
                                            urlVimeo = item.video
                                            //console.log(urlMedia.substring(0,urlMedia.lastIndexOf('.'))+'.mp4');
                                        }


                                        if (item.media === 'true') {
                                            return (
                                                <Box key={index} justify="center" alignSelf="center" width="medium" height={{max:'medium'}} onClick={(e) => { 
                                                    console.log(e); 
                                                    setIsOpenItem(true); 
                                                    setSelectedMedia({mediaUrl:e.target.currentSrc, itemID:parseInt(e.target.id)})
                                                    
                                                    }}>
                                                    <Image id={index} fit="contain" src={urlMedia} />
                                                </Box>
                                            )
                                        }

                                        if (item.media.includes('video')) {
                                            return (
                                                <Box key={index} justify="center" alignSelf="center" width="medium" height={{max:'small'}}  onClick={(e) => { 
                                                    //console.log(e);
                                                    setIsOpenItem(true); 
                                                    //setSelectedMedia({mediaUrl:e.target.currentSrc.substring(0,e.target.currentSrc.lastIndexOf('.'))+'.mp4', itemID:parseInt(e.target.id)})
                                                    setSelectedMedia({mediaUrl:e.target.classList[2], itemID:parseInt(e.target.id)})
                                                    
                                                    }}>
                                                    <Image id={index} className={urlVimeo} fit="contain" src={urlMedia} />
                                                </Box>
                                            )
                                        }

                                        

                                        if (item.media === 'false') {
                                            return (

                                                <Box key={index} justify="center" overflow={'hidden'} alignSelf="center" width="medium" onClick={(e) => { console.log(e); setIsOpenItem(true); setSelectedMedia({mediaUrl:'', itemID:parseInt(e.target.id)}) }}>
                                                    <Stack anchor="top-left">
                                                        <Image id={index}  fit='contain' src={QuoteBkgrd} />
                                                        <Box id={index} height={'20rem'} pad={{ bottom: 'large', top: 'medium', left: 'medium', right: 'medium' }} >
                                                            <BoxText id={index} quoteGallery>{item.content.citation}</BoxText>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            )
                                        }


                                    }

                                    )

                                }


                            </StackGridScroll>


                        </Box>

                        <Box gridArea='marginRight'/>

                        <Box gridArea='bottomBorder'/>
                    </Grid>








                </Layer>

            )}


            {/*ITEM SELECTED FRAME*/}
            {(isOpenItem) && (
                <Layer
                    style={{ width: '87.5vw', height: '95%' }}
                    background={{
                        //image: "url('/play-pandemic/format/texture/Tx_Green.jpg')", 
                        image: "url('"+BK_Gallery+"')",
                        size: 'cover',
                        repeat: "no-repeat",
                    }}
                    //full={'vertical'}
                    position={'center'}
                    onEsc={() => setIsOpenItem(undefined)}
                    onClickOutside={() => setIsOpenItem(undefined)}
                >

                    <Grid
                        fill
                        width={'100%'}
                        height={'100%'}
                        rows={['xxsmall', 'xxsmall', 'flex', 'xxsmall']}
                        //columns={['xsmall', '1/2','6.25vw', '1/2','6.25vw']}
                        columns={['6.25vw', '37.5vw','6.25vw', '37.5vw','6.25vw']}
                        gap="none"
                        alignContent='stretch'
                        areas={[
                            { name: 'topBorder', start: [0, 0], end: [4, 0] },
                            { name: 'header', start: [0, 1], end: [0, 1] },
                            { name: 'item', start: [1, 1], end: [1, 2] },
                            { name: 'marginMiddle', start: [2, 1], end: [2, 2] },
                            
                            { name: 'description', start: [3, 1], end: [3, 2] },
                            { name: 'marginRight', start: [4, 1], end: [4, 2] },
                            { name: 'bottomBorder', start: [0, 3], end: [4, 3] },
                        ]}
                    >
                        <Box gridArea='header' justify='center' align='center' >
                            <Button
                                style={{
                                    zIndex: '30',
                                    WebkitMaskImage: "url('"+BkgrMenuBt+"')",
                                    WebkitMaskSize: 'contain',
                                    WebkitMaskRepeat: 'no-repeat',
                                    backgroundColor: '#DFD5CD'
                                }}
                                icon={<LinkPrevious size='20' />}
                                onClick={() => { setIsOpenItem(undefined) }} focusIndicator={false} />
                        </Box>

                                <Box gridArea='marginMiddle'/>

                        <Box gridArea='description' pad={{ right: '10rem', top: '3rem' }} width="100%" fill='vertical' alignContent='center' justify='center'>
                            <Box>
                            <BoxText>{stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.title} </BoxText>
                            <BoxText textAlign='justify' primary quote>
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.citation}

                            </BoxText>
                            <BoxText textAlign='justify' primary>
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.author}<br />
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.year}<br />
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.location}<br />
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.note}<br />
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.PO_Ref}<br />

                            </BoxText>
                            </Box>
                        </Box>



                        <Box gridArea='item'>

                            <Box
                                justify='center'
                                height={'100%'} 
                              fill='vertical'

                            >
                                {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].media.includes('true') ?

                                    <Box 
                                    align='center'  
                                    //pad={'3rem'}

                                        style={{
                                           //backgroundImage: "url('" + Cadre_Quote + "')",
                                           //backgroundColor:'#DFD4CB',
                                           //backgroundSize: 'contain',
                                           //backgroundRepeat: 'no-repeat',
                                        }}
                                    >

                                        {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].media.includes('video') ?
                                      
                                      <Box width={{max:'37.5vw'}} flex>
                                        <ReactPlayer 
                                        
                                       // height={'100%'}
                                        width={'37.5vw'}
                                        controls={true}
                                       // light={true}
                                        

                                        style={{
                                            borderStyle: 'solid',
                                            borderWidth: '10px',
                                            borderColor:'#DFD4CB',
                                            backgroundColor:'#DFD4CB',
                                         }}
                                        
                                        //url='https://vimeo.com/1084537' //test video
                                        url={selectedMedia.mediaUrl} 
                                        />
                                        </Box>
                                        
                                        :
                                        
                                        <Box 
                                            height={'100%'} 
                                            pad='10px'
                                            style={{
                                                //backgroundImage: "url('" + Cadre_Quote + "')",
                                               backgroundColor:'#DFD4CB',
                                                //backgroundSize: 'contain',
                                                //backgroundRepeat: 'no-repeat',
                                             }}
                                        >
                                        <Image
                                            fit='contain'
                                            style={{
                                                
                                                outlineStyle:'solid',
                                                outlineWidth: '10px',
                                                outlineColor:'#DFD4CB',
                                             }}
                                        src={selectedMedia.mediaUrl} 

                                        />
                                        </Box>
                                        }

                                    </Box>
                                    :



                                    <Box align='center'
                                    //fill='vertical'
                                    //justify='center'
                                    
                                        //pad={'3rem'}
                                        style={{
                                            //backgroundImage: "url('" + Cadre_Quote + "')",
                                            backgroundColor:'#DFD4CB',
                                            //backgroundSize: 'contain',
                                            //backgroundRepeat: 'no-repeat',
                                        }}
                                    >
                                        <Box  align='center' alignSelf='center'
                                            margin={{ left: '6.25vw', top: '3rem', bottom: '3rem', right: '6.25vw' }}
                                            style={{
                                                borderLeftWidth: '0.25rem',
                                                borderLeftStyle: 'solid',
                                                borderLeftColor: '#BBA899',
                                            }}>

                                            <BoxText mainQuote>
                                            {stateObj.data[stateObj.annotation].items[selectedMedia.itemID].content.citation}
                                            </BoxText>

                                        </Box>

                                    </Box>
                                }
                            </Box>

                        </Box>

                        <Box gridArea='bottomBorder'></Box>

                    </Grid>




                </Layer>

            )}

</Grommet>
    );
};

export default Gallery;
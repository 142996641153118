import React, { useState, useContext } from 'react';
import './LowerButtons.css'
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MediaContext } from '../App/App';
import { FiberContext } from '../App/App';
import useSound from 'use-sound';

import { Box, Image, Button } from 'grommet';


import LBAudioOn from '../../assets/images/buttons/LowerButtons/LBAudio.svg'
import LBAudioOff from '../../assets/images/buttons/LowerButtons/LBAudioOff.svg'
import LBHelp from '../../assets/images/buttons/LowerButtons/LBHelp.svg'

import UI_Click from '../../assets/sounds/UI_Click.mp3';

import HelpLayer from '../HelpLayer/HelpLayer'

export default function ThemeButtons() {

    const { stateMedia, dispatchMedia } = useContext(MediaContext);
    const { dispatchFiber } = useContext(FiberContext);
    const [isOpen, SetOpen] = useState(false);



    const [UIClick] = useSound(
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );


    const onSoundClickHandler = (e) => {
        UIClick({ forceSoundEnabled: true })
        dispatchMedia({ type: 'MEDIA_UPDATE', isSoundEnabled: !stateMedia.isSoundEnabled });

    };

    const onClickHandler = (e) => {
        console.log(e);
        UIClick()
        SetOpen(true);
    };

    //font-family: 'Open Sans'; 
    const ButtonThemes = styled(Button)
        `
font-family: 'Source Sans Pro'; 
outline: none !important;
font-weight: 700;
font-weight: bold;
font-size:1.25rem;
height:2.5rem;
color: #000000;
background-color: #DFD5CD;
border-radius:40px;
width: 10rem;

${props => props.primary && css`

background-color: ${"#DFD5CD"};
      &:hover {
        background: ${props.colcol};
      }
      
      &:focus{
        background: ${props.colcol};
      }
`}
`;
    const location = useLocation();
    let navigate = useNavigate();

    function navigateClick() {

        //clickSound
        UIClick()

        dispatchFiber({
            type: 'HIDE_OBJ',
            helperScaleOrigami: [0, 0, 0],
            helperScaleObj: [0, 0, 0],
        })

        navigate("/activities");
    }

    return (
        <>
            <Box animation={{ type: 'fadeIn' }} style={{ zIndex: 22 }} direction='row' fill='horizontal' width={'100%'} >



                <Box align='start' flex margin={{ right: '100px' }} >
                    {(location.pathname === '/activities') || (location.pathname === '/') ?
                        null
                        :
                        <ButtonThemes
                            onClick={(e) => navigateClick(e)}
                            focusIndicator={false}
                            name={'Activities'}
                            id={4}>{'Activities'}</ButtonThemes>
                    }
                </Box>



                <Box direction='row' flex gap='14px' align='end' justify='end'  >
                    <Box aria-label="sound" width={'45px'}
                        onClick={(e) => { onSoundClickHandler(); 
                        //    console.log(stateMedia) 
                        }}
                        focusIndicator={false}
                    >
                        <Image className='icon' src={stateMedia.isSoundEnabled ? LBAudioOn : LBAudioOff} fit='contain' alt='sound on-off' ></Image>
                    </Box>

                    <Box aria-label="help" width={'45px'}
                        onClick={(e) => onClickHandler(e)}
                        focusIndicator={false}
                    >
                        <Image className='icon' src={LBHelp} fit='contain' alt='help' ></Image>
                    </Box>
                </Box>


            </Box>




            <HelpLayer SetOpen={{ isOpen, SetOpen }} />
        </>
    );

}
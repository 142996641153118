/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF, PresentationControls, Html } from '@react-three/drei'
import themodel from '../../../../../assets/models/focusedObjs/obj_pencil.glb';


export default function Model(props) {
    const group = useRef()
    const OBJ = useRef()
    const { nodes, materials } = useGLTF(themodel)




    return (
        <group ref={group} {...props} dispose={null}>
            <PresentationControls
                global
                speed={3}
                config={{ mass: 1, tension: 170, friction: 56 }}
                zoom={1}
                rotation={[0, -Math.PI / 4, 0]}
                polar={[-Infinity, Infinity]}
                azimuth={[-Infinity, Infinity]}>

                <group name="Scene">
                   
        <mesh
          name="obj_pencil"
          ref={OBJ}
          castShadow
          receiveShadow
          geometry={nodes.obj_pencil.geometry}
          material={materials.Crayon}
          scale={1}
          userData={{ name: "obj_pencil" }}
          />
          </group>
      </PresentationControls>
  </group>
);
}

useGLTF.preload(themodel);


import React, { useState, useContext } from 'react';
import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { MediaContext } from '../App/App';


//import BK_Activities from '../../assets/images/BK_Activities.svg'
import BK_Activities from '../../assets/images/BK_Activities_arrow.svg'


import { Layer, Box, Image, Text, Grommet, Grid, Button } from 'grommet';
import BT_BackOrigami from '../../assets/images/buttons/BT_BackOrigami.svg'
import LogoPITP from '../../assets/images/logo/VA_PITP_Logo.svg'

import useSound from 'use-sound';
import UI_Click from '../../assets/sounds/UI_Click.mp3';

import styled, { css } from 'styled-components';

import { useNavigate } from 'react-router-dom';



const Activities = (props) => {
    const { dispatch } = useContext(AppContext);
    const { dispatchFiber } = useContext(FiberContext);
    const { stateMedia } = useContext(MediaContext);

    const [openActivities, setOpenActivities] = useState(true);

    const onOpenActivities = () => setOpenActivities(true);
    const onCloseActivities = () => setOpenActivities(undefined);

    const [UIClick] = useSound(
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );

    const navigate = useNavigate();

    function navigateClick() {
        //clickSound
        UIClick()
        dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
        dispatchFiber({
            type: 'HIDE_OBJ',
            helperScaleOrigami: [1, 1, 1],
            helperScaleObj: [1, 1, 1],
        })

        navigate("/enter/Constructing");
    }

    function navigateClickMenu() { //Used for the logo
        dispatchFiber({
            type: 'HIDE_OBJ',
            helperScaleOrigami:[1,1,1],
            helperScaleObj:[1,1,1],
        })
        dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
        navigate("/enter/Constructing");

    }


    const theme = {
        layer: {
            zIndex: "21",
        }
    };

    const BoxText = styled(Text)
        `
  font-family: Source Sans Pro;   
  color: #E1F19; 
   font-weight: 700;
   font-size: 2.625rem;
   text-align: left;
   line-height: 2rem;
   margin-bottom:4.6875rem;
   padding-left:1rem;
   
   ${props => props.subtitle && css`
   font-weight: 400;
   margin-top:4.6875rem;
   margin-bottom: 1.5rem;
   font-size: 2rem;
   line-height: 1.5rem;
   `}

   ${props => props.primary && css`
   font-weight: 400;
   margin-top:0rem;
   font-size: 1.25rem;
   line-height: 1.5rem;
   `}
  `

    const ButtonThemes = styled(Button)
        `
font-family: 'Source Sans Pro'; 
outline: none !important;
font-weight: 700;
font-weight: bold;
font-size:1.25rem;
height:2.625rem;
color: #F7F2EE;
text-align:left;

text-decoration:underline;
background-color: #DFD5CD;
padding-left:21px;
border-radius:40px;
width: 10rem;
${props => props.constructing && css`
background-color: #93A4DE;
   `}

   ${props => props.imagining && css`
background-color: #EEC05A;
   `}
`;


    return (
        <Grommet theme={theme}>
            <Layer
                animate={false}
                style={{
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: "url('" + BK_Activities + "')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',

                }}
            >
                <Grid

                    fill
                    width={'100vw'}
                    height={'100vh'}
                    rows={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                    columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                    //gap="xsmall"

                    areas={[
                        { name: 'leftBorder', start: [0, 0], end: [0, 17] },
                        { name: 'headerMenu', start: [1, 0], end: [2, 3] },
                        { name: 'headerLogo', start: [4, 0], end: [11, 3] },

                        { name: 'menuLeft', start: [1, 3], end: [2, 17] },
                        { name: 'main', start: [2, 3], end: [13, 15] },
                        { name: 'contentRight', start: [14, 0], end: [14, 15] },

                        { name: 'rightBorder', start: [15, 0], end: [15, 17] },

                        { name: 'footer', start: [0, 16], end: [14, 17] },
                    ]}

                >

                    <Box gridArea='leftBorder' />
                    <Box gridArea='rightBorder' />

                    <Box gridArea='headerMenu' />

                    <Box gridArea='headerLogo' fill='vertical' justify='center'>
                        <Box alignContent='center' fill='horizontal' height={{ max: '72px' }}>
                            <Box >
                                <Image style={{ zIndex: '40' }} src={LogoPITP} fit='contain' onClick={navigateClickMenu} />
                            </Box>
                        </Box>
                    </Box>



                    {/*Back button to change with Origami*/}
                    <Box gridArea='menuLeft'

                        background={{
                            //size: 'contain',
                            repeat: "repeat",
                        }}

                        pad={{ left: '0rem', top: '0rem' }} >

                        <Box aria-label="back"
                            onClick={() => { navigateClick() }}
                            focusIndicator={false}
                        >
                            <Image className='icon' src={BT_BackOrigami} width={60} ></Image>
                        </Box>

                    </Box>


                    <Box gridArea='main'>
                        <Box width={'50%'}>
                            <BoxText>

                                Activities
                            </BoxText>

                            <BoxText primary>
                                Discover a range of creative activities for children and adults to do together. These activities are informed by children’s play during the Covid-19 pandemic. The activity packs have been co-produced by Great Ormond Street Hospital Play Specialist Team, Young V&A Museum and designed by Marcus Walters.
                            </BoxText>

                            <Box direction='row' gap='6.25vw' margin={{bottom:'5.3125rem'}}>

                                <ButtonThemes
                                    constructing
                                    className='matomo_download'
                                    onClick={(e) => {window.open ('https://play-observatory.com/user/pages/vexhibitmedia/resources/PinP-ActivitySheet_Den.pdf'); UIClick() }}
                                    focusIndicator={false}
                                    name={'Dens'}
                                    id={1}>Dens</ButtonThemes>

                                <ButtonThemes
                                imagining
                                className='matomo_download'
                                    onClick={(e) => {window.open ('https://play-observatory.com/user/pages/vexhibitmedia/resources/PinP-ActivitySheet_Story.pdf'); UIClick() }}
                                    focusIndicator={false}
                                    name={'Stories'}
                                    id={2}>Stories</ButtonThemes>

                                <ButtonThemes
                                constructing
                                className='matomo_download'
                                onClick={(e) => {window.open ('https://play-observatory.com/user/pages/vexhibitmedia/resources/PinP-ActivitySheet_WindowBox.pdf'); UIClick() }}
                                    focusIndicator={false}
                                    name={'Window Box'}
                                    id={3}>Window Box</ButtonThemes>

                            </Box>



                            <BoxText >
                                Origami House
                            </BoxText>

                            <BoxText primary>
                                Inspired by the exhibition to make your own origami house? <br /> <a href="https://play-observatory.com/user/pages/vexhibitmedia/resources/Paper_Origami.pdf" target="_blank" className='matomo_download'> Here's our easy how-to guide.</a>
                            </BoxText>



                        </Box>





                    </Box>

                    <Box gridArea='contentRight' direction="column" fill='vertical' alignContent='between' background={{
                        repeat: "repeat",
                    }}>

                    </Box>
                    <Box gridArea='footer' background={{
                        repeat: "repeat",
                    }} />
                </Grid>
            </Layer>


        </Grommet>
    );
};

export default Activities;
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { ObjContext } from '../App/App';


import Frame_Exploring from '../../assets/images/Layer_Focus/Frame_Exploring.svg'
import Frame_Innovating from '../../assets/images/Layer_Focus/Frame_Innovating.svg'
import Frame_Constructing from '../../assets/images/Layer_Focus/Frame_Constructing.svg'
import Frame_Imagining from '../../assets/images/Layer_Focus/Frame_Imagining.svg'

import LogoPITP from '../../assets/images/logo/VA_PITP_Logo.svg'


import ObjectCard from '../ObjectCard/ObjectCard';

import { Layer, Box, Image, Text, Grommet, Grid } from 'grommet';
import BT_BackOrigami from '../../assets/images/buttons/BT_BackOrigami.svg'


import { useLocation, useNavigate } from 'react-router-dom';


const FocusedObject = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const { stateFiber, dispatchFiber } = useContext(FiberContext);
    const { stateObj} = useContext(ObjContext);

    const [open, setOpen] = useState(false);
    const [frameBackground, setFrameBackground] = useState('');

    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(undefined);

    const navigate = useNavigate();
    const location = useLocation();

    function navigateClickMenu() { //Used for the logo
        dispatchFiber({
            type: 'HIDE_OBJ',
            helperScaleOrigami: [1, 1, 1],
            helperScaleObj: [1, 1, 1],
        })

        dispatch({ 
            type: 'UPDATE_FRAME', 
            isAnimationDone: true, 
            isFrameOpen: undefined, 
            isCardOpen: undefined, 
            isGalleryOpen: undefined 
        });

        navigate("/enter/Constructing");
    }


    useEffect(() => {

        if (location.pathname.includes('Constructing'))
            setFrameBackground(Frame_Constructing)

        if (location.pathname.includes('Innovating'))
            setFrameBackground(Frame_Innovating)

        if (location.pathname.includes('Exploring'))
            setFrameBackground(Frame_Exploring)

        if (location.pathname.includes('Imagining'))
            setFrameBackground(Frame_Imagining)

    }, [location]) // <-- here put the parameter to listen

    const theme = {
        layer: {
            zIndex: "10",
            container: {
                //extend: css`animation-duration:0.4s`
            } //it works but not in out
        }
    };

    return (
        <Box   >
            {(state.isFrameOpen) && (

                <Grommet theme={theme}>

                    <Layer
                        modal={false}
                        full={'vertical'}
                        position={'start'}
                        onClickOutside={onClose}
                        onEsc={onClose}
                        style={{
                            zIndex: '10',
                            width: '100vw',
                            height: '100vh',
                            backgroundImage: "url('" + frameBackground + "')",
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center'
                        }}
                        background={{

                            size: 'contain',
                            repeat: "repeat",
                        }}
                    >
                        <Grid


                            fill
                            width={'100vw'}
                            height={'100vh'}
                            rows={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}
                            columns={['flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex', 'flex']}

                            areas={[
                                { name: 'leftBorder', start: [0, 0], end: [0, 17] },
                                { name: 'headerMenu', start: [1, 0], end: [2, 3] },
                                { name: 'headerLogo', start: [4, 0], end: [11, 3] },
                                { name: 'menuLeft', start: [1, 3], end: [2, 17] },
                                { name: 'spaceMenu', start: [3, 0], end: [3, 17] },
                                { name: 'main', start: [4, 3], end: [11, 15] },

                                { name: 'contentRight', start: [10, 3], end: [14, 15] },

                                { name: 'rightBorder', start: [15, 0], end: [15, 17] },

                                { name: 'footer', start: [0, 16], end: [14, 17] },
                            ]}

                        >

                            <Box gridArea='leftBorder' />

                            <Box gridArea='spaceMenu' />

                            <Box gridArea='rightBorder' />


                            <Box gridArea='headerMenu' />
                            <Box gridArea='headerLogo' fill='vertical' justify='center' >
                                <Box alignContent='center' fill='horizontal' height={{ max: '72px' }}>
                                    <Box animation={{ type: 'fadeIn' }}>
                                        <Image style={{ zIndex: '40' }} src={LogoPITP} fit='contain' onClick={navigateClickMenu} />
                                    </Box>
                                </Box>
                            </Box>



                            {/*Back button to change with Origami*/}
                            <Box gridArea='menuLeft' pad={{ left: '0rem', top: '0rem' }} >

                                <Box aria-label="back" onClick={() => {
                                    dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
                                    navigate('enter/' + stateFiber.themeName);
                                }}
                                    focusIndicator={false}>
                                    <Image className='icon' src={BT_BackOrigami} width={60} ></Image>
                                </Box>

                            </Box>


                            <Box gridArea='main'/>
                            <Box gridArea='contentRight' direction="column" fill='vertical' alignContent='between'
                                style={{ zIndex: 50 }}
                            >
                                <ObjectCard label={stateObj.annotation} />
                            </Box>

                            <Box gridArea='footer' />
                        </Grid>
                    </Layer>
                </Grommet>
            )}
        </Box>
    );
};
export default FocusedObject;
import { Box, Text } from 'grommet';
import React from 'react';
import styled, { css } from 'styled-components';

const BoxText = styled(Text)
    `
  font-family: Source Sans Pro;   
  color: #E1F19; 
   font-weight: 700;
   font-size: 1.25rem;
    
   ${props => props.primary && css`
   font-weight: 400;
   font-size: 1.25rem;
   line-height: 1.5rem;
   padding-right:0.9375rem;
   `}
  `

/*Component for the label on the 3D object Origami Main page.
Props needed:
- props.label //the text for the tooltip
*/
const LabelOrigami = (props) => {


    return (
        <Box overflow={{ horizontal: 'hidden', vertical: 'hidden' }} >
           

{props.isHover ?
            <Box  animation={{type:'fadeIn', duration:250}}
                overflow={{ horizontal: 'hidden', vertical: 'hidden' }}
                align="center"
                width={{ min: '12rem' }}
                height={{ min: '3.125rem' }}
                background={{ color: "#DFD4CB" }}
                justify='center'
                round="medium"
                
                flex={true}
            >
                <BoxText  alignSelf='center' >{props.labelText}</BoxText>
            </Box>
            :
            null}

        </Box>
    );
}
export default LabelOrigami;
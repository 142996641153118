import React, { useState, useContext } from 'react';

import { Grid, Box, Carousel, Image, Layer, Button, Text, Grommet } from 'grommet';
import styled, { css } from 'styled-components';

import { MediaContext } from '../App/App';

import useSound from 'use-sound';
import UI_Click from '../../assets/sounds/UI_Click.mp3';

import { Radial } from 'grommet-icons';
import BkgrMenuBt from '../../assets/images/buttons/CloseButton.svg'
import TabDot from '../../assets/images/buttons/ObjCard/TabDot.svg'

import help00 from '../../assets/images/helpLayer/Help01.svg';
import help01 from '../../assets/images/helpLayer/Help02.svg';
import help02 from '../../assets/images/helpLayer/Help03.svg';
import help03 from '../../assets/images/helpLayer/Help04.svg';
import help04 from '../../assets/images/helpLayer/Help05.svg';
import help05 from '../../assets/images/helpLayer/Help06.svg';

/*Custom Styles*/
const BoxTextHelp = styled(Text)
    `
  font-family: Source Sans Pro;   
  color: #E1F19; 
   font-weight: 700;
   font-size: 2.25rem;
   line-height: 2.266rem;
   padding-left:0.9375rem;
    
   ${props => props.primary && css`
   font-weight: 400;
   font-size: 1.25rem;
   line-height: 1.5rem;
   padding-right:0.9375rem;
   `}
  `

const customTheme = {
    layer: {
        zIndex: "41",
    },
    carousel: {
        animation: {
            duration: 500,
        },
        icons: {
            color: '#78C194',
            current: Radial,
        },
    },
};


const HelpLayer = (params) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const { stateMedia } = useContext(MediaContext);

    const [UIClick] = useSound(
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );


    return (
        <Grommet theme={customTheme}>
            {params.SetOpen.isOpen &&

                <Layer
                    onEsc={() => params.SetOpen.SetOpen(false)}
                    onClickOutside={() => params.SetOpen.SetOpen(false)}

                    style={{ width: '54.9375rem', height: '32.1875rem', borderRadius: '1rem', backgroundColor: '#F7F2EE' }}
                >

                    <Grid
                        fill
                        rows={['4.25rem', '2.8125rem', 'flex', '1.125rem', '2.875rem']}
                        columns={['2.25rem', 'flex', '2.25rem']}
                        areas={[
                            { name: 'borderTop', start: [1, 0], end: [1, 0] },
                            { name: 'header', start: [1, 1], end: [1, 1] },
                            { name: 'content', start: [1, 2], end: [1, 2] },
                            { name: 'controls', start: [1, 3], end: [1, 3] },
                            { name: 'borderBottom', start: [1, 4], end: [1, 4] },
                            { name: 'borderRight', start: [2, 0], end: [0, 4] },
                            { name: 'borderLeft', start: [0, 0], end: [0, 4] },
                        ]}
                    >


                        <Box gridArea='borderTop' ></Box>
                        <Box gridArea='header' direction='row' >

                            <Box width={'45px'} aria-label="close" onClick={() => { params.SetOpen.SetOpen(false); UIClick() }} focusIndicator={false}>
                                <Image className='icon' src={BkgrMenuBt} fit='contain' ></Image>
                            </Box>


                            <BoxTextHelp>Help</BoxTextHelp>
                        </Box>



                        <Box gridArea="content" fill='vertical' justify="center" background="#F7F2EE">
                            <Carousel
                                //fill={true}
                                controls={false}
                                activeChild={activeSlide} onChild={setActiveSlide}>

                                
                                <Box width={'large'} alignSelf='center' direction='column' gap='1.375rem' >
                                    <Box alignSelf='center' height={'12.1875rem'}  >
                                        <Image src={help00} fit='contain' />
                                    </Box>
                                    <Text textAlign='center'> To move the house, hold and drag with your mouse</Text>
                                </Box>

                                <Box width={'large'} alignSelf='center' direction='column' gap='1.375rem' >
                                    <Box alignSelf='center' height={'12.1875rem'}  >
                                        <Image src={help01} fit='contain' />
                                    </Box>
                                    <Text textAlign='center'> Use the menu to access exhibition sections</Text>
                                </Box>

                                <Box width={'large'} alignSelf='center' direction='column' gap='1.375rem' >
                                    <Box alignSelf='center' height={'12.1875rem'}  >
                                        <Image src={help02} fit='contain' />
                                    </Box>
                                    <Text textAlign='center'> Discover a different theme in each room</Text>
                                </Box>

                                <Box width={'large'} alignSelf='center' direction='column' gap='1.375rem' >
                                    <Box alignSelf='center' height={'12.1875rem'}  >
                                        <Image src={help03} fit='contain' />
                                    </Box>
                                    <Text textAlign='center'> Each room holds 3 objects</Text>
                                </Box>

                                <Box width={'large'} alignSelf='center' direction='column' gap='1.375rem' >
                                    <Box alignSelf='center' height={'12.1875rem'}  >
                                        <Image src={help04} fit='contain' />
                                    </Box>
                                    <Text textAlign='center'> Each object contains exhibition content</Text>
                                </Box>

                                <Box width={'large'} alignSelf='center' direction='column' gap='1.375rem' >
                                    <Box alignSelf='center' height={'12.1875rem'}  >
                                        <Image src={help05} fit='contain' />
                                    </Box>
                                    <Text textAlign='center'> Click the icons to access information, galleries and activities</Text>
                                </Box>


                            </Carousel>
                        </Box>

                        <Box gridArea='controls' justify='center' direction='row' fill='horizontal'>
                            <ButtonHelp id='0' setSlide={{ activeSlide, setActiveSlide }} />
                            <ButtonHelp id='1' setSlide={{ activeSlide, setActiveSlide }} />
                            <ButtonHelp id='2' setSlide={{ activeSlide, setActiveSlide }} />
                            <ButtonHelp id='3' setSlide={{ activeSlide, setActiveSlide }} />
                            <ButtonHelp id='4' setSlide={{ activeSlide, setActiveSlide }} />
                           <ButtonHelp id='5' setSlide={{ activeSlide, setActiveSlide }} />




                        </Box>


                        <Box gridArea='borderBottom' ></Box>
                        <Box gridArea='borderRight' ></Box>
                        <Box gridArea='borderLeft' ></Box>

                    </Grid>
                </Layer>}
        </Grommet>
    );
};

export default HelpLayer;


const ButtonHelp = (props) => {

    return (
        <Box margin={{ right: '1rem' }}>
            <Button
                id={props.id}
                plain
                //autoFocus
                icon={<Radial size='16' color='rgba(0,0,0,0)' />}
                style={{
                    WebkitMaskImage: "url('" + TabDot + "')",
                    WebkitMaskSize: 'contain',
                    WebkitMaskRepeat: 'no-repeat',
                    backgroundColor: props.setSlide.activeSlide === parseInt(props.id) ? '#78C194' : '#DFD4CB',
                }}

                onClick={() =>
                    props.setSlide.setActiveSlide(parseInt(props.id))}

            />
        </Box>
    )
}
import React, { useContext } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';


import { Box, Button, Image, Stack } from 'grommet';

import TB_Constructing from '../../assets/images/buttons/ThemeButtons/TB_Constructing.svg';
import TB_Imagining from '../../assets/images/buttons/ThemeButtons/TB_Imagining.svg';
import TB_Exploring from '../../assets/images/buttons/ThemeButtons/TB_Exploring.svg';
import TB_Innovating from '../../assets/images/buttons/ThemeButtons/TB_Innovating.svg';

import './ThemeButtons.css';
import styled, { css } from 'styled-components';

import { FiberContext } from '../App/App';
import { MediaContext } from '../App/App';

import useSound from 'use-sound';

import turn_Origami from '../../assets/sounds/Turn_Origami.mp3';


export default function ThemeButtons() {

    return (
        <Box fill='vertical'>
            <Box width={"100%"} direction="column" gap={'medium'} >

                <BtnExpand btnName={'Constructing'} star={TB_Constructing} colcol={"#93A4DE"} id={"0"}
                    rotate={0} />
                <BtnExpand btnName={'Imagining'} star={TB_Imagining} colcol={"#F9D46B"} id={"1"}
                    rotate={-Math.PI / 2} />
                <BtnExpand btnName={'Exploring'} star={TB_Exploring} colcol={"#78C194"} id={"2"}
                    rotate={Math.PI} />
                <BtnExpand btnName={'Innovating'} star={TB_Innovating} colcol={"#EDA28C"} id={"3"}
                    rotate={Math.PI / 2} />


            </Box>



        </Box>
    );

}

//font-family: 'Open Sans'; 
const ButtonThemes = styled(Button)
    `
  font-family: 'Source Sans Pro'; 
 font-weight: 700;
 font-weight: bold;
 font-size:1.25rem;
 height:2.5rem;
 color: #000000;
 background-color: #DFD5CD;
 border-radius:2.5rem;
 width: 10rem;
 text-align:left;
 padding-left:1rem;

 ${props => props.location ? 
    css`background-color: ${props.colcol};`
    :
    css`background-color: ${"#DFD5CD"};`

}

`;


function BtnExpand(props) {
    const { stateFiber, dispatchFiber } = useContext(FiberContext);
    const { stateMedia} = useContext(MediaContext);
    const navigate = useNavigate();
    const location = useLocation();


    const [clickSound] = useSound(
        turn_Origami,
        { volume: 0.25,
            soundEnabled: stateMedia.isSoundEnabled
         }
    );


    const onClickOrigamiRotate = (e, value) => {

        dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": (e.target.id).toString(), "name": (e.target.name), "value": value } })
        //console.log("Theme ID: " + e.target.id);
        //console.log(e.target.name);
        //console.log(e);
        //console.log(stateFiber.yRotation);

        navigate("/enter/" + e.target.name);
        clickSound()

    };


    return (
        <Box animation={{ type: 'fadeIn' }} direction='row' gap='9px' align='center' >
            <Stack anchor='center'>

                {/* <Image src={props.starBK} width={0} ></Image>*/}
                <Box width={'55.69px'} height={'55.39px'}
                    
                    onClick={(e) => onClickOrigamiRotate(e, props.rotate)}
                    focusIndicator={false}>
                    <Image src={props.star} fit='contain'

                        name={props.btnName}
                        colcol={props.colcol}
                        id={props.id}

                        alt={props.star}

                    ></Image>
                </Box>
            </Stack>

            <ButtonThemes
                primary
                focusIndicator={false}
                name={props.btnName}
                colcol={props.colcol}
                onClick={(e) => onClickOrigamiRotate(e, props.rotate)}
                location={location.pathname.includes(props.btnName)}
                autoFocus={location.pathname.includes(props.btnName)}
                id={props.id}>{props.btnName}</ButtonThemes>
        </Box>
    )
};




//Component to Bridge from React Three Fiber and DOM - 
//https://standard.ai/blog/introducing-standard-view-and-react-three-fiber-context-bridge/
//https://codesandbox.io/s/react-three-fiber-contextbridge-example-ppz97

import React, { memo, useRef, useContext, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

const ContextListeners = memo(({ contexts, values, children }) =>
  contexts.map((context, index) => (
    <ContextListener key={index} context={context} values={values} index={index}>
      {children}
    </ContextListener>
  ))
)

const ContextListener = memo(({ context, values, index }) => {
  values[index] = useContext(context)
  return null
})

const ContextProviders = memo(({ contexts, values, children }) => {
  const [, update] = useState()

  useFrame(() => update({}))

  if (!contexts || !values) {
    return <>{children}</>
  }

  return contexts.reduce((child, Context, index) => <Context.Provider value={values[index]}>{child}</Context.Provider>, children)
})

const CanvasWithContextBridge = memo(({ contexts, children }) => {
  const values = useRef([])
  return (
    <>
      <ContextListeners contexts={contexts} values={values.current} />
      <Canvas 
        colorManagement
        dpr={Math.max(window.devicePixelRatio, 2)}
        onCreated={state => {
        
        state.gl.antialias=true}}

        style={{zIndex:'20'}}
        >

        <ContextProviders contexts={contexts} values={values.current}>
          {children}
        </ContextProviders>
      </Canvas>
    </>
  )
})

export default CanvasWithContextBridge

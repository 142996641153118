
import React, { useContext, useState } from 'react'
import { Box, Image, Button, Text, Grommet } from 'grommet';
import styled, { css } from 'styled-components';
import { FiberContext } from '../App/App';
import { ObjContext } from '../App/App';
import { AppContext } from '../App/App';
import { MediaContext } from '../App/App';


import { Radial } from 'grommet-icons';
import TopCard from '../../assets/images/Layer_Focus/Card/Top.svg'
import BottomCard from '../../assets/images/Layer_Focus/Card/Bottom.svg'

import TabDot from '../../assets/images/buttons/ObjCard/TabDot.svg'
import useSound from 'use-sound';
import UI_Click from '../../assets/sounds/UI_Click.mp3';


import { textObj } from '../../assets/text/text';

const ObjectCard = (props) => {
    const { state} = useContext(AppContext);
    const { stateFiber} = useContext(FiberContext);
    const { stateObj} = useContext(ObjContext);
    const { stateMedia } = useContext(MediaContext);

    const [selected, SetSelected] = useState(true)

    const theme = {
        box: {
            zIndex: "50",
        }
    };


    const [UIClick] = useSound(
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );


    const BoxText = styled(Text)
        `
     font-family: Source Sans Pro;  
     font-weight: 700;
     font-size: 2.25rem;
     text-align: left;
     
     ${props => props.primary && css`
     font-weight: 400;
     font-size: 1.25rem;
     line-height: 1.8rem;
     `}
     ${props => props.scroll && css`
     ::-webkit-scrollbar-thumb {
        background: ${textObj[stateFiber.idTheme].color};
        }
        overflow:auto;
     `}
    `
    const BoxScroll = styled(Box)
        `
        font-size: 1.25rem;
        line-height: 1.8rem;
    ::-webkit-scrollbar-thumb {
    background: ${textObj[stateFiber.idTheme].color};
    }
    `
    //color: #F7F2EE;
    const ButtonThemes = styled(Button)
        `
font-family: 'Source Sans Pro'; 
outline: none !important;
font-weight: 700;
font-weight: bold;
font-size:1.25rem;
height:2.625rem;
color: #FFFFFF;
text-align:left;
margin-top:2.625rem;

text-decoration:underline;
background-color: ${textObj[stateFiber.idTheme].color};
padding-left:21px;
border-radius:40px;
width: 10rem;
`;



    return (
        <Grommet theme={theme}> 
        <Box
            style={{
                
            zIndex: '30'}} >        
            
            {/*CHANGE TO SWITCH*/}
            
            {(state.isCardOpen) && (
                <>

                 {/*PLAY Theory Card*/}

                    { props.label === 'PlayTheory' ?
                        <Box  height={{ max: '500px' }} width={'100%'} margin={{ right: '20px' }}>

                            <Box>
                                <Image src={TopCard} />
                            </Box>


                            <Box direction="row"  fill='horizontal' width={'100%'} height={{min:'4rem'}} background={{ color: '#F7F2EE' }}>

                                {/*Card Title - 6.25vw*/}
                                
                                <Box justify='center' height={{min:'4rem'}} margin={{left:'6.25vw'}}  > 
                                    <BoxText>
                                        {selected ? stateObj.data.PlayTheory.title : stateObj.data.Provocation.title}
                                    </BoxText>
                                </Box>

                                {/*Card X button*/}
                                {/* <Box align='end' flex  >
                                    ButtonCurrently removed
                                    <Button
                                        style={{
                                            zIndex: '30',
                                            WebkitMaskImage: "url('"+BkgrMenuBt+"')",
                                            WebkitMaskSize: 'contain',
                                            WebkitMaskRepeat: 'no-repeat',
                                            backgroundColor: '#DFD5CD'
                                        }}
                                        icon={<Close size='20' />}
                                        onClick={() =>
                                            dispatch({ type: 'UPDATE_FRAME', isAnimationDone:state.isAnimationDone, isFrameOpen: true, isCardOpen: false, isGalleryOpen: undefined })
                                        } focusIndicator={false} />
                                    
                                </Box>*/}
                            </Box>

                            <Box background={{ color: '#F7F2EE' }}>
                                <Box  pad={{ top: '1rem' }} margin={{left:'6.25vw', right:'2vw'}} >
                                    <BoxScroll
                                        overflow={{ horizontal: 'hidden', vertical: 'auto' }}
                                        pad={{right:'2vw'}}
                                        textAlign='justify' primary>
                                        {selected ? stateObj.data.PlayTheory.text :
                                            stateObj.data.Provocation.text
                                        }
                                    </BoxScroll>
                                </Box>
                            </Box>
                            <Box height={{min:'2rem', max:'2rem'}} background={{ color: '#F7F2EE' }} pad={{ top: '1rem' }} direction='row' gap='small' justify='center'>
                                <Button plain
                                    autoFocus
                                    icon={<Radial size='20' color='rgba(0,0,0,0)' />}
                                    style={{
                                        WebkitMaskImage: "url('" + TabDot + "')",
                                        WebkitMaskSize: 'contain',
                                        WebkitMaskRepeat: 'no-repeat',
                                        backgroundColor: selected ? textObj[stateFiber.idTheme].color : '#DFD4CB',

                                    }}

                                    onClick={() => {
                                        SetSelected(true);
                                        //dispatch({ type: 'UPDATE_FRAME', isFrameOpen: true, isCardOpen: false, isGalleryOpen: undefined })
                                    }
                                    } />

                                <Button plain
                                    autoFocus
                                    icon={<Radial size='20' color='rgba(0,0,0,0)' />}
                                    style={{
                                        WebkitMaskImage: "url('" + TabDot + "')",
                                        WebkitMaskSize: 'contain',
                                        WebkitMaskRepeat: 'no-repeat',
                                        backgroundColor: selected ? '#DFD4CB' : textObj[stateFiber.idTheme].color,


                                    }}

                                    onClick={() => {
                                        SetSelected(false);

                                        //dispatch({ type: 'UPDATE_FRAME', isFrameOpen: true, isCardOpen: false, isGalleryOpen: undefined })
                                    }
                                    } />

                            </Box>
                            <Box><Image src={BottomCard} /></Box>
                        </Box>
                        :


                       

                        <Box height={{ max: '500px' }} margin={{ right: '20px' }}>
                             
                             {/*DISCOVER Card*/}

                            <Box>
                                <Image src={TopCard} />
                            </Box>
                            <Box direction="row" background={{ color: '#F7F2EE' }}>


                                <Box alignContent='center' justify='center' height={{min:'4rem'}} margin={{left:'6.25vw'}}  >
                                    {/*<BoxText>{state.titleToLoad}</BoxText>*/}
                                    <BoxText>
                                        {stateObj.data.Discover.title}
                                    </BoxText>
                                </Box>

                                 {/* BUTTON REMOVED
                                <Box alignContent='center' justify='center' pad={{ left: '2rem' }}>
                                    <Button
                                        style={{
                                            zIndex: '30',
                                            WebkitMaskImage: "url('"+BkgrMenuBt+"')",
                                            WebkitMaskSize: 'contain',
                                            WebkitMaskRepeat: 'no-repeat',
                                            backgroundColor: '#DFD5CD'
                                        }}
                                        icon={<Close size='20' />}
                                        onClick={() =>
                                            dispatch({ type: 'UPDATE_FRAME', isAnimationDone:state.isAnimationDone, isFrameOpen: true, isCardOpen: false, isGalleryOpen: undefined })
                                        } focusIndicator={false} />

                                </Box>*/}







                            </Box>
                            <Box background={{ color: '#F7F2EE' }}>
                                <Box  pad={{ top: '1rem' }} margin={{left:'6.25vw', right:'2vw'}} >
                                    <BoxScroll
                                        overflow={{ horizontal: 'hidden', vertical: 'auto' }}
                                         textAlign='justify' primary>
                                        {stateObj.data.Discover.text}
                                        <Box alignSelf='center'>

                                        {stateObj.data.Discover.link ? 
                                        
                                                //<a href={stateObj.data.Discover.link} target="_blank">{stateObj.data.Discover.text_link}</a>   
                                                
                                    <ButtonThemes
                                    
                                    onClick={(e) => {window.open (stateObj.data.Discover.link); UIClick() }}
                                    focusIndicator={false}
                                    name={stateObj.data.Discover.title_link}
                                    id={1}>{stateObj.data.Discover.title_link}</ButtonThemes>
                                                
                                                
                                                
                                                : null}
                                        
                                        </Box>
                                    </BoxScroll>
                                </Box>
                            </Box>

                            <Box><Image src={BottomCard} /></Box>
                        </Box>
                    }
                </>

            )}
        </Box>
        </Grommet>
    );
};

export default ObjectCard;




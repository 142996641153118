/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF, PresentationControls, Html } from '@react-three/drei'
import themodel from '../../../../../assets/models/focusedObjs/obj_tent.glb';
import ButtonLabel from '../../ButtonLabel/ButtonLabel.js';

import info from '../../../../../assets/images/buttons/focusobj/Info.svg'
import infoHover from '../../../../../assets/images/buttons/focusobj/InfoHover.svg'
import gallery from '../../../../../assets/images/buttons/focusobj/Gallery.svg'
import galleryHover from '../../../../../assets/images/buttons/focusobj/GalleryHover.svg'
import discover from '../../../../../assets/images/buttons/focusobj/Discover.svg'
import discoverHover from '../../../../../assets/images/buttons/focusobj/DiscoverHover.svg'

export default function Model(props) {
    const group = useRef()
    const OBJ = useRef()
    const { nodes, materials } = useGLTF(themodel)
    const [annotation, SetAnnotation] = useState('');




    useEffect(() => {
        if (annotation !== '') {
            console.log(annotation, '- Has changed from the Internal button')
            props.sendOver.SetAnnotation(annotation)
        }
        SetAnnotation('')
    }, [annotation]) // <-- here put the parameter to listen


    return (
        <group ref={group} {...props} dispose={null}>
            <PresentationControls
                global
                speed={3}
                config={{ mass: 1, tension: 170, friction: 56 }}
                zoom={1}
                rotation={[0, -Math.PI / 4, 0]}
                polar={[-Infinity, Infinity]}
                azimuth={[-Infinity, Infinity]}>

                <group name="Scene">
                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        wrapperClass={'page-wrap'}
                        //position={[-0.559, -0.101,0.303]}
                        position={[0.99,-0.22,-0.43]}

                        className="html-story-label" >

                       <ButtonLabel soundOn={props.soundOn}
                            currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: info, hover: infoHover }}
                            annotationToSet={'PlayTheory'}
                            label={'Play Thinking'}
                        ></ButtonLabel>
                    </Html>

                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        position={[0.09, 1.27, -0.19]}
                        className="html-story-label">

                       <ButtonLabel soundOn={props.soundOn}
                        currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: gallery, hover: galleryHover }}
                            annotationToSet={'Gallery_1'}
                            label={'Gallery'}
                        ></ButtonLabel>
                    </Html>

                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        position={[0.59,0.51,0.56]}
                        className="html-story-label"
                    >
                       <ButtonLabel soundOn={props.soundOn}
                            currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: gallery, hover: galleryHover }}
                            annotationToSet={'Gallery_2'}
                            label={'Gallery'}
                        ></ButtonLabel>

                    </Html>

                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        position={[0.46,-0.63,0.31]}
                        className="html-story-label"
                    >

                       <ButtonLabel soundOn={props.soundOn}
                            currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: discover, hover: discoverHover }}
                            annotationToSet={'Discover'}
                            label={'Discover'}
                        ></ButtonLabel>

                    </Html>
        <mesh
          name="obj_tent"
          ref={OBJ}
          castShadow
          receiveShadow
          geometry={nodes.obj_tent.geometry}
          material={materials.Tent}
          scale={1}
          userData={{ name: "obj_tent" }}
          />
          </group>
      </PresentationControls>
  </group>
);
}

useGLTF.preload(themodel);
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF, PresentationControls, Html } from '@react-three/drei'
import themodel from '../../../../../assets/models/focusedObjs/obj_book.glb';
import ButtonLabel from '../../ButtonLabel/ButtonLabel.js';

import info from '../../../../../assets/images/buttons/focusobj/Info.svg'
import infoHover from '../../../../../assets/images/buttons/focusobj/InfoHover.svg'
import gallery from '../../../../../assets/images/buttons/focusobj/Gallery.svg'
import galleryHover from '../../../../../assets/images/buttons/focusobj/GalleryHover.svg'
import discover from '../../../../../assets/images/buttons/focusobj/Discover.svg'
import discoverHover from '../../../../../assets/images/buttons/focusobj/DiscoverHover.svg'

export default function Model(props) {
    const group = useRef()
    const OBJ = useRef()
    const { nodes, materials } = useGLTF(themodel)
    const [annotation, SetAnnotation] = useState('');




    useEffect(() => {
        if (annotation !== '') {
            //console.log(annotation, '- Has changed from the Internal button')
            props.sendOver.SetAnnotation(annotation)
        }
        SetAnnotation('')
    }, [annotation]) // <-- here put the parameter to listen


    return (
        <group ref={group} {...props} dispose={null}>
            <PresentationControls
                global
                speed={3}
                config={{ mass: 1, tension: 170, friction: 56 }}
                zoom={1}
                rotation={[0, -Math.PI / 4, 0]}
                polar={[-Infinity, Infinity]}
                azimuth={[-Infinity, Infinity]}>

                <group name="Scene">
                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        wrapperClass={'page-wrap'}
                        position={[-0.04, 0.02, 0.15]}
                        className="html-story-label" >

                       <ButtonLabel soundOn={props.soundOn}
                            currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: info, hover: infoHover }}
                            //annotationToSet={'Annotation1'}
                            annotationToSet={'PlayTheory'}
                            //label={'Play Theory'}
                            label={'Play Thinking'}
                        ></ButtonLabel>
                    </Html>

                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        position={[0.03, 0, -0.2]}
                        className="html-story-label">

                       <ButtonLabel soundOn={props.soundOn}
                            currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: gallery, hover: galleryHover }}
                            //annotationToSet={'Annotation2'}
                            annotationToSet={'Gallery_1'}
                            label={'Gallery'}
                        ></ButtonLabel>
                    </Html>

                    <Html
                        zIndexRange={[10, 0]}
                        center
                        occlude={[OBJ]}
                        position={[0.05, -0.03, -0.01]}
                        className="html-story-label"
                    >

                       <ButtonLabel soundOn={props.soundOn}
                            currentAnnotation={props.currentAnnotation}
                            sendOver={{ annotation, SetAnnotation }}
                            icons={{ plain: discover, hover: discoverHover }}
                            annotationToSet={'Discover'}
                            label={'Discover'}
                        ></ButtonLabel>

                    </Html>

                    <mesh
                        name="obj_book"
                        ref={OBJ}
                        castShadow
                        receiveShadow
                        geometry={nodes.obj_book.geometry}
                        material={materials.Book}
                        scale={1}
                        userData={{ name: "obj_book" }}
                    />
                </group>
            </PresentationControls>
        </group>
    );
}

useGLTF.preload(themodel);

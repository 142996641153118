import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { Grommet, grommet } from 'grommet';
import { deepMerge } from "grommet/utils";
import { HashRouter } from 'react-router-dom';
import history from './components/Helpers/history'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import { BreakpointProvider } from 'react-socks';
import { BrowserView, MobileView} from 'react-device-detect';

import AppMobile from './components/AppMobile/AppMobile';



const instance = createInstance({
  urlBase: 'https://matomo.cetools.org/',
  siteId: 1,
  //userId: 'UID76903202', // optional, default value: `undefined`.
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 15 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
  }
})




const colorsb = {
  selected: "#a90000",
  focus: "#fff"
}
const theme = deepMerge(grommet, {
  global: {
    colorsb,
    colors: {
      focus: 'neutral-3',
    }
  },

});

ReactDOM.render(
  <MatomoProvider value={instance}>
    <HashRouter history={history}>
      <BreakpointProvider>
        <Grommet theme={theme} full >

          <MobileView>
            <AppMobile />
          </MobileView>


          <BrowserView>
            <App />
          </BrowserView>


        </Grommet>
      </BreakpointProvider>
    </HashRouter>
  </MatomoProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

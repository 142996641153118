export const textObj =

{
  "0": {
    "id": "0",
    "title": "Constructing",
    "color": "#93A4DE",
    "content": "Through constructing, building and making children can control a small part of the world around them. During the first and second national lockdowns, children created safe spaces, like dens, to play in. They made artworks that conveyed hopes, joys and fears. They created small worlds with their toys, often playing out real-life scenarios in a safe setting.",
    "objects": {
      "obj_tent": {
        "id": "Dens",
        "title": "Dens",
        "text": "Children are resourceful when building dens. They are constantly experimenting and designing as they fashion their hideouts from sticks, furniture, cushions and bedsheets. These temporary structures provide children with a dedicated space which they have control over. The task of constructing these spaces often requires teamwork and cooperation, with siblings or other family members all joining in. From the initial thought to the final decoration, building dens and forts can support collaboration, decision-making and creative confidence.",
        "annotations": {
          "PlayTheory": {
            "title": "Dens",
            "text": "Children are resourceful when building dens. They are constantly experimenting and designing as they fashion their hideouts from sticks, furniture, cushions and bedsheets. These temporary structures provide children with a dedicated space which they have control over. The task of constructing these spaces often requires teamwork and cooperation, with siblings or other family members all joining in. From the initial thought to the final decoration, building dens and forts can support collaboration, decision-making and creative confidence."
          },
          "Provocation": {
            "title": "Constructing",
            "text": "During the first and second national lockdowns many of the social spaces for children were suddenly unavailable to them. For much of the day, children were confined to their homes.\nPlay naturally spread to living rooms, bedrooms, and gardens. Temporary structures and hideouts were constructed throughout the home. For some, dens provided shelter for toys, for others these spaces offered safety from the threat of dinosaurs or COVID-19. During a time of insecurity, these dens, forts and tents offered children a creative, safe space that they had agency over."
          },
          "Gallery_1": {
            "title": "Outside Dens",
            "text": "2020 was the sunniest year on record. During sun-soaked days children played outdoors when they could, building an array of structures that represented different things – from theatres and shops, to sitting rooms and dens.",
            "items": [
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "",
                  "citation": "“Den building happened outdoors using a tarp and washing line to make a tent and indoors dens were made using chairs, the washing maiden and sheets etc. No adults were involved.”",
                  "author": "Mary Summers (contributor) and children, 4, 8 and 12 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL169A1/S001"
                }
              },
              {
                "media": "true",
                "file": "72_collecting_adult_3_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Balcony Tent House",
                  "citation": "“We built this tent house on the balcony once and my son asked to do it again and again.”",
                  "author": "Ilaria Geddes (contributor) and child, 2 years old",
                  "location": "Nicosia, Cyprus",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL72A1/S003"
                }
              },
              {
                "media": "true",
                "file": "79_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Outdoor Cinema",
                  "citation": "Using cardboard boxes these children built an outdoor cinema complete with television screen in their garden.",
                  "author": "Stephanie Ray (contributor) and children, 3 and 4 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Cardboard",
                  "PO_Ref": "© Play Observatory PL79A1/S001"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Cardboard Theatre",
                  "citation": "",
                  "author": "Mary Summers (contributor) and child, 8 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Cardboard",
                  "PO_Ref": "© Play Observatory PL169A1/S002"
                }
              }
            ],
            "id": "constructing/dens"
          },
          "Gallery_2": {
            "title": "Inside Dens",
            "text": "During the lockdowns, dens, forts, and caves sprung up in bedrooms and sitting rooms throughout the UK. Some stayed up for hours, whilst others remained in place for days. Constructed from bedding and supported by furniture, these temporary structures offered children a dedicated space of their own.",
            "items": [
              {
                "media": "true",
                "file": "125_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Sitting Room Den",
                  "citation": "“We’ve done LOTS of den building.”",
                  "author": "Rosie Whitehead (contributor) and children, 1 and 3 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Bedding",
                  "PO_Ref": "© Play Observatory PL125A1/S002"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_17_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Hedgehog Cave",
                  "citation": "“My sons have built hundreds of caves during lockdown. Sometimes they have remained in situ for days. The one in the photo is a hedgehog cave with solar panels on it.”",
                  "author": "Parent (contributor) and children, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Bedding",
                  "PO_Ref": "© Play Observatory PL65A1/S017"
                }
              },
              {
                "media": "true",
                "file": "82_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Bedroom Den",
                  "citation": "",
                  "author": "Hannah Young (contributor) and children, 6 and 8 years old",
                  "location": "Cheltenham, UK",
                  "year": "2020-21",
                  "note": "Bedding",
                  "PO_Ref": "© Play Observatory PL82A1/S001"
                }
              },
              {
                "media": "true",
                "file": "172_collecting_young_1_r_childmedia_upload6.jpg",
                "content": {
                  "title": "Blanket Fort",
                  "citation": "",
                  "author": "Eli (contributor), 7 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Bedding",
                  "PO_Ref": "© Play Observatory PL172C1/S001"
                }
              },
              {
                "media": "true",
                "file": "125_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Den with Tunnel",
                  "citation": "",
                  "author": "Rosie Whitehead (contributor) and children, 1 and 3 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Bedding",
                  "PO_Ref": "© Play Observatory PL125A1/S001"
                }
              }
            ],
            "id": "constructing/dens"
          },
          "Discover": {
            "title": "Discover",
            "text": "These children constructed dens for hedgehogs and bears in their homes. They used bedding, clothes-rails, and furniture to make their dens, click the link below for some simple steps to help children build their own dens",
            "text_link":"Activity Den",
            "title_link":"Dens",
            "link":"https://play-observatory.com/user/pages/vexhibitmedia/resources/PinP-ActivitySheet_Den.pdf",
            "id": "0"
          }
        }
      },
      "obj_lego": {
        "id": "Small_World_Play",
        "title": "Small World Play",
        "text": "Small world play is when children use everyday items or toys to create imaginary worlds and narratives. There is a relationship between how a child feels, what they see in the world around them, and what scenarios they enact with their toys. Often children construct meaning by playing out real-life or fantasy scenarios in small world play. They develop their curiosity, stretch their imagination and demonstrate their understanding of the world. This form of play enables children to explore emotions and can support wellbeing by offering a sense of control.",
        "annotations": {
          "PlayTheory": {
            "title": "Small World Play",
            "text": "Small world play is when children use everyday items or toys to create imaginary worlds and narratives. There is a relationship between how a child feels, what they see in the world around them, and what scenarios they enact with their toys. Often children construct meaning by playing out real-life or fantasy scenarios in small world play. They develop their curiosity, stretch their imagination and demonstrate their understanding of the world. This form of play enables children to explore emotions and can support wellbeing by offering a sense of control."
          },
          "Provocation": {
            "title": "Constructing",
            "text": "During the national lockdowns children’s lives and everyday routines were completely disrupted. Changes to schooling, socialising and play, alongside the threat of the virus caused worry and confusion for many children. Over this period children’s small world play sometimes reflected the ‘new normal’ around them. As children controlled the characters and narratives, families started to see play that included toys in masks, references to Joe Wick’s P.E. classes, vaccinations, and hospital stays complete with individual beds and IV drips. Children were processing the unfolding world events in miniature, helping them to regulate emotions in a safe space."
          },
          "Gallery_1": {
            "title": "Reflective Small World Play",
            "text": "A child’s small world play can be a faithful recreation of their environment, enabling them to process the context and events which are happening around them.",
            "items": [
              {
                "media": "true",
                "file": "175_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "TY™ Toy Collection with IV drips, Masks, and in Hospital",
                  "citation": "This child’s toy collection reflected many real-life pandemic experiences, such as wearing masks, getting vaccinated, and hospital treatments.",
                  "author": "Fei Victor Lim (contributor) and child, 8 years old",
                  "location": "Singapore",
                  "year": "2020-21",
                  "note": "Synthetic materials",
                  "PO_Ref": "© Play Observatory 175A1/S001"
                }
              },
              {
                "media": "true",
                "file": "175_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "TY™ Toy Collection with IV drips, Masks, and in Hospital",
                  "citation": "This child’s toy collection reflected many real-life pandemic experiences, such as wearing masks, getting vaccinated, and hospital treatments.",
                  "author": "Fei Victor Lim (contributor) and child, 8 years old",
                  "location": "Singapore",
                  "year": "2020-21",
                  "note": "Synthetic materials",
                  "PO_Ref": "© Play Observatory PL175A1/S001"
                }
              },
              {
                "media": "true",
                "file": "175_collecting_adult_1_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "TY™ Toy Collection with IV drips, Masks, and in Hospital",
                  "citation": "This child’s toy collection reflected many real-life pandemic experiences, such as wearing masks, getting vaccinated, and hospital treatments.",
                  "author": "Fei Victor Lim (contributor) and child, 8 years old",
                  "location": "Singapore",
                  "year": "2020-21",
                  "note": "Synthetic materials",
                  "PO_Ref": "© Play Observatory PL175A1/S001"
                }
              },
              {
                "media": "true",
                "file": "73_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Barbie Lockdown",
                  "citation": "This child’s doll play referenced the wearing of protective masks. “The dolls all had to wear masks to stop them getting ill.”",
                  "author": "Jacqueline Harris (contributor) and child, 7 years old",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "Plastic ",
                  "PO_Ref": "© Play Observatory PL73A1/S001"
                }
              },
              {
                "media": "true",
                "file": "73_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Fitness Coach Joe Wicks's Televised P.E. lessons with Barbie",
                  "citation": "\"7-year-old daughter played a lot with Barbie. She had Barbie joining in with PE with Joe and the rest of the family.\"",
                  "author": "Jacqueline Harris (contributor) and child, 7 years old",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "Plastic ",
                  "PO_Ref": "© Play Observatory PL73A1/S002"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_15_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "LEGO ™ Figure and Vehicle",
                  "citation": "“My boys have played with Lego a lot this year. Mostly building houses for animals, prisons for robbers, trains.”",
                  "author": "Parent (contributor) and children, 1 and 4 years old",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "Plastic ",
                  "PO_Ref": "© Play Observatory PL69A1/S001"
                }
              },
              {
                "media": "true",
                "file": "69_collecting_adult_1_r_adultmedia_upload4.jpg",
                "content": {
                  "title": "LEGO ™ Vehicle",
                  "citation": "",
                  "author": "Parent (contributor) and child, 4 years old",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "Plastic ",
                  "PO_Ref": "© Play Observatory PL69A1/S001"
                }
              },
              {
                "media": "true",
                "file": "69_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Roundabout",
                  "citation": "",
                  "author": "Parent (contributor) and child, 4 years old",
                  "location": "Maastricht, The Netherlands",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL69A1/S001"
                }
              },
              {
                "media": "true",
                "file": "69_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "LEGO™ Medical Centre",
                  "citation": "",
                  "author": "Parent (contributor) and child, 4 years old",
                  "location": "Maastricht, The Netherlands",
                  "year": "2020-21",
                  "note": "Plastic",
                  "PO_Ref": "© Play Observatory PL69A1/S001"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685932993/dc7399c8a8",
                "file": "69_collecting_adult_1_r_adultmedia_upload5.jpg",
                "content": {
                  "title": "Stop-motion animation",
                  "citation": "",
                  "author": "Parent (contributor) and child, 4 years old",
                  "location": "Maastricht, The Netherlands",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "© Play Observatory PL69A1/S001"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Houses",
                  "citation": "“My 4-year-old and I made houses from milk cartons. He noticed that the spout looked a bit like a chimney, so we transformed them into houses and garages. He made a whole street, and now heand his brother (2 years) play with them with their cars and small figures.”",
                  "author": "Parent (contributor) andchildren, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Milk cartons",
                  "PO_Ref": "© Play Observatory PL69A1/S001"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_10_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Houses",
                  "citation": "“My 4-year-old and I made houses from milk cartons. He noticed that the spout looked a bit like a chimney, so we transformed them into houses and garages. He made a whole street, and now heand his brother (2 years) play with them with their cars and small figures.”",
                  "author": "Parent (contributor) andchildren, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Milk cartons",
                  "PO_Ref": "© Play Observatory PL69A1/S010"
                }
              }
            ],
            "id": "constructing/small_world_play"
          },
          "Gallery_2": {
            "title": "Small World Play Order",
            "text": "Crises can prompt anxiety and restrictions for children. To mitigate these factors, many children regain their sense of agency by imposing rules and order in their games and on their playthings.",
            "items": [
              {
                "media": "true",
                "file": "82_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Rules",
                  "citation": "Playing with rules can help children feel they have agency: “My daughter has been categorising toys and other objects by writing lists and dividing the toys between groups. [sic} I wonder whether this categorisation is a type of play influenced by her experience of COVID, reflecting the bubbles we find ourselves in and the rules imposed upon us.”",
                  "author": "Hannah Young\n(contributor) and child, 8 years old",
                  "location": "Cheltenham, UK",
                  "year": "2020-21",
                  "note": "Pen on paper",
                  "PO_Ref": "© Play Observatory PL82A1/S002"
                }
              },
              {
                "media": "true",
                "file": "2014GW1880.jpg",
                "content": {
                  "title": "Toy Monkey and Exercise book",
                  "citation": "Children’s play has often involved creating imaginary worlds and characters. Now in the Young V&A Museum, these toy monkeys are part of a set owned by two brothers in the 1960s. They devised an intricate monkey society, which they recorded in exercise books. The brothers used the monkeys to play out matters which concerned them.",
                  "author": "Owned by the ReddawayBrothers",
                  "location": "UK",
                  "year": "1960s",
                  "note": "Wool, Nylon",
                  "PO_Ref": "V&A Museum no. B.174-2013 [image 2014GW1880] 'Angus', monkey toy, handmade, owned by Richard and Michael Jay Reddaway, British, 1960s, ©Victoria and Albert Museum, London"
                }
              },
              {
                "media": "true",
                "file": "2014GW1883.jpg",
                "content": {
                  "title": "Toy Monkey and Exercise book",
                  "citation": "Children’s play has often involved creating imaginary worlds and characters. Now in the Young V&A Museum, these toy monkeys are part of a set owned by two brothers in the 1960s. They devised an intricate monkey society, which they recorded in exercise books. The brothers used the monkeys to play out matters which concerned them.",
                  "author": "Owned by the ReddawayBrothers",
                  "location": "UK",
                  "year": "1960s",
                  "note": "Wool, Nylon",
                  "PO_Ref": "V&A Museum no. B.169-2013 [image 2014GW1883] ‘Alderbamm', monkey toy, handmade, owned by Richard and Michael Jay Reddaway, British, 1960s, ©Victoria and Albert Museum, London"
                }
              },
              {
                "media": "true",
                "file": "2014GW1884.jpg",
                "content": {
                  "title": "Toy Monkey and Exercise book",
                  "citation": "Children’s play has often involved creating imaginary worlds and characters. Now in the Young V&A Museum, these toy monkeys are part of a set owned by two brothers in the 1960s. They devised an intricate monkey society, which they recorded in exercise books. The brothers used the monkeys to play out matters which concerned them.",
                  "author": "Owned by the ReddawayBrothers",
                  "location": "UK",
                  "year": "1960s",
                  "note": "Wool, Nylon",
                  "PO_Ref": "V&A Museum no. B.172:1-2013 [image 2014GW1883 (4)] 'Appalling', monkey toy wearing green outfit with red cardigan, handmade, owned by Richard and Michael Jay Reddaway, British, 1960s, ©Victoria and Albert Museum, London"
                }
              },
              {
                "media": "true",
                "file": "2013GU1029.jpg",
                "content": {
                  "title": "Toy Monkey and Exercise book",
                  "citation": "Children’s play has often involved creating imaginary worlds and characters. Now in the Young V&A Museum, these toy monkeys are part of a set owned by two brothers in the 1960s. They devised an intricate monkey society, which they recorded in exercise books. The brothers used the monkeys to play out matters which concerned them.",
                  "author": "Owned by the ReddawayBrothers",
                  "location": "UK",
                  "year": "1960s",
                  "note": "Wool, Nylon",
                  "PO_Ref": "V&A Museum no. B.177-2013 [image 2013GU1029] Hard backed exercise book, Vol. II, containing writing by Richard and Michael Jay Reddaway, English, 1960s, ©Victoria and Albert Museum, London"
                }
              }
            ],
            "id": "constructing/small_world_play"
          },
          "Discover": {
            "title": "Discover",
            "text": "Through small world play, these children used their toys to reflect, recreate and make meaning out of crises. Their toys took part in P.E. classes with Joe Wicks, mask-wearing and hospital stays. What scenarios have you seen children recreate?",
            "id": "0"
          }
        }
      },
      "obj_pencil": {
        "id": "Art",
        "title": "Arts and Crafts",
        "text": "The process of making art has a wealth of creative, intellectual and emotional benefits. For children, art offers them an opportunity to explore complex feelings and communicate to others without words. Children’s mark-making, moulding and collages may appear chaotic or random to adults but involve sophisticated processes. They require decision-making and imagination. As children choose colours, shapes, materials and forms, they are constantly experimenting and making judgements. This in turn helps to build creative confidence and the ability to make new connections. Recognising patterns and creating forms help support children's intellectual, social, physical and emotional development.",
        "annotations": {
          "PlayTheory": {
            "title": "Arts and Crafts",
            "text": "The process of making art has a wealth of creative, intellectual and emotional benefits. For children, art offers them an opportunity to explore complex feelings and communicate to others without words. Children’s mark-making, moulding and collages may appear chaotic or random to adults but involve sophisticated processes. They require decision-making and imagination. As children choose colours, shapes, materials and forms, they are constantly experimenting and making judgements. This in turn helps to build creative confidence and the ability to make new connections. Recognising patterns and creating forms help support children's intellectual, social, physical and emotional development."
          },
          "Provocation": {
            "title": "Constructing",
            "text": "During the pandemic the number of children and young people feeling anxious or depressed nearly doubled in the UK.  As adults across the world grappled with the crisis, children were plunged into uncertainty. When children felt confined, arts and crafts activities offered a vehicle for self-expression. For many these activities were a joyful distraction from home-schooling and a chance to create collaboratively with family. Some developed alternative interests and engaged in new artistic endeavours. Across the country, we saw windows, pavements and parks become public platforms for children to display their artworks, assert their ideas and connect with their communities."
          },
          "Gallery_1": {
            "title": "Art to channel or reflect the crisis",
            "text": "Art offers children an outlet to record, process and reflect on crises.",
            "items": [
              {
                "media": "true",
                "file": "2015HL5188.jpg",
                "content": {
                  "title": "Study of a Dover IV",
                  "citation": "Children have used art to respond to crises. Protective equipment, such as masks and hand-sanitisers, from different crises become normalized, everyday objects for children. These items feature strongly in their artworks. Dun’s sketches capture some safety equipment associated with the Second World War.",
                  "author": "Byron Dun, 11 to 14 years old",
                  "location": "Norfolk and Northampton, UK",
                  "year": "1939-42",
                  "note": "Pencil on paper",
                  "PO_Ref": "V&A Museum no. B.117:20-2014 [image 2015HL5188] Drawing of a tank \"Dover IV\", by Bryon Dun, 1942, ©Victoria and Albert Museum, London"
                }
              },
              {
                "media": "true",
                "file": "2015HL5189.jpg",
                "content": {
                  "title": "Study of a Gas Mask",
                  "citation": "Children have used art to respond to crises. Protective equipment, such as masks and hand-sanitisers, from different crises become normalized, everyday objects for children. These items feature strongly in their artworks. Dun’s sketches capture some safety equipment associated with the Second World War.",
                  "author": "Byron Dun, 11 to 14 years old",
                  "location": "Norfolk and Northampton, UK",
                  "year": "1939-42",
                  "note": "Pencil on paper",
                  "PO_Ref": "V&A Museum no. B.117:27-2014 [image 2015HL5189] Drawing of a gas mask, by Bryon Dun, 1939-1942, ©Victoria and Albert Museum, London"
                }
              },
              {
                "media": "true",
                "file": "82_collecting_adult_3_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Drawing of a Girl",
                  "citation": "During the pandemic, this child drew figures with bigger eyes. Their mother wondered “whether her focus on eyes is a consequence of the prevalence of masks around her, inhibiting our senses of smell and taste, with the nose and lips being hidden.”",
                  "author": "Hannah Young (contributor) and child, 8 years old",
                  "location": "Cheltenham, UK",
                  "year": "2020 -21",
                  "note": "Pencil on paper",
                  "PO_Ref": "© Play Observatory PL82A1/S003"
                }
              },
              {
                "media": "true",
                "file": "46_collecting_young_1_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Germs and Hand-Sanitiser",
                  "citation": "“I have been drawing pictures of germs and hand-sanitiser.” \n\nThe health messaging for increased hand-washing to prevent virus transmission became part of children’s everyday language.",
                  "author": "Cadi (contributor), 4 years old",
                  "location": "Pontarddulais, UK",
                  "year": "2020 -21",
                  "note": "Pen on paper",
                  "PO_Ref": "© Play Observatory PL46C1/S001"
                }
              }
            ],
            "id": "constructing/art"
          },
          "Gallery_2": {
            "title": "Art to voice support or fear",
            "text": "Throughout the national lockdowns, children's artworks were publicly displayed across windows, fences, and pavements. These spaces became a public platform for children to voice their wishes and fears and to signal their support for their communities.",
            "items": [
              {
                "media": "true",
                "video": "https://vimeo.com/685900855/60692ff528",
                "file": "189_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "I Don’t Want Life To Go Back To Normal I Want It To Go Back Better ",
                  "citation": "Woody is autistic and COVID-19 made him feel anxious. He expressed his anxiety through the artworks that he produced with his father.",
                  "author": "Woody, 8 years old, and his father, Sonny Adorjan (contributor)",
                  "location": "London, UK",
                  "year": "2020 -21",
                  "note": "Lino print and film",
                  "PO_Ref": "Play Observatory PL189A1/S001 © Woody and Sonny Adorjan"
                }
              },
              {
                "media": "true",
                "file": "177_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Landscape",
                  "citation": "“Everything has corona virus because it's been outside, apart from the medium tree because it stayed inside. The tiny tree is crying because it doesn't understand what's happening.”\n\nChildren expressed their fears caused by the pandemic through art.\n",
                  "author": "Hannah Pattison (contributor) and child, 5 years old",
                  "location": "Peterborough. UK",
                  "year": "2020",
                  "note": "Ink on paper",
                  "PO_Ref": "© Play Observatory PL177A1/S001"
                }
              },
              {
                "media": "true",
                "file": "162_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Chalk Drawing",
                  "citation": "",
                  "author": "Erin Tebben (contributor)",
                  "location": "Columbus, OH",
                  "year": "2020-21",
                  "note": "Chalk on blackboard",
                  "PO_Ref": "© Play Observatory PL162A1/S001"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_11_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Window Decorations",
                  "citation": "",
                  "author": "Parent (contributor) and two children, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Paper",
                  "PO_Ref": "© Play Observatory PL65A1/S011"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_9_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Decorative Stones",
                  "citation": "Rainbow images appeared in various forms during the pandemic, first in Italy and then across the world. In many countries the rainbow symbolises hope, peace and equality. Those pictured here were placed at the kindergarten door and reminded the child how much he missed his friends.",
                  "author": "Parent (contributor) and two children, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Paint on stones",
                  "PO_Ref": "© Play Observatory PL65A1/S011"
                }
              },
              {
                "media": "true",
                "file": "95_collecting_adult_1_r_adultmedia_upload5.jpg",
                "content": {
                  "title": "NHS Poster",
                  "citation": "",
                  "author": "Charlie and Sam, 9 and 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "Paint on paper",
                  "PO_Ref": "© Play Observatory PL95A1/S001"
                }
              },
              {
                "media": "true",
                "file": "34_collecting_young_1_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Pandemic Panic Drawing",
                  "citation": "“I didn't want people to come near the house and act like COVID doesn't exist. So I did chalk drawings of things like a ghost, a bottle of poison, a skull and crossbones and I wrote Keep Out.\nWhen I finished, I felt a bit more secure because the drawings were there and because I talked to my mum about why I was drawing them.”",
                  "author": "Parent (contributor), and child, 10 years old",
                  "location": "Leeds, UK",
                  "year": "2020",
                  "note": "Chalk on pavement",
                  "PO_Ref": "© Play Observatory PL34C1/S001"
                }
              }
            ],
            "id": "constructing/art"
          },
          "Gallery_3": {
            "title": "Art as a creative distraction",
            "text": "Against the uncertainty and the tedium of the national lockdowns, many families found joyful distraction and purpose in arts and crafts activities.",
            "items": [
              {
                "media": "true",
                "file": "64_collecting_young_1_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Town",
                  "citation": "“[I] painted a picture of a town on a big paper with me, my little sister and my mummy. [I felt] happy because we were painting, and my sister was able to do something on the picture too.”",
                  "author": "Alex, 5 years old",
                  "location": "Durham, UK",
                  "year": "2020-21",
                  "note": "Pen on paper",
                  "PO_Ref": "© Play Observatory PL64C1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "",
                  "citation": "“I have enjoyed modelling with sticks and recycling materials. I have been practicing sketching with help from my big sister who studies art.”",
                  "author": "Una (contributor), 8 years old",
                  "location": "Durham, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL104C1/S001"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_2_r_childmedia_upload4.jpg",
                "content": {
                  "title": "Pumpkin Carving",
                  "citation": "",
                  "author": "Toby (contributor), 14 years old",
                  "location": "Newcastle upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Pumpkins",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_2_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Arts and Crafts",
                  "citation": "",
                  "author": "Toby (contributor), 14 years old",
                  "location": "Newcastle upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_2_r_childmedia_upload2.jpg",
                "content": {
                  "title": "Arts and Crafts",
                  "citation": "",
                  "author": "Toby (contributor), 14 years old",
                  "location": "Newcastle upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_2_r_childmedia_upload3.jpg",
                "content": {
                  "title": "Arts and Crafts",
                  "citation": "",
                  "author": "Toby (contributor), 14 years old",
                  "location": "Newcastle upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Making Easter Hats",
                  "citation": "",
                  "author": "Melanie Canfer (contributor) and children, 4 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL48A1/S001"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Painting",
                  "citation": "",
                  "author": "Melanie Canfer (contributor), and child, 4 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Multi-media",
                  "PO_Ref": "© Play Observatory PL48A1/S001"
                }
              }
            ],
            "id": "constructing/art"
          },
          "Discover": {
            "title": "Discover",
            "text": "What might the children in your life draw on the pavement or which pictures would you put up on your windows for passersby to view? Cick the link below to discover more arts and crafts ideas.",
            "text_link":"Activity Window Box",
            "title_link":"Window Box",
            "link":"https://play-observatory.com/user/pages/vexhibitmedia/resources/PinP-ActivitySheet_WindowBox.pdf",
            "id": "0"
          }
        }
      }
    }
  },
  "1": {
    "id": "1",
    "title": "Imagining",
    "color": "#EEC05A",
    "content": "During the pandemic, children used their imaginations to conjure up moments of escapism and comic relief. Making and using props, telling stories and creating performances enabled children to play with reality and immerse themselves in alternative scenarios. Children's ability to craft their evolving play narratives requires emotional and cognitive flexibility, vital for negotiating the challenges of everyday life.",
    "objects": {
      "obj_guitar": {
        "id": "obj_guitar",
        "title": "Performance",
        "text": "Performing is an integral part of childhood and can be a source of personal or shared entertainment, self-expression and affirmation. Learning a script, song or dance routine (or even making one up) builds children’s skills, including resilience and patience​. The process from practice to performance reinforces the link between effort and reward, with the result that performing is often being extremely fulfilling. Creative forms of expression, such as plays, music and dance, can often involve cooperation with others, furthering social skills. Performing can empower children and develop their creative confidence and self-esteem.",
        "annotations": {
          "PlayTheory": {
            "title": "Performance",
            "text": "Performing is an integral part of childhood and can be a source of personal or shared entertainment, self-expression and affirmation. Learning a script, song or dance routine (or even making one up) builds children’s skills, including resilience and patience​. The process from practice to performance reinforces the link between effort and reward, with the result that performing is often being extremely fulfilling. Creative forms of expression, such as plays, music and dance, can often involve cooperation with others, furthering social skills. Performing can empower children and develop their creative confidence and self-esteem."
          },
          "Provocation": {
            "title": "Imagining",
            "text": "During the first and second waves of the pandemic in the UK, may spaces closed, such as music venues, theatres and places of worship. Due to the restricted public access many forms of entertainment moved online to connect with audiences. \nFor many adults and children alike, performing was a joyful distraction during the national lockdowns. They provided a much-needed source of entertainment and vehicle for children’s imagination. Often with more spare time to fill, some children took up new hobbies, such as composing music and poetry, film-making and animation.  Despite the social restrictions, children found ways to reach new audiences and form communities by uploading their content onto TikTok and other social media platforms."
          },
          "Gallery_1": {
            "title": "Performances",
            "text": "In the absence of in-person audiences, many children sought new ways to perform, connect and receive accolades. They tailored their performances for digital mediums and shared their works with online audiences through platforms such as Zoom™, You Tube™, TikTok™ and WhatsApp™.",
            "items": [
              {
                "media": "true_video",
                "video": "https://vimeo.com/684757444/7399919e03",
                "file": "56_collecting_young_2_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Covid Gone",
                  "citation": "This film is a collaboration between Louis and his father. Written in Louis’s own words, it captured the feelings of frustration, fear, joy and family togetherness felt during the first UK-wide lockdown.",
                  "author": "Louis Davis (contributor), 10 years old, and Jonathan Davis",
                  "location": "Taunton, UK ",
                  "year": "2020",
                  "note": "Music Video",
                  "PO_Ref": "Play Observatory PL56C1/S001  © Louis and Jonathan Davis (used with permission)"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685934469/33185fc85d",
                "file": "LostWithoutYou.jpg",
                "content": {
                  "title": "Lost Without You ",
                  "citation": "“My music video 'Lost Without You' was created, directed, edited, and filmed by me. I had to work independently due to the pandemic and lockdown restrictions. I had to use my family to play the roles for video… my music video won a national competition for the Media Magazine- Making Lockdown Creative Competition (2021). The video was awarded best cinematography by the celebrity judge, Eva Riley.”",
                  "author": "Leo Kelly (contributor), 11 years old",
                  "location": "Northampton, UK",
                  "year": "2021",
                  "note": "Music Video",
                  "PO_Ref": "Play Observatory PL150C1/S001, © Leo Kelly"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685936371/7d22d7f95c",
                "file": "154_collecting_young_1_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Band practice",
                  "citation": "“I like to play in a band which I play guitar in.” Music practice continued on digital platforms, despite the social distancing restrictions caused by the first and second national lockdowns.",
                  "author": "Harry Taylor (contributor), 11 years old ",
                  "location": "Clun, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL154C1/S001, © Harry and George Taylor"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685943507/e323b5079c",
                "file": "154_collecting_young_1_r_childmedia_upload4.jpg",
                "content": {
                  "title": "Band practice",
                  "citation": "“I like to play in a band which I play guitar in.” Music practice continued on digital platforms, despite the social distancing restrictions caused by the first and second national lockdowns.",
                  "author": "Harry Taylor (contributor), 11 years old ",
                  "location": "Clun, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL154C1/S001, © Harry and George Taylor"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685947189/fe4a1870b5",
                "file": "154_collecting_young_1_r_childmedia_upload5.jpg",
                "content": {
                  "title": "Band practice",
                  "citation": "“I like to play in a band which I play guitar in.” Music practice continued on digital platforms, despite the social distancing restrictions caused by the first and second national lockdowns.",
                  "author": "Harry Taylor (contributor), 11 years old ",
                  "location": "Clun, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL154C1/S001, © Harry and George Taylor"
                }
              },
              {
                "media": "false",
                "file": "MISSING IMAGE",
                "content": {
                  "title": "",
                  "citation": "“I have been dancing, writing songs, making short films/animations.” ",
                  "author": "Child (contributor), 11 years old ",
                  "location": "Taunton, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL51C1/S001 and PL204A1/S001"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685900060/8b1a0936c5",
                "file": "68_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Drum-Kit",
                  "citation": "“My 1-year-old playing on an adult drum kit at her grandma's house.”",
                  "author": "Mia Clark (contributor) and child, 1 year old",
                  "location": "Carnforth, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "© Play Observatory PL68A1/S001"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685944578/648bb75195",
                "file": "72_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Skater Boy",
                  "citation": "“My son seems to be totally fascinated by skateboarders, can sit in a skatepark and look at them for hours. He tries to imitate the acrobatics, including flipping his skateboard with a foot, which, given his age/height has sometimes ended right on his face!”",
                  "author": "Ilaria Geddes (contributor) and child, 3 years old",
                  "location": "Cyprus",
                  "year": "2021",
                  "note": "Film",
                  "PO_Ref": "© Play Observatory PL72A1/S001"
                }
              },
              {
                "media": "false",
                "file": "MISSING VIDEO",
                "content": {
                  "title": "Thank You Jacket Potato",
                  "citation": "“The 'thank you Jacket Potato' song by the comedian Matt Lucas was a hit in our house when that came out. Both children liked watching it on (I think) YouTube, and we enjoyed singing bits of it at different times and places.”",
                  "author": "Parent (contributor) and children",
                  "location": "North Anston, UK",
                  "year": "2020",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL80A1/S001"
                }
              },
              {
                "media": "true",
                "file": "2012FR1122.jpg",
                "content": {
                  "title": "Eileen Brock: A Born Performer",
                  "citation": "Young V&A holds the Eileen Brock archive, which is packed with theatre programmes, sheet music, costumes and plays. The wealth of material that Eileen collected and created during her childhood, is testament to the important role it served for her and her family.  \n\nGrowing up between the Wars and with the early loss of her father, Eileen and her mother found joint happiness in performing. Together they made costumes, composed music, and acted for their friends and family.  \n\nAcross time and lives, children have found a joyful counterbalance to crises through performing.",
                  "author": "Eileen Brock's childhood costumes, photographs, and music",
                  "location": "London, UK",
                  "year": "1920",
                  "note": "Fairy costume",
                  "PO_Ref": "V&A Museum no. 2012FR1122 "
                }
              },
              {
                "media": "true",
                "file": "2013GH9015.jpg",
                "content": {
                  "title": "Eileen Brock: A Born Performer",
                  "citation": "Young V&A holds the Eileen Brock archive, which is packed with theatre programmes, sheet music, costumes and plays. The wealth of material that Eileen collected and created during her childhood, is testament to the important role it served for her and her family.  \n\nGrowing up between the Wars and with the early loss of her father, Eileen and her mother found joint happiness in performing. Together they made costumes, composed music, and acted for their friends and family.  \n\nAcross time and lives, children have found a joyful counterbalance to crises through performing.",
                  "author": "Eileen Brock's childhood costumes, photographs, and music",
                  "location": "London, UK",
                  "year": "1920",
                  "note": "Fairy costume",
                  "PO_Ref": "V&A Museum no. 2013GH9015"
                }
              },
              {
                "media": "true",
                "file": "2011FB2513.jpg",
                "content": {
                  "title": "Eileen Brock: A Born Performer",
                  "citation": "Young V&A’s holds the Eileen Brock archive, which is packed with theatre programmes, sheet music, costumes and plays. The wealth of material that Eileen collected and created during her childhood, is testament to the important role it served for her and her family.  \n\nGrowing up between the Wars and with the early loss of her father, Eileen and her mother found joint happiness in performing. Together they made costumes, composed music, and acted for their friends and family.  \n\nAcross time and lives, children have found a joyful counterbalance to crises through performing.",
                  "author": "Eileen Brock's childhood costumes, photographs, and music",
                  "location": "London, UK",
                  "year": "1920",
                  "note": "Fairy costume",
                  "PO_Ref": "V&A Museum no. 2011FB2513"
                }
              },
              {
                "media": "true",
                "file": "2012FR1124.jpg",
                "content": {
                  "title": "Eileen Brock: A Born Performer",
                  "citation": "Young V&A’s holds the Eileen Brock archive, which is packed with theatre programmes, sheet music, costumes and plays. The wealth of material that Eileen collected and created during her childhood, is testament to the important role it served for her and her family.  \n\nGrowing up between the Wars and with the early loss of her father, Eileen and her mother found joint happiness in performing. Together they made costumes, composed music, and acted for their friends and family.  \n\nAcross time and lives, children have found a joyful counterbalance to crises through performing.",
                  "author": "Eileen Brock's childhood costumes, photographs, and music",
                  "location": "London, UK",
                  "year": "1920",
                  "note": "Fairy costume",
                  "PO_Ref": "V&A Museum no. 2012FR1124"
                }
              }
            ],
            "id": "imagining/perform"
          },
          "Discover": {
            "title": "Discover",
            "text": "During the lockdowns did you take part in a choir concert on Zoom or use the time to teach yourself guitar? Perhaps you watched musicals or plays online? On stage, on screen or on the sofa, these creative activities stimulated everyday life. Click the link below to Discover some ideas",
            "text_link":"Activity Story",
            "title_link":"Stories",
            "link": "https://play-observatory.com/user/pages/vexhibitmedia/resources/PinP-ActivitySheet_Story.pdf",
            "id": "1"
          }
        }
      },
      "obj_book": {
        "id": "obj_book",
        "title": "Stories",
        "text": "Hearing, reading and creating stories sparks children’s imaginations and develops their cognitive growth. Fictional and non-fictional tales provide a means of immersion indifferent worlds, strengthening children’s understanding of other people, cultures and new experiences. Stories form the foundation stones for building children’s curiosity, memory, and analytical skills and there is no child too young to benefit from this. Hearing stories spoken aloud supports children’s speech and language development. Through creative reading and writing, many children are able to voice their thoughts, as well as make sense of and cope with difficult real-life situations​.",
        "annotations": {
          "PlayTheory": {
            "title": "Stories",
            "text": "Hearing, reading and creating stories sparks children’s imaginations and develops their cognitive growth. Fictional and non-fictional tales provide a means of immersion indifferent worlds, strengthening children’s understanding of other people,\ncultures and new experiences. Stories form the foundation stones for building children’s curiosity, memory, and analytical skills and there is no child too young to benefit from this. Hearing stories spoken aloud supports children’s speech and language development. Through creative reading and writing, many children are able to voice their thoughts, as well as make sense of and cope with difficult real-life situations​."
          },
          "Provocation": {
            "title": "Imagining",
            "text": "Restrictions during the pandemic limited travel and social interactions.\nPublic spaces which normally foster children’s reading, writing and storytelling, such as libraries and community centres, were closed. With schools also closed, many children remained at home, learning online and with their families. Despite these limitations the nation found respite and escapism in stories, causing a boom in the sales of books and audiobooks in 2020-21. Reading offered older children time away from a digital device, and for younger children unable to read independently, story time became a ringfenced family routine (even for those living apart). As well as consuming stories children also used this time to create their own characters and narratives. Developing stories gave voice to their concerns about the pandemic as well as providing creative avenues for their imaginations."
          },
          "Gallery_1": {
            "title": "Stories",
            "text": "Stories form the foundation stones for building children’s curiosity, memory, and analytical skills and there is no child too young to benefit from this.",
            "items": [
              {
                "media": "true",
                "file": "175_collecting_adult_1_r_adultmedia_upload5.jpg",
                "content": {
                  "title": "News Alert",
                  "citation": "This child created their own news stories around the themes of the pandemic. ",
                  "author": "Fei Victor Lim (contributor) and child,  8 years old",
                  "location": "Singapore",
                  "year": "2020-21",
                  "note": "Pencil on paper",
                  "PO_Ref": "© Play Observatory PL175A1/S001"
                }
              },
              {
                "media": "true",
                "file": "175_collecting_adult_1_r_adultmedia_upload4.jpg",
                "content": {
                  "title": "News Alert",
                  "citation": "This child created their own news stories around the themes of the pandemic. ",
                  "author": "Fei Victor Lim (contributor) and child,  8 years old",
                  "location": "Singapore",
                  "year": "2020-21",
                  "note": "Pencil on paper",
                  "PO_Ref": "© Play Observatory PL175A1/S001"
                }
              },
              {
                "media": "true",
                "file": "108_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "All Alone",
                  "citation": "Austin’s poem uses imagery to represent his feelings towards the pandemic.\n“All Alone \n \nAs the waves crashed violently  \nThinking to himself if he would ever  \nFeel someone’s gentle arms around him  \nOnce again. When did he felt comfort.  \nHappiness  \n\nNow at his finale hours \nAt the crack of dawn he \nGrabbed his dingy broken oars \nSat in his boat and hoisted the sail  \nAnd he sailed into the sunlight. \n\nBy Austin” ",
                  "author": "Austin Coolin, 9 years old",
                  "location": "North Walsham, UK",
                  "year": "2020-21",
                  "note": "Poem",
                  "PO_Ref": "© Play Observatory PL108A1/S001"
                }
              },
              {
                "media": "true",
                "file": "172_collecting_young_1_r_childmedia_upload4.jpg",
                "content": {
                  "title": "Zoom Storytime",
                  "citation": "“My Grampa and gramma did storytime on Zoom.”",
                  "author": "Eli (contributor), 7 years old",
                  "location": "North Walsham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL172C1/S001"
                }
              },
              {
                "media": "true",
                "file": "2016JJ3299.jpg",
                "content": {
                  "title": "The Old Man of Peru ",
                  "citation": "This poem from the archives at Young V&A, is from a collection of works and letters by Charles Kirke. As a child, whilst coping with loss and resettlement, Charles created imaginative limericks and stories to provide comic relief for himself, his family and friends.\nThe old man of Peru \n \nThere was an old man of Peru, \nHe thought he would eat in one chew \nA duck with it’s beak  \nOn a mountain peak,  \nThat silly old man of Peru. \n \nby  \nCharles Kirke ",
                  "author": "Charles Kirke, 11 years old",
                  "location": "London, UK",
                  "year": "1950-60",
                  "note": "Poem",
                  "PO_Ref": "V&A Museum no. MOC/KIRK"
                }
              },
              {
                "media": "true",
                "file": "2015HK7080.jpg",
                "content": {
                  "title": "Letters Home",
                  "citation": "Young V&A’s archive is host to a wealth of children’s writings including those of schoolboy, Lionel Hemsley.  Lionel wrote letters packed with stories to amuse his relatives throughout the Second World War and later to fill the gap caused by being at boarding school.",
                  "author": "Lionel Hemsley, 9 years old",
                  "location": "London, UK",
                  "year": "1941",
                  "note": "Correspondence",
                  "PO_Ref": "V&A Museum no. MOC/HEMS/3/ARC"
                }
              },
              {
                "media": "true_video",
                "file": "192_collecting_young_1_r_childmedia_upload1.jpg",
                "video": "https://vimeo.com/685929419/aa343c28e7",
                "content": {
                  "title": "Park Police",
                  "citation": "Filmed and edited by Woody, this film’s narrative playfully sends up the lockdown restrictions.",
                  "author": "Woody Brownell (contributor), 11 years old",
                  "location": "London, UK",
                  "year": "2020",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL192C1/S001  © Woody Brownell "
                }
              },
              {
                "media": "true_video",
                "file": "194_collecting_young_1_r_childmedia_upload1.jpg",
                "video": "https://vimeo.com/685928742/ffe6c1ed66",
                "content": {
                  "title": "All By Myself",
                  "citation": "This child developed a story around the importance of serious play in lockdown.",
                  "author": "Prisha Kular (contributor), 10 years old, and Shaanti Kular,12 years old",
                  "location": "Birmingham, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL194C1/S001  © Prisha and Shaanti Kular"
                }
              },
              {
                "media": "true_video",
                "file": "201_collecting_adult_1_r_adultmedia_upload1.jpg",
                "video": "https://vimeo.com/686275901/f50e8c490b",
                "content": {
                  "title": "Locked in Character",
                  "citation": " “I loved writing an original theatrical monologue, and designing the costume and film poster. I collaborated with musician, Dominic Dawnay, to compose an original soundtrack. This film was independently produced with my twin sister, Sophia Rose Dawkins who stars,… I loved venturing into different narrative style, which was very exciting to direct.” ",
                  "author": "Lauren Dawkins (contributor) adult, 19 years old",
                  "location": "Southampton, UK",
                  "year": "2021",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL201A1/S001 © Lauren Dawkins "
                }
              }
            ],
            "id": "imagining/stories"
          },
          "Discover": {
            "title": "Discover",
            "text": "What stories did you read, listen to, or create during the pandemic? If you could write the next chapter in your story, what adventures would you go on?",
            "id": "1"
          }
        }
      },
      "obj_hats": {
        "id": "obj_hats",
        "title": "Fancy Dress",
        "text": "Imaginative play is part of the everyday fabric of children’s lives. Dressing up is particularly popular and may draw on home-made, adapted or shop-bought costumes, props and playthings. As a form of play it enables children to imagine beyond themselves and their immediate context, deepening engagement in fantasy scenarios, developing empathy and sparking creative exchanges. For many children it is an important vehicle for self-expression, social interaction and safe experimentation with different identities​.",
        "annotations": {
          "PlayTheory": {
            "title": "Fancy Dress",
            "text": "Imaginative play is part of the everyday fabric of children’s lives. Dressing up is particularly popular and may draw on home-made, adapted or shop-bought costumes, props and playthings. As a form of play it enables children to imagine beyond themselves and their immediate context, deepening engagement in fantasy scenarios, developing empathy and sparking creative exchanges. For many children it is an important vehicle for self-expression, social interaction and safe experimentation with different identities​."
          },
          "Provocation": {
            "title": "Imagining",
            "text": "The national lockdowns limited children’s face-to-face contact with their friends, the distance they could travel, and some of the kinds of play they would normally take part in.\nDespite this, many children found opportunities and new possibilities for fancy dress and role play, alone and with siblings, parents and toys. Children were still able to imagine being different characters, and to  experience new places and adventures, at a time when their range of play, playmates and play spaces were limited. Dressing up as a favourite character, like Harry Potter, Disney’s Elsa, or Marvel’s Spiderman, helped children feel empowered. During the pandemic, their play sometimes involved using their powers to challenge and overcome the virus.  Some adults also donned fancy-dress costumes to offer some comic relief for their families and communities during this unpredictable and frightening time."
          },
          "Gallery_1": {
            "title": "Fancy Dress",
            "text": "During the pandemic, many children found dressing a fun distraction which helped them feel empowered.",
            "items": [
              {
                "media": "true",
                "file": "Dinosaur.jpg",
                "content": {
                  "title": "Essential Escapism",
                  "citation": "“During lockdown it was fancy-dress and imaginary play that offered my child essential escapism.”",
                  "author": "Parent and child, 3 years old",
                  "location": "London, UK",
                  "year": "2020",
                  "note": "Photograph ",
                  "PO_Ref": "Photograph © K.Canales"
                }
              },
              {
                "media": "true",
                "file": "Hungry_Caterpillar.jpg",
                "content": {
                  "title": "Essential Escapism",
                  "citation": "“During lockdown it was fancy-dress and imaginary play that offered my child essential escapism.”",
                  "author": "Parent and child, 3 years old",
                  "location": "London, UK",
                  "year": "2020",
                  "note": "Photograph ",
                  "PO_Ref": "Photograph © K.Canales"
                }
              },
              {
                "media": "true",
                "file": "219_collecting_adult_1_r_adultmedia_upload1_c.jpg",
                "content": {
                  "title": "Dragon costume",
                  "citation": "“It was a dragon, 19 March 2021 in state run quarantine in Brisbane, QLD. It was constructed from the brown paper bags our meals were delivered in and the teeth were a milk carton.”",
                  "author": "Michael Bettell (contributor) and child, 2 years old",
                  "location": "Brisbane, Australia",
                  "year": "2021",
                  "note": "Photograph ",
                  "PO_Ref": "© Play Observatory PL219A1/S001 and PL219A1/S002"
                }
              },
              {
                "media": "true",
                "file": "211_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Role Play ",
                  "citation": "“Photographs which capture different moments of our daughter at play, during the pandemic. This first image shows how she has lined up some of her soft toys and sellotaped a tissue over their noses and mouths as a toy face mask and the second is of  hospital play.”",
                  "author": "Heidi Hinder(contributor) and child, 6 years old",
                  "location": "Bristol, UK",
                  "year": "2021",
                  "note": "Photograph ",
                  "PO_Ref": "© Play Observatory PL211A1/S001, © Heidi Hinder"
                }
              },
              {
                "media": "true",
                "file": "211_collecting_adult_1_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "Role Play ",
                  "citation": "“Photographs which capture different moments of our daughter at play, during the pandemic. This first image shows how she has lined up some of her soft toys and sellotaped a tissue over their noses and mouths as a toy face mask and the second is of  hospital play.”",
                  "author": "Heidi Hinder(contributor) and child, 6 years old",
                  "location": "Bristol, UK",
                  "year": "2021",
                  "note": "Photograph ",
                  "PO_Ref": "© Play Observatory PL211A1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Role Play ",
                  "citation": "“My children have been taking on the roles of members of parliament, imitating Boris Johnson in their play. Donald Trump also features! They use these figures to impose rules and authority on each other and their toys. Potentially reflecting the rules imposed upon us all during lockdown and how our freedom has been curtailed. They found this humorous and serious!”",
                  "author": "Hannah Young (contributor) and children, 6 and 8 years old",
                  "location": "Cheltenham, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL82A1/S004"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "",
                  "citation": "“Role play games - often around the theme of 'schools' - a teacher tries to manage a class of unruly children.”",
                  "author": "Parent (contributor) and children, 8, 11 and 14 years old",
                  "location": "Melbourne, Australia",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL132A1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "",
                  "citation": "“A group of girls in nursery playing 'cats'. One girl would be pretending to feed them and the others like to crawl around the floor after her meowing. They are always happy and laughing, really engaged in their play and not needing adult interaction or help.”",
                  "author": "Rebecca Kirby (contributor)",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL142A1/S002"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685930090/5c7c035b1a",
                "file": "148_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Swabbing Sven",
                  "citation": "“COVID testing play, she used a screwdriver as a swab to test 'Sven'. She put the swab in his mouth and then counted as she turned the swab in his nose.”",
                  "author": "James Butler (contributor) and child, 3 years old",
                  "location": "Cheltenham, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "© Play Observatory PL148A1/S001 "
                }
              },
              {
                "media": "true",
                "file": "170_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "",
                  "citation": "Festivities such as Halloween, which involve fancy dress, costumes and socialising were often adapted during the pandemic, with families opting to celebrate at a social distance. \n“My children were excited, especially my 9-year-old. Everyone seemed to be enjoying it”",
                  "author": "Parent (contributor) and child, 9 years old",
                  "location": "Sheffield, UK",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL170A1/S001"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_4_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Tiger",
                  "citation": "My daughter wanted her face painted so her sister helped her be a tiger",
                  "author": "Melanie Canfer (contributor) and children, 4 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S004"
                }
              },
              {
                "media": "true",
                "file": "177_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "A mask for monkey",
                  "citation": "Role playing with toys helped many children become accustomed to the practice of wearing face masks. \n“My child was quite alarmed when she started seeing people wearing masks when out and about. She would stare at them and tell me she felt scared. She found a scrap of material and asked for a little bit of help to make a mask for her favourite cuddly toy. She was laughing and excited when making mask for her favourite toy.”",
                  "author": "Hannah Pattison (contributor) and child, 5 years old",
                  "location": "Peterborough, UK",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL177A1/S001"
                }
              }
            ],
            "id": "imagining/fancydress"
          },
          "Discover": {
            "title": "Discover",
            "text": "Throughout the world people of all ages create and dress up in costumes for traditional festivities and celebrations. If you could make anything, what would your costume be?",
            "id": "1"
          }
        }
      }
    }
  },
  "2": {
    "id": "2",
    "title": "Exploring",
    "color": "#78C194",
    "content": "Outside exploration makes for happier, healthier, and more confident children. During the national lockdowns, children’s access to places and playmates outside the home were restricted. In response, play became more localised. Families began exploring their neighbourhoods during their daily walks. Gardens, window boxes and parks provided fertile ground for seasonal play and some communities devised activities like scavenger hunts for families to enjoy.",
    "objects": {
      "obj_bucket": {
        "id": "obj_bucket",
        "title": "Seasonal Play",
        "text": "Seasonal play includes splashing in puddles, running through sprinklers, or making mud pies, sandcastles and snowmen. It is shaped by the time of year, meaning that it is often messy, loud and physical. It presents children with opportunities to be curious, observant and to take risks – all of which can help build children’s confidence. \nBy playing outdoors with natural materials, children can develop an understanding of the world, build a relationship with wildlife, and experience the process of time. Seasonal play supports a range of learning styles and ways to play outside of the classroom. The values and ethics surrounding seasonal play are aligned with the slow learning movement which celebrates children learning from lived experience and at their own pace. The slow learning movement gained traction during the pandemic as a counter argument against concerns from educational providers about children’s lost learning.",
        "annotations": {
          "PlayTheory": {
            "title": "Seasonal Play",
            "text": "Seasonal play includes splashing in puddles, running through sprinklers, or making mud pies, sandcastles and snowmen. It is shaped by the time of year, meaning that it is often messy, loud and physical. It presents children with opportunities to be curious, observant and to take risks – all of which can help build children’s confidence. \nBy playing outdoors with natural materials, children can develop an understanding of the world, build a relationship with wildlife, and experience the process of time. Seasonal play supports a range of learning styles and ways to play outside of the classroom. The values and ethics surrounding seasonal play are aligned with the slow learning movement which celebrates children learning from lived experience and at their own pace. The slow learning movement gained traction during the pandemic as a counter argument against concerns from educational providers about children’s lost learning."
          },
          "Provocation": {
            "title": "Exploring",
            "text": "During lockdowns, the scope for travel and socialising was limited to a localised level. In response, children shifted their attentions towards their immediate spaces, with gardens and parks taking on greater significance as places to explore and play in.\nWhilst much of lockdown was characterised by routines and repetition, each new season ushered in new possibilities for play. Across private and public outdoor spaces, paddling pools made way for falling leaves and, later, snow. Taking part in seasonal play, such as growing plants on windowsills, or rock pooling, helped forge a greater understanding of the natural world and encouraged their sense of responsibility for it. During the pandemic seasonal play formed a counterbalance to the time spent indoors and provided an opportunity to better explore the natural world."
          },
          "Gallery_1": {
            "title": "Seasonal Play",
            "text": "Gardens, window boxes and parks provided fertile ground for seasonal play during the pandemic",
            "items": [
              {
                "media": "true",
                "file": "65_collecting_adult_3_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Water Play",
                  "citation": "“In the spring and summer, the children played a lot with water in the garden.”",
                  "author": "Parent (contributor) and two children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S003"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_3_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Water Play",
                  "citation": "“In the spring and summer, the children played a lot with water in the garden.”",
                  "author": "Parent (contributor) and two children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S003"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_5_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Fly a Kite",
                  "citation": "Strong winds and bright days provided the perfect conditions for “Flying a kite, as a family.”",
                  "author": "Parent (contributor) and two children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S005"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_3_r_childmedia_upload2.jpg",
                "content": {
                  "title": "Beach Time",
                  "citation": "",
                  "author": "Toby, 14-years-old",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL101C1/S003"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_7_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Outdoor Pursuits",
                  "citation": "“We made much more of an effort to play outdoors and it felt like the children cooperated better when there was a big space around them. They were together when they wanted to be not when they had to be!”",
                  "author": "Mary Summers (contributor) and three children, 4, 8 and 12 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S007"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_7_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Outdoor Pursuits",
                  "citation": "“We made much more of an effort to play outdoors and it felt like the children cooperated better when there was a big space around them. They were together when they wanted to be not when they had to be!”",
                  "author": "Mary Summers (contributor) and three children, 4, 8 and 12 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S007"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_7_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "Outdoor Pursuits",
                  "citation": "“We made much more of an effort to play outdoors and it felt like the children cooperated better when there was a big space around them. They were together when they wanted to be not when they had to be!”",
                  "author": "Mary Summers (contributor) and three children, 4, 8 and 12 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S007"
                }
              },
              {
                "media": "true",
                "file": "160_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Wildlife",
                  "citation": "“Exploring pockets of nature searching for anything alive (in parking lots even) and waterways, from ponds to ditches and curbside gutters and storm drains.”",
                  "author": "Parent (contributor) and children, 4-7 years old",
                  "location": "Houston, USA",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL160A1/S001"
                }
              },
              {
                "media": "true",
                "file": "160_collecting_adult_1_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "Wildlife",
                  "citation": "“Exploring pockets of nature searching for anything alive (in parking lots even) and waterways, from ponds to ditches and curbside gutters and storm drains.”",
                  "author": "Parent (contributor) and children, 4-7 years old",
                  "location": "Houston, USA",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL160A1/S001"
                }
              },
              {
                "media": "true",
                "file": "160_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Wildlife",
                  "citation": "“Exploring pockets of nature searching for anything alive (in parking lots even) and waterways, from ponds to ditches and curbside gutters and storm drains.”",
                  "author": "Parent (contributor) and children, 4-7 years old",
                  "location": "Houston, USA",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL160A1/S001"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_7_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Potion Kitchen",
                  "citation": "Summertime enabled more outdoor play and provided this child with the opportunity to “make potions from garden materials.”",
                  "author": "Melanie Canfer (contributor) and child, 4 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S007"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_7_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Potion Kitchen",
                  "citation": "Summertime enabled more outdoor play and provided this child with the opportunity to “make potions from garden materials.”",
                  "author": "Melanie Canfer (contributor) and child, 4 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S007"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_7_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "Potion Kitchen",
                  "citation": "Summertime enabled more outdoor play and provided this child with the opportunity to “make potions from garden materials.”",
                  "author": "Melanie Canfer (contributor) and child, 4 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S007"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_9_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Not Tyred To Play",
                  "citation": "This family could be found “playing with a tractor tyre, jumping, hiding” during the long summer days.",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S009"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_9_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Not Tyred To Play",
                  "citation": "This family could be found “playing with a tractor tyre, jumping, hiding” during the long summer days.",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S009"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_11_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Tree Swing",
                  "citation": "",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S011"
                }
              },
              {
                "media": "true",
                "file": "172_collecting_young_1_r_childmedia_upload2.jpg",
                "content": {
                  "title": "Spider Tank",
                  "citation": "“We went on nature walks and did tree rubbings We set up a spider tank and caught a spider from the garden.”",
                  "author": "Eli (contributor), 7 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL172C1/S001"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_5_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Squirrel House",
                  "citation": "“Squirrel house building was a regular feature of our park visits. Built independently by my 4-year-old daughter.”",
                  "author": "Mary Summers (contributor) and child, 4 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S005"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_14_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Sand Writing",
                  "citation": "",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S014"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_13_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Building a Snow Fort",
                  "citation": "",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2021",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S013"
                }
              },
              {
                "media": "true",
                "file": "25_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Masked Snowman",
                  "citation": "",
                  "author": "Kate Cowan (contributor)",
                  "location": "London, UK",
                  "year": "2021",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL25A1/S001"
                }
              },
              {
                "media": "true_video",
                "video": "https://vimeo.com/685934028/f87bced78a",
                "file": "57_collecting_young_1_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Muddy Puddles",
                  "citation": "“Exploring the local environment of woods, parks and playgrounds together with mom and dad.”",
                  "author": "Juni, 2 years old",
                  "location": "Stockholm, Sweden",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "© Play Observatory PL57C1/S001"
                }
              }
            ],
            "id": "exploring/seasonalplay"
          },
          "Discover": {
            "title": "Discover",
            "text": "The changing seasons offer up new outdoor play activities from blackberry picking to playing ‘Pooh sticks’, what forms of seasonal play do you enjoy most?",
            "id": "2"
          }
        }
      },
      "obj_boots": {
        "id": "obj_boots",
        "title": "Daily Walks",
        "text": "Outdoor activities offer children a host of social, emotional, health and cognitive benefits. Walking, running, climbing, and crawling outdoors develop children’s gross motor skills and muscular strength. Being in a wide-open space provides them with room to move and express themselves freely which helps to reduce anxiety. By determining their own paths and journeys on outside walks, children can feel more confident and assured in their decision-making processes. Playing outdoors in nature provides children with a sensory immersion that sharpens their perceptions, imaginations and enables a greater appreciation of their environment.",
        "annotations": {
          "PlayTheory": {
            "title": "Daily Walks",
            "text": "Outdoor activities offer children a host of social, emotional, health and cognitive benefits. Walking, running, climbing, and crawling outdoors develop children’s gross motor skills and muscular strength. Being in a wide-open space provides them with room to move and express themselves freely which helps to reduce anxiety. By determining their own paths and journeys on outside walks, children can feel more confident and assured in their decision-making processes. Playing outdoors in nature provides children with a sensory immersion that sharpens their perceptions, imaginations and enables a greater appreciation of their environment."
          },
          "Provocation": {
            "title": "Exploring",
            "text": "Prior to COVID-19, children in Britain experienced less freedom to play independently outdoor than any previous generation. The lockdown restrictions on the length and geographical scope of the public’s outdoor exercise as well as the closing of playgrounds, did little to buck this trend.  However new healthy behaviours did emerge during the first and second lockdowns. For some children, exercising with family on daily walks created healthy habits and offered chances for guided play outside. Daily walks or runs through local areas and public spaces, helped deepen children’s connections to their local communities. Outside activities also provided much needed sensory stimulation away from home-schooling and working."
          },
          "Gallery_1": {
            "title": "Urban Landscapes",
            "text": "Urban areas offer a wealth of public spaces for children and young adults to explore from parks to car parks: “They go for walks around town, push each other in abandoned shopping trolleys, ‘hang out’ at the graveyard.” Parent, Melbourne, Australia (PL312A1/S001). Playing independently in these outside spaces reinforces connections between the child and their setting and builds their confidence.",
            "items": [
              {
                "media": "true",
                "file": "38_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Park Life",
                  "citation": "“Greater use of public parks - every day for a whole year! For the most part, the play equipment was closed off, so children had to look harder within the natural environs of the park and be more creative in their play (whatever the weather). Creating artwork for the enjoyment of others. Gifting. Sharing. Community enjoyment.”",
                  "author": "Cheryl Bailey (contributor) and child, 2 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Chalk drawing",
                  "PO_Ref": "© Play Observatory PL38A1/S002"
                }
              },
              {
                "media": "true",
                "file": "2015HR2759.jpg",
                "content": {
                  "title": "Bandley Hill Adventure Playground",
                  "citation": "Bandley Hill was one of many playgrounds championed by campaigners Donne Buck and Lady Allen of Hurtwood. The pair recognised that after the destruction of the Second World War, children needed a dedicated place to play, as Lady Allen describes:\n“Here, they can play with very dangerous tools, they can create their own houses, their own climbing frames, they can take really dangerous risks and overcome them, and above all it’s a place where they can meet their friends.”\nThe closure of playgrounds during the Covid-19 pandemic restricted children’s play opportunities.",
                  "author": "Donne Buck",
                  "location": "Stevenage, UK",
                  "year": "1968",
                  "note": "Oil paint on canvas",
                  "PO_Ref": "V&A Museum no. B.119-2014 © Donne Buck"
                }
              },
              {
                "media": "true",
                "file": "95_collecting_adult_1_r_adultmedia_upload6.jpg",
                "content": {
                  "title": "Sticking Local",
                  "citation": "“I have seen my boys spending more time playing outside and enjoying time out and about in our local area. On walks they always like to climb a tree, feed the ducks and find the biggest stick they can. In fact, we have a collection by the front door. They have also been keen to join me when I was litter picking in our local area... This meant we could all get some exercise and fresh air and stick to the COVID guidelines.”",
                  "author": "Wendy Riley (contributor) and children, 9 and 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL95A1/S001"
                }
              },
              {
                "media": "true",
                "file": "72_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Climbing Trees",
                  "citation": "“Strictly speaking this was illegal to climb trees as we did it at our local park and during the worst of lockdown in 2020 parks were officially closed. Living in a small flat with a back-then 2½ -years-old, we would have all gone crazy without breaking that rule. During our very long walks (3 hours maximum per exit, one exit per day), he took to climbing trees repeatedly.”",
                  "author": "Ilaria Geddes (contributor) and child, 2 years old",
                  "location": "Nicosia, Cyprus",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL72A1/S002"
                }
              }
            ],
            "id": "exploring/dailywalks"
          },
          "Gallery_2": {
            "title": "Rural Landscapes",
            "text": "During the national lockdowns, many families moved out of the cities. For those families already living there, or those able to make journeys out of urban centres, the countryside offered more space and opportunities to explore the great outdoors. ",
            "items": [
              {
                "media": "true",
                "file": "65_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Forest Play",
                  "citation": "“We spent a lot of time exploring the forests near our house. We went for walks, watched tadpoles develop, climbed trees.”",
                  "author": "Parents (contributor) and two children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S002"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_2_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Wood Instrument",
                  "citation": "“One day our 4-year-old decided to try out how it sounds when you hit the felled tree trunks with sticks.”",
                  "author": "Parents (contributor) and two children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S002"
                }
              },
              {
                "media": "true",
                "file": "80_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Dog Walking",
                  "citation": "“We did a lot of dog walking with the children in the March-June lockdown 2020. We had got a rehomed rescue dog in the autumn of 2019, but until the lockdown, she was walked by me or my husband during the week, mostly on our own. Lockdown changed that as 'dog walk' became one of our daily activities.”",
                  "author": "Parent (contributor) and two children, 6 and 8 years old",
                  "location": "Cheltenham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL80A1/S002"
                }
              },
              {
                "media": "true",
                "file": "48_collecting_adult_3_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Roller Coaster",
                  "citation": "“Running through the woods, pretending to be on a roller coaster.”",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S003"
                }
              },
              {
                "media": "true_video",
                "file": "48_collecting_adult_8_r_adultmedia_upload1.jpg",
                "video": "https://vimeo.com/685948342/8972638c8d",
                "content": {
                  "title": "Outdoor Family Fun",
                  "citation": "As a family they enjoyed “running, laughing, rough play” outside together.",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S008"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_2_r_childmedia_upload6.jpg ",
                "content": {
                  "title": "Family Walks",
                  "citation": "“Running through the woods, pretending to be on a roller coaster.”",
                  "author": "Toby (contributor), 14 years old, and family",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_2_r_childmedia_upload5.jpg",
                "content": {
                  "title": "Family Walks",
                  "citation": "“Running through the woods, pretending to be on a roller coaster.”",
                  "author": "Toby (contributor), 14 years old, and family",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_3_r_childmedia_upload4.jpg",
                "content": {
                  "title": "Family Walks",
                  "citation": "“Running through the woods, pretending to be on a roller coaster.”",
                  "author": "Toby (contributor), 14 years old, and family",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              },
              {
                "media": "true",
                "file": "101_collecting_young_3_r_childmedia_upload3.jpg",
                "content": {
                  "title": "Family Walks",
                  "citation": "“Running through the woods, pretending to be on a roller coaster.”",
                  "author": "Toby (contributor), 14 years old, and family",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL101C1/S002"
                }
              }
            ],
            "id": "exploring/dailywalks"
          },
          "Discover": {
            "title": "Discover",
            "text": "During the national lockdowns did you discover new sights, areas and features in your local area?",
            "id": "2"
          }
        }
      },
      "obj_flag": {
        "id": "obj_flag",
        "title": "Trails",
        "text": "Nature trails, along with scavenger and treasure hunts, encourage children’s playful exploration and active discovery and experience of their worlds. As they seek out objects and move from one marker to the next, they are thinking creatively and testing their problem-solving skills. Although scavenger and treasure hunts can be undertaken alone, they can also provide an extremely rewarding social experience with opportunities for mixed-age and intergenerational engagement. When set outside, these activities provide exciting ways for children to explore and engage with their local and natural environments. Discovering and collecting different forms of wildlife from leaves to stones and learning more about the natural world. Building connections with their habitats helps develop children’s sense of belonging, identity, and empathy.",
        "annotations": {
          "PlayTheory": {
            "title": "Trails",
            "text": "Nature trails, along with scavenger and treasure hunts, encourage children’s playful exploration and active discovery and experience of their worlds. As they seek out objects and move from one marker to the next, they are thinking creatively and testing their problem-solving skills. Although scavenger and treasure hunts can be undertaken alone, they can also provide an extremely rewarding socialexperience with opportunities for mixed-age and intergenerational engagement. When set outside, these activities provide exciting ways for children to explore and engage with their local and natural environments. Discovering and collecting different forms of wildlife from leaves to stones and learning more about the natural world. Building connections with their habitats helps develop children’s sense of belonging, identity, and empathy."
          },
          "Provocation": {
            "title": "Exploring",
            "text": "In the UK’s first and second national lockdowns citizens were required to remain in their local areas. Allowed outside once-a-day for exercise, many of the local walks and routes became well-trodden and over-familiar. The emergence of scavenger hunts and nature trails in parks and across neighbourhoods, reframed and refreshed these well-known spaces. The pursuit of clues or markers encouraged children to explore their areas through a new lens. Often undertaken by families, the creation of these scavenger hunts or nature trails provided not only a series of popular diversions, but it also encouraged a sense of community and a powerful reclamation of public space. At a time when households could not mix, these activities, sent a message of social cohesion and cooperation, helping children feel that they were not alone."
          },
          "Gallery_1": {
            "title": "Trails",
            "text": "The pursuit of nature trails and scavenger hunts encouraged children to explore their areas through a new lens",
            "items": [
              {
                "media": "true",
                "file": "48_collecting_adult_5_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Easter Egg Hunt",
                  "citation": "“Running around the garden looking for Easter egg hunt clues.”",
                  "author": "Melanie Canfer (contributor) and three children, 4, 7 and 9 years old",
                  "location": "Ipswich, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL48A1/S005"
                }
              },
              {
                "media": "true",
                "file": "38_collecting_adult_3_r_adultmedia.jpg",
                "content": {
                  "title": "Fairy Doors",
                  "citation": "In Sheffield, the appearance of public art in forms such as “fairy doors” added to trees and “the creation of COVID snakes of painted stones” were enjoyed by local residents during the national lockdowns.",
                  "author": "Cheryl Bailey (contributor)",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL38A1/S003"
                }
              },
              {
                "media": "true",
                "file": "38_collecting_adult_3_r_adultmedia_2.jpg",
                "content": {
                  "title": "Painted Stones",
                  "citation": "In Sheffield, the appearance of public art in forms such as “fairy doors” added to trees and “the creation of COVID snakes of painted stones” were enjoyed by local residents during the national lockdowns.",
                  "author": "Cheryl Bailey (contributor) and child, 2 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL38A1/S003"
                }
              },
              {
                "media": "true",
                "file": "38_collecting_adult_3_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Nature Trail",
                  "citation": "Nature trails were the source of much “community enjoyment” across the UK during the national lockdowns.",
                  "author": "Cheryl Bailey (contributor)",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL38A1/S003"
                }
              },
              {
                "media": "true",
                "file": "38_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Decorated Tree",
                  "citation": "Public spaces and parks were enlivened by those who “created artwork for the enjoyment of others.”",
                  "author": "Cheryl Bailey (contributor)",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL38A1/S001 and PL38A1/S003"
                }
              },
              {
                "media": "true",
                "file": "38_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Exploring and Collecting",
                  "citation": "“A lot of time was spent exploring new pathways and routes, wooded areas, streams, bridges, undergrowth etc. This led to the finding of sticks, stones, leaves etc. which formed part of their play.”",
                  "author": "Cheryl Bailey (contributor) and two children, 2 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL38A1/S002"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_4_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Collecting Colours",
                  "citation": "“To make the daily family walks a bit more interesting for us all, we made a colour challenge where we painted an egg box and then aimed to collect natural objects on our walk that had these colours.”",
                  "author": "Parents (contributor) and two children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S004"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_9_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Stone painting",
                  "citation": "“During the first lockdown in 2020 my son's kindergarten invited the children to collect stones and paint them. They could leave them on a friend's doorstep and bring one to the kindergarten door. The photo is the collection that were there when we delivered our painted stones.”",
                  "author": "Parent (contributor) and child, 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S009"
                }
              }
            ],
            "id": "exploring/trails"
          },
          "Discover": {
            "title": "Discover",
            "text": "Scavenger hunts and nature trails challenged participants to think creatively, be active and work collaboratively.",
            "id": "2"
          }
        }
      }
    }
  },
  "3": {
    "id": "3",
    "title": "Innovating",
    "color": "#EDA28C",
    "content": "Children are constantly exploring and innovating novel ways to engage with and learn from objects, settings, and the people around them. During the national lockdowns, many children used digital devices to connect socially with friends, family, and peers, and to voice their ideas with a broader audience. For other children, this was a period to disconnect from digital technology and discover interests away from the screen.",
    "objects": {
      "obj_computer": {
        "id": "obj_computer",
        "title": "Connectivity",
        "text": "From birth, children are constantly finding new ways to connect with the world and the people around them. As they mature, they develop more sophisticated ways of communicating, moving from touching and listening, to talking, reading and writing. Where it is available to them, access and understanding of digital technologies have empowered many children to feel connected, informed and engaged. Digital play from texting to playing games, has supported their digital-literacy and boosted their confidence with technology. Many children who are able to, employ a range of technologies to develop content, communicate and express themselves with others.",
        "annotations": {
          "PlayTheory": {
            "title": "Connectivity",
            "text": "From birth, children are constantly finding new ways to connect with the world and the people around them. As they mature, they develop more sophisticated ways of communicating, moving from touching and listening, to talking, reading and writing. Where it is available to them, access and understanding of digital technologies have empowered many children to feel connected, informed and engaged. Digital play from texting to playing games, has supported their digital-literacy and boosted their confidence with technology. Many children who are able to, employ a range of technologies to develop content, communicate and express themselves with others."
          },
          "Provocation": {
            "title": "Innovating",
            "text": "Government restrictions on social gatherings during much of 2020-21 hindered many face-to-face interactions. Families, friends, and social groups with access to them turned to digital technologies to stay connected, whilst unable to meet in person. Many of the children’s social and sports groups in such situations, met over Zoom™ and devised new challenges for home-bound activities. Celebrations, such as birthdays, were reconfigured online with party guests taking part in quizzes, games and kitchen discos. Using digital technologies, children developed content, such as dances on TikTok™ or the HomeCool Kids digital magazine to reach out to audiences beyond the home."
          },
          "Gallery_1": {
            "title": "Connectivity",
            "text": "Children are constantly creating new ways to connect with the world and the people around them.",
            "items": [
              {
                "media": "true",
                "file": "65_collecting_adult_20_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Virtual doll's house play with Grandma",
                  "citation": "“In May 2021 when my children had not seen their grandparents for some time (they live in a different country), they were skyping at least once per week. They developed a game where they played together with Grandma's Playmobil dolls house. Grandma has a figure representing each family member, so they also made-up stories about having family barbecues or going to the shops together. All things we of course couldn't do at that time. In the photo (a screenshot taken during the play) you can see what the kids could see (the house, car and Grandma's hands) and the children crowding around the phone to play.” ",
                  "author": "Parent (contributor) and children, 2 and 6 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2021",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S020"
                }
              },
              {
                "media": "true_video",
                "file": "56_collecting_young_1_r_childmedia_upload2.jpg",
                "video": "https://vimeo.com/685898737/189250389f",
                "content": {
                  "title": "Rhinos 20",
                  "citation": "Sports groups, like Louis’s football team, set goals and challenges to help the team feel connected during lockdowns. Louis is captured by his father attempting and smashing his team’s keepy-uppy challenge.",
                  "author": "Louis Davis (contributor), 10 years old, and Jonathan Davis",
                  "location": "Taunton, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "© Play Observatory PL56C1/S001 © Louis and Jonathan Davis (used with permission)"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_16_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Day of Challenges",
                  "citation": "A friend of mine sent little challenges on the hour between 10am and 5pm and we completed them and sent a video or photo of our efforts. Challenges ranged from learning a dance from a You Tube™ video, to sculpting something you love, to recreating a movie poster. It was a great and sociable way to spend day in lockdown…My mother did one for the rest of us a few weeks later.”",
                  "author": "Parent (contributor) and children, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S016"
                }
              },
              {
                "media": "true",
                "file": "all_covers_april21.jpg",
                "content": {
                  "title": "HomeCool Kids Magazine: Learning, Laughing and Loving in Lockdown",
                  "citation": "Launched in May 2020, this digital magazine was made by children for children and has been running for nine issues to date. \n \nIt was developed by two siblings as a forum for children to share their experiences of home-schooling, the lockdowns and returning to school. It is now run by a child-led editorial board, XazQ, Xai, Mahnoor, Lauren, Aadam, William, Lucas, Prisha, Shaanti, Lucy, Mia and Diya. \n \nHomeCool Kids magazines are packed with poems, interviews, artworks and quizzes, offering readers a range of activities and distractions to help banish boredom. The digital format was chosen as it made the magazine easier to share during the lockdowns.",
                  "author": "Children, 8-14 years old",
                  "location": "Birmingham, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "Play Observatory PL158A1/S001 © HomeCool Kids www.homecoolkids.com (Images not to be reused without permission)"
                }
              },
              {
                "media": "true",
                "file": "HOMECOOL-MAY-5_pgs-HGRES.jpg",
                "content": {
                  "title": "HomeCool Kids Magazine: Learning, Laughing and Loving in Lockdown",
                  "citation": "Launched in May 2020, this digital magazine was made by children for children and has been running for nine issues to date. \n \nIt was developed by two siblings as a forum for children to share their experiences of home-schooling, the lockdowns and returning to school. It is now run by a child-led editorial board, XazQ, Xai, Mahnoor, Lauren, Aadam, William, Lucas, Prisha, Shaanti, Lucy, Mia and Diya. \n \nHomeCool Kids magazines are packed with poems, interviews, artworks and quizzes, offering readers a range of activities and distractions to help banish boredom. The digital format was chosen as it made the magazine easier to share during the lockdowns.",
                  "author": "Children, 8-14 years old",
                  "location": "Birmingham, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "Play Observatory PL158A1/S001 © HomeCool Kids www.homecoolkids.com (Images not to be reused without permission)"
                }
              },
              {
                "media": "true",
                "file": "168_collecting_young_5_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Minecraft funeral",
                  "citation": "“My dad's friend died at Christmas and he was sad that he couldn't go to her funeral because of Lockdown. So I made her a funeral in Minecraft so that we could say goodbye. I wanted to help my dad and make him feel better because he was very sad.”",
                  "author": "Isaac (contributor), 10 years old",
                  "location": "Leeds, UK",
                  "year": "2021",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL168C1/S005"
                }
              },
              {
                "media": "true",
                "file": "168_collecting_young_5_r_childmedia_upload2.jpg",
                "content": {
                  "title": "Minecraft funeral",
                  "citation": "“My dad's friend died at Christmas and he was sad that he couldn't go to her funeral because of Lockdown. So I made her a funeral in Minecraft so that we could say goodbye. I wanted to help my dad and make him feel better because he was very sad.”",
                  "author": "Isaac (contributor), 10 years old",
                  "location": "Leeds, UK",
                  "year": "2021",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL168C1/S005"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Social Media",
                  "citation": "Students set up and used social media and gaming platforms to make friends ahead of starting university. \n \n“Web games were really important: although we would often just chat for ages about all kinds of topics, having them meant that we had more fun and were able to build experiences together rather than rehashing stories and catching up all the time – there wasn’t exactly a ton to catch up on from the first pandemic especially!”",
                  "author": "Aiden Tsen (contributor), 18 years old",
                  "location": "London, UK",
                  "year": "2020",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL83A1/S001"
                }
              },
              {
                "media": "true",
                "file": "154_collecting_young_1_r_childmedia_upload3_still.jpg",
                "content": {
                  "title": "Harry and Friends",
                  "citation": "Connecting with friends over Zoom™",
                  "author": "Harry Taylor (contributor), 11 years old",
                  "location": "Clun, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "Play Observatory PL154C1/S001, © Harry Taylor"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Collaborative Play",
                  "citation": "“My son has balanced 'socialising' virtually using VR headset to allow meeting up and collaborative play. To keep in touch.”",
                  "author": "Parent (contributor) and child, 14 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL92A1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Online Friends",
                  "citation": "“Toby maintained existing friendships, got closer to people he previously knew less well and made new friends. All via online gaming. it was something he looked forward to and enjoyed.”",
                  "author": "Toby, 14 years old, and parent (contributors)",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL101C1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Quiz Team",
                  "citation": "“Group of school friends took turns every week to make general knowledge quizzes, usually on PowerPoint on laptops. We kept a record of scores every week so we could decide an overall winner at the end.”",
                  "author": "Ellie (contributor), 17 years old",
                  "location": "Newcastle Upon Tyne, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL102C1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Keeping in Contact",
                  "citation": "“He spends a lot of time in his room on his computer. He talks for hours to his friends via Discord. He plays online games with them or on his own. He listens to music. He competed in a school online chess tournament yesterday.“",
                  "author": "Parent (contributor) and child, 14 years old",
                  "location": "Melbourne, Australia",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL132A1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Virtual Celebrations",
                  "citation": "“Both my children had lockdown birthdays that involved family members making party food at their houses and Zoom™ calls to celebrate.”",
                  "author": "Shannon Peterson (contributor) and children, 2 and 4 years old",
                  "location": "Auckland, New Zealand",
                  "year": "2020",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL134A1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Gaming Across Countries",
                  "citation": "“It made me feel happy because I can connect with my friend through a game. I have been doing Roblox with a friend in Singapore.”",
                  "author": "Child (contributor), 8 years old",
                  "location": "Taunton, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL51C1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Screen Sharing",
                  "citation": "“Me and my cousin, in London, watched a film together at the same time over Zoom™.”",
                  "author": "Eli, 7 years old",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL172C1/S001"
                }
              },
              {
                "media": "true",
                "file": "170_collecting_adult_6_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Minecraft Celebrations",
                  "citation": "“My eldest son and his younger brother held a Christmas celebration and a Birthday Festival on Minecraft.”",
                  "author": "Parent (contributor) and children, 10 and 15 years old",
                  "location": "Sheffield, UK",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL170A1/S006"
                }
              },
              {
                "media": "true",
                "file": "170_collecting_adult_6_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "Minecraft Celebrations",
                  "citation": "“My eldest son and his younger brother held a Christmas celebration and a Birthday Festival on Minecraft.”",
                  "author": "Parent (contributor) and children, 10 and 15 years old",
                  "location": "Sheffield, UK",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL170A1/S006"
                }
              },
              {
                "media": "true",
                "file": "170_collecting_adult_7_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Minecraft Celebrations",
                  "citation": "“My eldest son and his younger brother held a Christmas celebration and a Birthday Festival on Minecraft.”",
                  "author": "Parent (contributor) and children, 10 and 15 years old",
                  "location": "Sheffield, UK",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL170A1/S006"
                }
              },
              {
                "media": "true",
                "file": "170_collecting_adult_7_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Minecraft Celebrations",
                  "citation": "“My eldest son and his younger brother held a Christmas celebration and a Birthday Festival on Minecraft.”",
                  "author": "Parent (contributor) and children, 10 and 15 years old",
                  "location": "Sheffield, UK",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL170A1/S006"
                }
              }
            ],
            "id": "innovating/connectivity"
          },
          "Discover": {
            "title": "Discover",
            "text": "In what ways did you connect with friends, family, and colleagues during 2020-21? Now social restrictions have been lifted are there some ways of connecting which you’ll continue?",
            "id": "3"
          }
        }
      },
      "obj_saucepan": {
        "id": "obj_saucepan",
        "title": "Learning",
        "text": "Interwoven into every aspect of children’s lives are learning opportunities. Children are constantly discovering, understanding, and mastering new skills which help unlock new social, emotional, cognitive, and physical experiences. Embracing new technologies from wind-up toys to FaceTime™ calls, is part of their socio-cultural learning process. Children born after 2010 are more aware of digital technologies than any previous generation. They can effortlessly shift from and merge both digital and non-digital modes of play. They are often confident using digital technologies, from simple electronic toys to highly sophisticated live streaming video games. These forms of digital play are part of children’s digital-literacy, creativity, and communications skills in the 21st Century.",
        "annotations": {
          "PlayTheory": {
            "title": "Learning",
            "text": "Interwoven into every aspect of children’s lives are learning opportunities. Children are constantly discovering, understanding, and mastering new skills which help unlock new social, emotional, cognitive, and physical experiences. Embracing new technologies from wind-up toys to FaceTime™ calls, is part of their socio-cultural learning process. Children born after 2010 are more aware of digital technologies than any previous generation. They can effortlessly shift from and merge both digital and non-digital modes of play. They are often confident using digital technologies, from simple electronic toys to highly sophisticated live streaming video games. These forms of digital play are part of children’s digital-literacy, creativity, and communications skills in the 21st Century."
          },
          "Provocation": {
            "title": "Innovating",
            "text": "When social distancing restrictions reduced students’ access into classrooms, many learning resources became available online through digital media platforms. Children were quick to adapt. For many, accessing learning materials online helped them develop new skills in their own time and encouraged their sense of agency and creative confidence. However, this was not the case for all children. Many families actively looked for learning opportunities which took them away from technology to provide a counterbalance to their time spent learning online. The pandemic exposed the divide between those households who had digital devices and internet provision and those who did not. Schools and communities sought to plug the gaps, but many children without access to digital resources have missed out on much learning and social support."
          },
          "Gallery_1": {
            "title": "Lessons online",
            "text": "Many children and their families searched the internet to find the resources they needed to learn new skills or share their achievements.",
            "items": [
              {
                "media": "true",
                "file": "172_collecting_young_1_r_childmedia_upload1.jpg",
                "content": {
                  "title": "Online Learning",
                  "citation": "“We used YouTube for a lot of ideas such as science experiments to try. We also went on museum websites to look at online exhibitions and films.”",
                  "author": "Eli (contributor), 7 years old, and parent",
                  "location": "Sheffield, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL172C1/S001"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_6_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Google 3D Animals",
                  "citation": "“When we discovered this, we played a lot looking at all the different animals and moving them around our room. Then taking pictures as if the children were stroking or speaking to the animals.”",
                  "author": "Parent (contributor) and children, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S006"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_6_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Google 3D Animals",
                  "citation": "“When we discovered this, we played a lot looking at all the different animals and moving them around our room. Then taking pictures as if the children were stroking or speaking to the animals.”",
                  "author": "Parent (contributor) and children, 2 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL65A1/S006"
                }
              },
              {
                "media": "true",
                "file": "HOMECOOL-MAY-5_pgs-HGRES3.jpg",
                "content": {
                  "title": "Confessions of a Homeschooled Kid",
                  "citation": "“We have gone from being taught by Masters…to being taught how to type in questions into the search engine of your choice”",
                  "author": "X1 (author), 13 years old",
                  "location": "UK",
                  "year": "2020",
                  "note": "HomeCool Kids Magazine, Issue 1 (May 2020), p.3",
                  "PO_Ref": "© Play Observatory PL158A1/S001  \n© HomeCool Kids www.homecoolkids.com (Images not to be reused without permission)"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Lockdown Lessons",
                  "citation": "“During Lockdown 2021 my son used phonics heroes, Phonics bloom and phonics play to practise phonics. Using Géraldine the giraffe other YouTube™ clips to learn new phonics and other YouTube™ sites to encourage exercise.”",
                  "author": "Sara Chave (contributor) and child, age unknown",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "HomeCool Kids Magazine, Issue 1 (May 2020), p.3",
                  "PO_Ref": "© Play Observatory PL96A1/S001"
                }
              },
              {
                "media": "true",
                "file": "170_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Times Table Rock Stars",
                  "citation": "An example of how digital games can be used to engage children’s interest in school subjects. \n \n“My son aged 10 was asked by his school to play Times Table Rock Stars as part of his learning in Year 5. He created a character called Rex in the game.”",
                  "author": "Parent (contributor) and child, 10 years old",
                  "location": "Sheffield, UK",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL170A1/S002"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Online Classes",
                  "citation": "“Online art classes, bath bomb making Learning new skills such as baking and how to chop.” \n \nThe internet provides materials for a vast array of learning opportunities.",
                  "author": "Emma Tebbutt (contributor) and children, 5 and 8 years old",
                  "location": "Kent, UK",
                  "year": "2020",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL122A1/S001"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_7_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Online Exercise Classes",
                  "citation": "“My 4-year-old got really into doing Cosmic Kids Yoga on YouTube. At the peak of his interest, he was doing it every day!” Children learning yoga through online classes.",
                  "author": "Parent (contributor) and children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL65A1/S007"
                }
              },
              {
                "media": "true",
                "file": "65_collecting_adult_7_r_adultmedia_upload21.jpg",
                "content": {
                  "title": "Online Exercise Classes",
                  "citation": "“My 4-year-old got really into doing Cosmic Kids Yoga on YouTube. At the peak of his interest, he was doing it every day!” Children learning yoga through online classes.",
                  "author": "Parent (contributor) and children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL65A1/S007"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Online Music Class",
                  "citation": "“My 1-year-old's baby music class had to be cancelled because of the first lockdown. The teacher put some videos online and for a while we watched them and played along often. They were songs with actions, most of which we knew from the class. My oldest son (then 4) was delighted to get to see what we'd been doing in class. We would watch on the TV, and each choose an instrument to play along with.”",
                  "author": "Parent (contributor) and children, 1 and 4 years old",
                  "location": "Kusterdingen, Germany",
                  "year": "2020",
                  "note": "Photographs",
                  "PO_Ref": "© Play Observatory PL65A1/S008"
                }
              }
            ],
            "id": "innovating/learning"
          },
          "Gallery_2": {
            "title": "Time Out",
            "text": "The departure from normal life, provided some families with more time to take on new activities and develop new skills, such as baking, playing music and DIY.",
            "items": [
              {
                "media": "true",
                "file": "95_collecting_adult_1_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Time to Bake",
                  "citation": "“At 9 and 11 they both have a few recipes up their sleeve! We would never have had time for this before.”",
                  "author": "Wendy Riley (contributor) and children, 9 and 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL95A1/S001"
                }
              },
              {
                "media": "true",
                "file": "95_collecting_adult_1_r_adultmedia_upload4.jpg",
                "content": {
                  "title": "Time to Bake",
                  "citation": "“At 9 and 11 they both have a few recipes up their sleeve! We would never have had time for this before.”",
                  "author": "Wendy Riley (contributor) and children, 9 and 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL95A1/S001"
                }
              },
              {
                "media": "true",
                "file": "95_collecting_adult_1_r_adultmedia_upload3.jpg",
                "content": {
                  "title": "Time to Bake",
                  "citation": "“At 9 and 11 they both have a few recipes up their sleeve! We would never have had time for this before.”",
                  "author": "Wendy Riley (contributor) and children, 9 and 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL95A1/S001"
                }
              },
              {
                "media": "true_video",
                "file": "154_collecting_young_1_r_childmedia_upload5.jpg",
                "video": "https://vimeo.com/685947189/fe4a1870b5",
                "content": {
                  "title": "Inspired by Johnny B Goode",
                  "citation": "“I like to play in a band which I play guitar in.” \nFor some children music practice provided a reassuring constant in a time of flux.",
                  "author": "Harry Taylor (contributor), 11 years old",
                  "location": "Clun, UK",
                  "year": "2020-21",
                  "note": "Film",
                  "PO_Ref": "Play Observatory PL154C1/S001, © Harry Taylor"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_4_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "DIY",
                  "citation": "“All my children used this time to help up cycle some furniture for their own rooms.” \nThe lockdowns provided some families with time to take up a new hobby, such as DIY and fine new channels to express their creative agency.",
                  "author": "Mary Summers (contributor) and children, 4, 8 and 12 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S004"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_4_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "DIY",
                  "citation": "“All my children used this time to help up cycle some furniture for their own rooms.” \nThe lockdowns provided some families with time to take up a new hobby, such as DIY and fine new channels to express their creative agency.",
                  "author": "Mary Summers (contributor) and children, 4, 8 and 12 years old",
                  "location": "Liverpool, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S004"
                }
              }
            ],
            "id": "innovating/learning"
          },
          "Discover": {
            "title": "Discover",
            "text": "If you had the time, what new interests would you explore or what skills would you like to learn?",
            "id": "3"
          }
        }
      },
      "obj_cards": {
        "id": "obj_cards",
        "title": "New Rules, Old Games",
        "text": "Playing games is a central feature of childhood. Played alone or with others, gamesallow children to test boundaries by following or breaking the rules. Playing with others helps develop children’s social skills, for example, being patient, taking turns, strategising in teams, and negotiating winning and losing. Many children assert their agency and imagination by inventing their own gamesinto which they can determine and impose their own, sometimes evolving rules. Playing games together with friends and family helps build relationships. Giving the participants opportunities for humorous exchanges, and for experiencing shared purpose with a competitive edge",
        "annotations": {
          "PlayTheory": {
            "title": "New Rules, Old Games",
            "text": "Playing games is a central feature of childhood. Played alone or with others, games allow children to test boundaries by following or breaking the rules. Playing with others helps develop children’s social skills, for example, being patient, taking turns, strategising in teams, and negotiating winning andlosing. Manychildren assert their agency and imagination by inventing their own gamesinto which they can determine and impose their own, sometimes evolving rules. Playing games together with friends and family helps build relationships. Giving the participants opportunities for humorous exchanges, and for experiencing shared purpose with a competitive edge"
          },
          "Provocation": {
            "title": "Innovating",
            "text": "Games offered a wealth of benefits to children and their families during the national UK lockdowns and sales of digital and non-digital games boomed. Traditional games such as chess, hide and seek and tag/tig were dusted off and reinvigorated. These games were adapted with new rules or new technologies for social-distancing restrictions, and some were modified with specific reference to the pandemic. For many families playing games offered distraction, new shared experiences, and a healthy vent for frustrations. Friendly competition saw participants honing or developing new skills with children gaining confidence as they often surpassed their parents and peers."
          },
          "Gallery_1": {
            "title": "New Rules, Old Games",
            "text": "Playing games together with friends and family helps build relationships, test boundries and provide a healthy vent for frustrations",
            "items": [
              {
                "media": "true",
                "file": "95_collecting_adult_1_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Cluedo",
                  "citation": "Taking inspiration from popular games, like Cluedo, to develop new creative activities.",
                  "author": "Charlie, 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL95A1/S001"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_8_r_adultmedia_upload2.jpg",
                "content": {
                  "title": "Family Game Time",
                  "citation": "“Playing board games and jigsaws. This we used to replace some screen time and is something we all want to try and keep going!!!”",
                  "author": "Mary Summers (contributor) and children, 11, 7 and 3 years old",
                  "location": "Liverpool, UK",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S008"
                }
              },
              {
                "media": "true",
                "file": "169_collecting_adult_8_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Family Game Time",
                  "citation": "“Playing board games and jigsaws. This we used to replace some screen time and is something we all want to try and keep going!!!”",
                  "author": "Mary Summers (contributor) and children, 11, 7 and 3 years old",
                  "location": "Liverpool, UK",
                  "year": "2020",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL169A1/S008"
                }
              },
              {
                "media": "true",
                "file": "175_collecting_adult_2_r_adultmedia_upload1.jpg",
                "content": {
                  "title": "Our Family in Roblox ",
                  "citation": "“It is great fun, filled with much laughter and friendly jibes at each other because the kids are often better than the parents in the games we play.” Families discovered new way of playing together through computer games.",
                  "author": "Fei Victor Lim (contributor) and children, ages unknown",
                  "location": "Singapore",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL175A1/S002"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Outdoor Entertainment",
                  "citation": "“Playing ‘café’ outdoors with my grandson, I put out the small plastic chairs as we would normally do. He rearranged them so as to make them 2 meters apart, saying it was not allowed to be close together now.”     \n \nMany children adapted their favourite games to take account of the government’s social distancing restrictions.",
                  "author": "Susan Bannister (contributor) and child, 4 years old",
                  "location": "Dronfield, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL71A1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "The Chase",
                  "citation": "“During lockdown we developed a game of chase with our daughter. We played round the ground floor of our house, using our dining table as 'base' and developing tricks and tactics (hiding in the downstairs loo, behind coats etc.). It was joyful and madcap and really helped us get through the period, especially when we were only allowed an hour's exercise a day during Lockdown1.  We've now kept the game going as lockdown has eased and it's part of day-to-day family culture.”",
                  "author": "Parents (contributor) and child, 4-5 years old",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL123A1/S001"
                }
              },
              {
                "media": "true",
                "file": "Girls_running-unsplash.jpg",
                "content": {
                  "title": "COVID Tig/Tag",
                  "citation": "“Children playing a chasing game outdoors at school. One was shouting 'Don't catch COVID!'” \nPlayground games like, Tag/Tig incorporated references to this pandemic or were adapted into ‘Shadow tag’ so that there’s no physical contact between children.",
                  "author": "Rebecca Kirby (contributor)",
                  "location": "London, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Play Observatory PL142A1/S003, Photo by Atoms on Unsplash"
                }
              },
              {
                "media": "true",
                "file": "STG_COM_01.jpg",
                "content": {
                  "title": "Street Tape Games",
                  "citation": "A playable art installation designed around social distancing rules, which subverted the role of social-distancing tapes and signs to create a fun collaborative game.",
                  "author": "Helen Kwok and Chad Toprak",
                  "location": "Melbourne, Australia, and London, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Street Tape Games (Images courtesy of the Street Tape Games team)"
                }
              },
              {
                "media": "true",
                "file": "STG_COM_09.jpg",
                "content": {
                  "title": "Street Tape Games",
                  "citation": "A playable art installation designed around social distancing rules, which subverted the role of social-distancing tapes and signs to create a fun collaborative game.",
                  "author": "Helen Kwok and Chad Toprak",
                  "location": "Melbourne, Australia, and London, UK",
                  "year": "2020-21",
                  "note": "Photograph",
                  "PO_Ref": "© Street Tape Games (Images courtesy of the Street Tape Games team)"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Boardgames Online",
                  "citation": "“Played online Board Game Arena at the beginning of lockdown with friends and grandparents online.”",
                  "author": "Child (contributor), 11 years old",
                  "location": "Nottingham, UK",
                  "year": "2020",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL78C1/S001"
                }
              },
              {
                "media": "false",
                "file": "",
                "content": {
                  "title": "Family Games",
                  "citation": "“We've definitely played more as a family; we started Monopoly evenings during the 1st Lockdown and we have continued with those on weekends.”",
                  "author": "Parents (contributor) and children, 10 and 12 years old",
                  "location": "UK",
                  "year": "2020-21",
                  "note": "",
                  "PO_Ref": "© Play Observatory PL158A1/S001"
                }
              },
              {
                "media": "false",
                "file": "2015HW0903.jpg",
                "content": {
                  "title": "Cricket",
                  "citation": "Across history, children have been inventing their own games and rules for friends and family to enjoy. In the Young V&A collection is a cricket-inspired board game, which was created, developed and played by generations of the Ellerman family.",
                  "author": "Given by S. E. Ellerman",
                  "location": "England, UK",
                  "year": "1930-1980",
                  "note": "https://collections.vam.ac.uk/item/O26174/cricket-board-game/",
                  "PO_Ref": "V&A Museum no. MISC.685:1-1992"
                }
              }
            ],
            "id": "innovating/oldgames"
          },
          "Discover": {
            "title": "Discover",
            "text": "Whether its Monopoly, cricket, or Call of Duty, what are the games that you enjoy? Discover other games to play together here.",
            "id": "3"
          }
        }
      }
    }
  }
}

import React from 'react';
import { Box, Text} from 'grommet';
import { teamText } from '../../assets/text/teamText'
import './Credits.css'
import styled, { css } from 'styled-components';
import Obfuscate from 'react-obfuscate'; //Reduce spam


const BoxText = styled(Text)
    `
font-family: Source Sans Pro;   
color: #E1F19; 
font-weight: 700;
font-size: 2.625rem;
text-align: left;
line-height: 2rem;
margin-bottom:4.6875rem;
padding-left:1rem;

${props => props.primary && css`
font-weight: 400;
padding-right:6rem;
font-size: 1.25rem;
line-height: 1.5625rem;
`}

${props => props.subpar && css`
font-weight: 700;
padding-right:2rem;
font-size: 1.25rem;
line-height: 1.5625rem;
`}

`

const BoxTextTeam = styled(Text)
    `
font-family: Source Sans Pro;   
color: #E1F19; 
text-align: left;
padding-left:1rem;

${props => props.team && css`
font-weight: 700;
font-size: 1.35rem;
margin-bottom:3.5rem;
`}

${props => props.person && css`
font-weight: 700;
font-size: 1.25rem;

`}

${props => props.role && css`
font-weight: 400;
font-size: 1.25rem;
margin-bottom:1rem;
`}

`


const Credits = (props) => {

    return (

        <Box style={{ overflow: 'auto' }} >
            <BoxText key={'About'} >
                About the Exhibition
            </BoxText>

            <BoxText primary>

                Launched in March 2022, Play in the Pandemic is an online exhibition drawing on the work of the <a href="https://play-observatory.com/" target="_blank" rel="noreferrer">Play Observatory</a>.  The exhibition showcases children's artworks, games and films from across the UK that were contributed as part of the Play Observatory project.  It celebrates how children have demonstrated resilience, resourcefulness, and creativity through play, supporting and reflecting their wellbeing during the COVID-19 pandemic in 2020-22. <br /> <br />
                The Play Observatory was funded from October 2020 to March 2022 by the Economic and Social Research Council under the UK Research and Innovation `COVID-19 Rapid Response` fund. The work was undertaken by researchers from <a href="https://www.ucl.ac.uk/ioe/departments-and-centres/centres/ucl-knowledge-lab" target="_blank" rel="noreferrer">IOE, UCL’s Faculty For Education and Society</a>, Prof John Potter, Dr Kate Cowan and Dr Michelle Cannon, <a href="https://www.ucl.ac.uk/bartlett/casa/" target="_blank" rel="noreferrer">The Bartlett Centre for Advanced Spatial Analysis</a>, Dr Valerio Signorelli, and, the <a href="https://www.sheffield.ac.uk/education" target="_blank" rel="noreferrer">School of Education at the University of Sheffield</a>, Dr Yinka Olusoga, Dr Julia Bishop and Dr Cath Bannister. <br /><br />
                The aim of this innovative project was to collect and research children's play during COVID-19. Children, their families, schools, groups and organisations were invited to submit their play experiences to an online survey which was launched in February 2021. Contributions to the survey will subsequently form a digital archive, accessible via the <a href="http://www.play-observatory.com/" target="_blank" rel="noreferrer">project website</a> for the public to explore. <br /> <br />
                In September 2021, the Play Observatory linked up with Katy Canales, Online Exhibition Producer at Young V&A (part of the V&A Museum) with the aim of developing an online exhibition to share this research in a playful and engaging way. Drawing from the National Childhood Collection at YV&A, Katy identified parallels between the recent submissions and items within the collection. Using these examples, the exhibition highlights how children across time have relied on art, design, and performance to make meaning out of personal and global crises. Artist <a href="https://www.marcuswalters.com/" target="_blank" rel="noreferrer">Marcus Walters</a> and immersive web designers, Juliette Coquet and Sindi Breshani from <a href="https://episod.studio/" target="_blank" rel="noreferrer">Episod Studio</a>, were commissioned to develop the stunning and engaging visual treatments and online immersive environments.  The exhibition’s bespoke interface was designed by Dr Valerio Signorelli and the toolkits were supported by the play specialist team at <a href="https://www.gosh.org/the-power-of-play/" target="_blank" rel="noreferrer">Great Ormond Street Hospital</a>.
            </BoxText>

            <BoxText key={'Team'}>
                Team
            </BoxText>



            {/*<Box direction='row'  flex={'grow'}>*/}
            <Box direction='column' gap='large' flex={'grow'}>

                <Box direction='row' width={'100%'}>

                    {teamText.Team.organisation.map((item, index) => {

                        if (index < 2)
                            return (

                                <Box key={index} width={'60%'} >
                                    <BoxTextTeam key={item.name} team >
                                        {item.name}
                                    </BoxTextTeam>
                                    {
                                        item.persons.map((person, index) => {
                                            return (
                                                <>
                                                    <BoxTextTeam key={index + person.name} person>{person.name}</BoxTextTeam>
                                                    <BoxTextTeam key={index} role>
                                                        {(person.role) ? person.role + ', ' : ""}

                                                        {(person.website) ? <a href={person.website} target="_blank" rel="noreferrer">{person.organisation}</a> : person.organisation}

                                                    </BoxTextTeam>

                                                </>
                                            )
                                        }
                                        )
                                    }
                                </Box>
                            )
                    })}
                </Box>




                <Box direction='row' width={'100%'}>

                    {teamText.Team.organisation.map((item, index) => {

                        if (index > 1)
                            return (

                                <Box key={index} width={'50%'} >
                                    <BoxTextTeam key={item.name} team >
                                        {item.name}
                                    </BoxTextTeam>
                                    {
                                        item.persons.map((person, index) => {
                                            return (
                                                <>
                                                    <BoxTextTeam key={index + person.name} person>{person.name}</BoxTextTeam>
                                                    <BoxTextTeam key={index} role>
                                                        {(person.role) ? person.role + ', ' : ""}

                                                        {(person.website) ? <a href={person.website} target="_blank" rel="noreferrer">{person.organisation}</a> : person.organisation}

                                                    </BoxTextTeam>

                                                </>
                                            )
                                        }
                                        )
                                    }
                                </Box>
                            )
                    })}
                </Box>

            </Box>


            <BoxText key={'Copyright'} margin={{ top: '5rem' }} >
                Copyright and Re-Use
            </BoxText>

            <BoxText primary>
                All Play Observatory data (descriptions, images and films) are the joint copyright of University College London and the University of Sheffield, unless otherwise stated on the item label. Where individuals have retained copyright, the item has been used by the Play Observatory and the Young V&A in this exhibition with permission of the copyright holders.<br/><br/>
                If you wish to re-use content from the exhibition, please contact the Play Observatory (<Obfuscate style={{ display: 'inline-block' }} email="info@play-observatory.com" />).<br/><br/>
                <b>Takedown Policy</b> <br/><br/>
                If you consider you hold rights in any of the images on this website, and wish for it to be removed, you can request the removal of the image, or other modification, by writing to <Obfuscate style={{ display: 'inline-block' }} email="info@play-observatory.com" /> putting <b>Play in the Pandemic Exhibition</b> in the subject line, and stating <br/><br/>
                1. your name, email address and phone number<br/>
                2. the nature of the complaint<br/>
                3. a statement that you are the copyright holder or are authorised to act on behalf of the rights holder.<br/><br/>
                We aim to make an initial assessment within 5 working days of receipt. If the complaint warrants further investigation the material will be withdrawn from the repository within 15 working days of receiving your notification while we investigate it fully.

            </BoxText>
        </Box>

    )
}

export default Credits;
import { Button, Tip, Box, Text } from 'grommet';
import React, { useState} from 'react';
import { Radial } from 'grommet-icons';

import useSound from 'use-sound';
import UI_Click from '../../../../assets/sounds/UI_Click.mp3';
import UI_Hover from '../../../../assets/sounds/UI_Hover.mp3';

/*Component for the label on the 3D object.
Props needed:
- props.icons.hover -props.icons.plain //these are the icons (svg) to visualise
- props.label //the text for the tooltip
- props.annotationToSet //this is the annotation linked with the TEXTOBJ json to finalise the request and open the gallery
*/
const ButtonLabel = (props) => {

    const [hovered, setHover] = useState(false);
    const [selected, setSelected] = useState(false);

    const [UIClick] = useSound(
        
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: props.soundOn
        }
    );

    const [hoverSound] = useSound(
        UI_Hover,
        {
            volume: 0.5,
            soundEnabled: props.soundOn
        }
    );

    return (
        <Box overflow={{ horizontal: 'hidden', vertical: 'hidden' }} >
            <Tip
                plain
                dropProps={{ align: { left: 'right' } }}
                content={
                    <Box
                    overflow={{ horizontal: 'hidden', vertical: 'hidden' }}
                        align="center"
                        width={{ min: '8rem' }}
                        background={{ color: "#F7F2EE" }}

                        margin="xsmall"
                        pad="xsmall"
                        round="medium"
                        flex={false}
                    >
                        <Text >{props.label}</Text>
                    </Box>
                }
            >
                <Button plain
                
                    icon={<Radial size='40' color='rgba(0,0,0,0)' />}
                    style={{
                        backgroundImage: hovered || selected ? "url('" + props.icons.hover + "')" : "url('" + props.icons.plain + "')",
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}
                    onClick={(e) => { props.sendOver.SetAnnotation(props.annotationToSet); 
                        //if(props.annotationToSet !== props.currentAnnotation){setSelected(true)}
                        //else{setSelected(false)}; 
                        setSelected(!selected)
                        //console.log(props);
                        UIClick()
                    }}
                    onPointerEnter={(event) => { event.stopPropagation(); setHover(true); hoverSound() }}
                    onPointerLeave={(event) => { event.stopPropagation(); if(!selected){setHover(false);} }}
                />
            </Tip>
        </Box>
    );
}
export default ButtonLabel;
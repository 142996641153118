import React, { useState, Suspense, useContext, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { OrthographicCamera, ContactShadows } from '@react-three/drei';

import Origami from '../Models/Origami/Origami';

import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { ObjContext } from '../App/App';
import { MediaContext } from '../App/App';


import CanvasWithContextBridge from '../ContextBridge/ContextBridge';
import { textObj } from '../../assets/text/text';

import FocusedObject from '../FocusedObject/FocusedObject';


import { useNavigate, useLocation } from 'react-router-dom';

import { Box } from 'grommet'

import useSound from 'use-sound';
import UI_Click from '../../assets/sounds/UI_Click.mp3';
import UI_Hover from '../../assets/sounds/UI_Hover.mp3';






export default function OrigamiAnimation(props) {
    const { stateFiber, dispatchFiber } = useContext(FiberContext);
    const { state, dispatch } = useContext(AppContext);
    const { dispatchObj } = useContext(ObjContext);
    const { stateMedia} = useContext(MediaContext);

    const [UIClick] = useSound(
        UI_Click,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );

    const [hoverSound] = useSound(
        UI_Hover,
        {
            volume: 0.5,
            soundEnabled: stateMedia.isSoundEnabled
        }
    );

    
    function startAnimation(e) {
        setIsRunning(true)
        setIndex(['Animation']);
    }

    function stopAnimation(e) {
        setIsRunning(false)
        setIndex(['Animation']);
    }



    const [isRunning, setIsRunning] = useState(false)
    const [startTouch,setStartTouch]= useState(undefined)
    const [index, setIndex] = useState('')
    const [done, setDone] = useState(false)

    useEffect(() => {
        if (done === true) { 
            dispatch({ type: 'UPDATE_FRAME', isAnimationDone:true, isFrameOpen:undefined, isCardOpen:undefined, isGalleryOpen:undefined });
        }

    }, [done]);

    useEffect(() => {
        if (state.isAnimationDone === true) { 
            navigate("/enter/Constructing"); 
            
        }

    }, [state.isAnimationDone]);


    function UpdateCamera() {

        useFrame((state, delta) => {

            state.camera.updateProjectionMatrix();

            //console.log(state);
            let aspectRatio = state.viewport.width / state.viewport.height;

            state.camera.bottom = -state.viewport.height / 2
            state.camera.top = state.viewport.height / 2
            state.camera.left = (-aspectRatio * state.viewport.height) / 2
            state.camera.right = (aspectRatio * state.viewport.height) / 2

        })

        return null
    }


    const [hovered, setHover] = useState(false)
    const [object, setObject] = useState('')
    const [mousePos, setMousePos]= useState({x:0,y:0,z:0})
    const [labelText, setLabelText]= useState('')

    const navigate = useNavigate();
    const location = useLocation();


    const changeHandler = (e) => {
        e.stopPropagation()

        //console.log(e.object.name);
        setObject(e.object.name)

        //console.log(e);
        if (e.object.name.includes('obj_')) {
            //when the new objects are ready, Use the name of the object to trigger the dispatch (this would fix the the click in another room)
            //console.log(textObj[stateFiber.idTheme].objects);
            //console.log(e.object.name);

            UIClick()

            if (textObj[stateFiber.idTheme].objects[e.object.name]) {
                
                dispatchObj({
                    type: 'OBJ_UPDATE',
                    data: textObj[stateFiber.idTheme].objects[e.object.name].annotations,
                    idTheme: stateFiber.idTheme,
                    idObj: e.object.name,
                    annotation:'PlayTheory'
                })
                
                
                dispatch({ type: 'UPDATE_FRAME', isAnimationDone:true, isFrameOpen:true, isCardOpen:undefined, isGalleryOpen:undefined });

                //console.log(e.object);
//console.log(stateObj);


                //navigate(stateFiber.themeName + "/" + e.object.name);
                navigate('enter/'+stateFiber.themeName + "/" + textObj[stateFiber.idTheme].objects[e.object.name].id);
            }
        }
    };

    
    const hoverHandler = (e) => {
        e.stopPropagation();
        if (e.object.name.includes('obj_')) {
            if (textObj[stateFiber.idTheme].objects[e.object.name]) {
           // console.log(e);
            setHover(!hovered)

            
            
            //console.log(hovered);
            setMousePos({x:e.object.position.x, y:e.object.position.y, z:e.object.position.z})
            setLabelText(textObj[stateFiber.idTheme].objects[e.object.name].title)
            }

        }

    }

    useEffect(() => {
        document.body.style.cursor = hovered ? 'pointer' : 'auto'
      }, [hovered])

      useEffect(() => {
        state.isFrameOpen ?
        dispatchFiber({type: 'HIDE_OBJ', helperScaleOrigami:[0,0,0], helperScaleObj:[1,1,1]})
        :
        dispatchFiber({type: 'HIDE_OBJ', helperScaleOrigami:[1,1,1], helperScaleObj:stateFiber.helperScaleObj})


      }, [state.isFrameOpen])





    return (
        <Box height={'100%'} width={'100%'}

        //Touch Start animation
            onTouchStart={(e) => {
                setStartTouch(e.touches[0].pageX)
                startAnimation(e)
            }}    
            onTouchEnd={(e) => {
                stopAnimation()
            }}

            onMouseDown={(e) => {
                startAnimation()
            }}

            onMouseUp={(e) => {
                stopAnimation()
            }}

        >
            <CanvasWithContextBridge contexts={[AppContext, FiberContext, ObjContext, MediaContext]} >
                <Suspense fallback={<mesh>
                    <sphereBufferGeometry args={[0.0, 0.0, 0.0]} />
                    <meshStandardMaterial />
                    <ambientLight intensity={0.5} />

                </mesh>}>

                    <OrthographicCamera
                        makeDefault
                        // zoom={252}
                        zoom={3.2}
                        near={0}
                        far={10}
                        //position={[0, 7, 7]}
                        position={[0, 2.2, 2]}
                        rotation={[-Math.PI / 4, 0, 0]}
                    />
                    <UpdateCamera />

                    <ambientLight intensity={0.86} />

                    {state.isFrameOpen ?

                        
                        <FocusedObject objectSelected={object} />
                        
                        :
                            null
                    }

                                <Origami 
                                position={[0, 0, 0]}
                                //scale={origamiScale}
                                scale={stateFiber.helperScaleOrigami}

                                location={location.pathname}
                                index={index}
                                isRunning={isRunning}
                                touchStart={startTouch}
                                isDone={{ done, setDone }}
                                grRot={  stateFiber.yRotation+Math.PI / 4} //Math.PI / 4 +
                                mousePosition={mousePos}
                                labelText={labelText}
                                isHover={hovered}
                                

                            onClick={(e) => changeHandler(e)}
                            onPointerLeave={(event) => { hoverHandler(event); 
                                //console.log('Leaving: ' + event.object.name); 
                                }}
                            onPointerEnter={(event) => { hoverHandler(event);  
                                //console.log(event.object); 
                                
                                if (event.object.name.includes('obj_')) {
                                    if (textObj[stateFiber.idTheme].objects[event.object.name]) {hoverSound()}} }}
                            />



                </Suspense>
            </CanvasWithContextBridge>
        </Box>
    )
}



{/* <ContactShadows position={[0, -0.05, 0]} opacity={0.2} scale={5} blur={0.1} far={10} resolution={512} /> */}
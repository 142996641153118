import React, { useContext, useState } from 'react';
import './SideMenu.css';

import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { MediaContext } from '../App/App';
import useSound from 'use-sound';
import UI_Click from '../../assets/sounds/UI_Click.mp3';
import turn_Origami from '../../assets/sounds/Turn_Origami.mp3';

import { useNavigate } from 'react-router-dom';

import { Box, Layer, Text, Heading, Button, Image, Grommet } from 'grommet';
import { Close } from 'grommet-icons';
import MenuToggle from '../../assets/images/buttons/MenuToggle.svg'
import BkgrMenuBt from '../../assets/images/buttons/BkgrMenuBt.svg'




function SideMenu(props) {
  const [open, setOpen] = useState(false);
  const [openCredits, setOpenCredits] = useState(false);

  const { dispatchFiber } = useContext(FiberContext);
  const { state, dispatch } = useContext(AppContext);
  const { stateMedia } = useContext(MediaContext);

  const onOpen = () => setOpen(true);
  const onOpenCredits = () => setOpenCredits(true);

  const onClose = () => { setOpen(undefined); setOpenCredits(undefined) };

  const navigate = useNavigate();
  const onClickOrigamiRotate = (e, value) => {


    //console.log("Theme ID: " + e.target.themeid);
    //console.log(e);

    //console.log(state);
    //UIClick()
    turnOrigami()

    if (e.target.classList[0] === 'bm-subitem') {
      //console.log(e.target.className[1]);
      dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": (e.target.classList[1]).toString(), "name": e.target.name, "value": value } })
      dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });


      setOpen(undefined);
      navigate("/enter/" + e.target.name);
    }
    else {
      dispatchFiber({ type: 'ROTATE_MODEL', data: { "id": (e.target.id).toString(), "name": e.target.name, "value": value } })
      dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });

      dispatchFiber({
        type: 'HIDE_OBJ',
        helperScaleOrigami: [1, 1, 1],
        helperScaleObj: [1, 1, 1],
      })
      
      setOpen(undefined);
      navigate("/enter/" + e.target.name);
    }
  };


  function navigateClick(e) {

    switch (e.target.id) {
      case 'home':
        //console.log('Going Home');
        dispatchFiber({
          type: 'HIDE_OBJ',
          helperScaleOrigami: [1, 1, 1],
          helperScaleObj: [1, 1, 1],
        })
        dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
        UIClick()
        setOpen(undefined);
        navigate("/enter/Constructing");
        break;


      case 'credits':
        dispatchFiber({
          type: 'HIDE_OBJ',
          helperScaleOrigami: [0, 0, 0],
          helperScaleObj: [0, 0, 0],
        })
        dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
        UIClick()
        setOpen(undefined);
        navigate("/about");
        break;

      case 'activities':
        dispatchFiber({
          type: 'HIDE_OBJ',
          helperScaleOrigami: [0, 0, 0],
          helperScaleObj: [0, 0, 0],
        })
        dispatch({ type: 'UPDATE_FRAME', isAnimationDone: true, isFrameOpen: undefined, isCardOpen: undefined, isGalleryOpen: undefined });
        UIClick()
        setOpen(undefined);
        navigate("/activities");
        break;

      default:
        console.log('No Path');
        break;


    }



  }



  const [UIClick] = useSound(
    UI_Click,
    {
      volume: 0.5,
      soundEnabled: stateMedia.isSoundEnabled
    }
  );


  const [turnOrigami] = useSound(
    turn_Origami,
    {
      volume: 0.5,
      soundEnabled: stateMedia.isSoundEnabled
    }
  );



  const theme = {
    layer: {
      zIndex: "25",
    }
  };


  return (
    <Grommet theme={theme}>


      <Box >
        <Box animation={{ type: 'fadeIn' }} style={{ zIndex: '24' }} aria-label="menu" width={'56px'} height={'56px'}
          onClick={(e) => { onOpen(); UIClick() }}
          focusIndicator={false}


        >
          <Image className='icon' src={MenuToggle} fit='contain' alt='menu'></Image>
        </Box>




        {open && (<Layer full={'vertical'}
          position={'start'}
          onClickOutside={onClose}
          onEsc={onClose}
          responsive
          style={{ zIndex: '24' }}
        >

          <Box direction="column" alignContent="stretch" fill="vertical" overflow='auto' style={{ minWidth: '31.25vw' }} >


            <Box align='end' flex background={{ color: "#DFD4CB" }}  >
              <Button
                style={{
                  zIndex: '30',
                  WebkitMaskImage: "url('" + BkgrMenuBt + "')",
                  WebkitMaskSize: 'contain',
                  WebkitMaskRepeat: 'no-repeat',
                  backgroundColor: '#DFD5CD'
                }}
                icon={<Close size='20' />}
                onClick={() => { onClose(); UIClick() }}
                focusIndicator={false}
              />

            </Box>

            <Box id="box-home" className="bm-box" flex="grow" background={{ color: "#DFD4CB" }} justify='center'  >
              <a id="home" className="bm-item" onClick={(e) => navigateClick(e)} >Home</a>
            </Box>

            <Box id="box-0" className="bm-box" flex="grow" background={{ color: "#93A4DE" }} justify='center' >

              <a id="0" name="Constructing" className="bm-item" onClick={(e) => onClickOrigamiRotate(e, 0)}  >Constructing</a>
              {/*     
              <a id="dens" name="Constructing/Dens" className="bm-subitem 0" onClick={(e) => onClickOrigamiRotate(e, 0)}  >Dens</a>
              <a id="smallworldplay" name="Constructing/Small_World_Play" className="bm-subitem 0" onClick={(e) => onClickOrigamiRotate(e, 0)}  >Small World Play</a>
              <a id="artandcraft" name="Constructing/Art" className="bm-subitem 0" onClick={(e) => onClickOrigamiRotate(e, 0)}  >Art and Crafts</a>
        */}
            </Box>

            <Box id="box-1" className="bm-box" flex="grow" background={{ color: "#EEC05A" }} justify='center' >
              <a id="1" name="Imagining" className="bm-item" onClick={(e) => onClickOrigamiRotate(e, Math.PI / 2)}  >Imagining</a>
              {/*
              <a id="stories" name="Imagining/stories" className="bm-subitem 1" onClick={(e) => onClickOrigamiRotate(e, Math.PI / 2)} >Stories</a>
              <a id="fancydress" name="Imagining/fancydress" className="bm-subitem 1" onClick={(e) => onClickOrigamiRotate(e, Math.PI / 2)} >Fancy Dress</a>
              <a id="perform" name="Imagining/perform" className="bm-subitem 1" onClick={(e) => onClickOrigamiRotate(e, Math.PI / 2)} >Perform</a>
            */}
            </Box>

            <Box id="box-2" className="bm-box" flex="grow" background={{ color: "#78C194" }} justify='center' >
              <a id="2" name="Exploring" className="bm-item" onClick={(e) => onClickOrigamiRotate(e, Math.PI)} >Exploring</a>
              {/*
              <a id="trails" name="Exploring/trails" className="bm-subitem 2" onClick={(e) => onClickOrigamiRotate(e, Math.PI)} >Trails</a>
              <a id="seasonalplay" name="Exploring/seasonalplay" className="bm-subitem 2" onClick={(e) => onClickOrigamiRotate(e, Math.PI)} >Seasonal Play</a>
              <a id="dailywalks" name="Exploring/dailywalks" className="bm-subitem 2" onClick={(e) => onClickOrigamiRotate(e, Math.PI)} >Daily Walks</a>
            */}
            </Box>
            <Box id="box-3" className="bm-box" flex="grow" background={{ color: "#EDA28C" }} justify='center' >
              <a id="3" name="Innovating" className="bm-item" onClick={(e) => onClickOrigamiRotate(e, -Math.PI / 2)} >Innovating</a>
              {/*
              <a id="connectivity" name="Innovating/connectivity" className="bm-subitem 3" onClick={(e) => onClickOrigamiRotate(e, -Math.PI / 2)} >Connectivity</a>
              <a id="learning" name="Innovating/learning" className="bm-subitem 3" onClick={(e) => onClickOrigamiRotate(e, -Math.PI / 2)} >Learning</a>
              <a id="newrules" name="Innovating/newrules" className="bm-subitem 3" onClick={(e) => onClickOrigamiRotate(e, -Math.PI / 2)} >New Rules, Old Games</a>
            */}
            </Box>
            <Box id="box-4" className="bm-box" flex="grow" background={{ color: "#DFD4CB" }} justify='center' >
              <a id="credits" className="bm-item" onClick={(e) => navigateClick(e)} >About the Exhibition</a>
            </Box>

            <Box id="box-5" className="bm-box" flex="grow" background={{ color: "#DFD4CB" }} justify='center' >
              <a id="activities" className="bm-item" onClick={(e) => navigateClick(e)} >Activities</a>
            </Box>


          </Box>

          {openCredits && (
            <Layer
              id="credits"
              position="center"
              onClickOutside={onClose}
              onEsc={onClose}
            >
              <Box pad="medium" gap="small" width="large">
                <Heading level={3} margin="none">
                  Credits
                </Heading>
                <Text>PlayObservatory, in collaboration with Young V&A, EpisodStudio, Marcus Walters </Text>

                <Box
                  as="footer"
                  gap="small"
                  direction="row"
                  align="center"
                  justify="end"
                  pad={{ top: 'medium', bottom: 'small' }}
                >
                  <Button label="Close" onClick={onClose} color="dark-3" />
                </Box>
              </Box>
            </Layer>
          )}


        </Layer>)}

      </Box>
    </Grommet>

  );
};

export default SideMenu;

import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../App/App';
import { FiberContext } from '../App/App';
import { ObjContext } from '../App/App';
import { MediaContext } from '../App/App';

import { textObj } from '../../assets/text/text';


import Lego from '../Models/FocusedObjs/Constructing/Lego/Lego';
import Pencil from '../Models/FocusedObjs/Constructing/Pencil/Pencil';
import Tent from '../Models/FocusedObjs/Constructing/Tent/Tent';

import Boots from '../Models/FocusedObjs/Exploring/Boots/Boots';
import Bucket from '../Models/FocusedObjs/Exploring/Bucket/Bucket';
import Flag from '../Models/FocusedObjs/Exploring/Flag/Flag';

import Book from '../Models/FocusedObjs/Imagining/Book/Book';
import Guitar from '../Models/FocusedObjs/Imagining/Guitar/Guitar';
import Hats from '../Models/FocusedObjs/Imagining/Hats/Hats';

import Cards from '../Models/FocusedObjs/Innovating/Cards/Cards';
import Computer from '../Models/FocusedObjs/Innovating/Computer/Computer';
import Saucepan from '../Models/FocusedObjs/Innovating/Saucepan/Saucepan';


const FocusedObject = (props) => {
    const { state, dispatch } = useContext(AppContext);
    const { stateFiber, dispatchFiber } = useContext(FiberContext);
    const { stateObj, dispatchObj } = useContext(ObjContext);
    const { stateMedia, dispatchMedia } = useContext(MediaContext);
    const [annotation, SetAnnotation] = useState('');

    const [index, setIndex] = useState('')

    useEffect(() => {
        if (annotation !== '') {
           // console.log(annotation, '- Has changed')

            dispatchObj({
                type: 'OBJ_UPDATE',
                data: textObj[stateFiber.idTheme].objects[props.objectSelected].annotations,
                idTheme: stateFiber.idTheme,
                idObj: props.objectSelected,
                annotation: annotation,
            })
            dispatch({
                type: 'UPDATE_FRAME',
                isAnimationDone: state.isAnimationDone,
                isFrameOpen: true,
                isCardOpen: true,
                isGalleryOpen: state.isGalleryOpen
            })


            if (annotation.includes('Gallery') && textObj[stateFiber.idTheme].objects[props.objectSelected].annotations[annotation]) {

                dispatch({
                    type: 'UPDATE_FRAME',
                    isAnimationDone: true,
                    isFrameOpen: true,
                    isCardOpen: state.isCardOpen,
                    isGalleryOpen: true
                })

               // console.log(state.isGalleryOpen);


            }
        }
        SetAnnotation('')
    }, [annotation]) // <-- here put the parameter to listen



    return (
        <AppContext.Provider value={{ state, dispatch }}>
            <MediaContext.Provider value={{ stateMedia, dispatchMedia }}>
            <FiberContext.Provider value={{ stateFiber, dispatchFiber }}>
            

                {/* Each object name will load the correct object*/}
                {(props.objectSelected.includes('obj_')) && (

                    (() => {
                       // console.log(stateMedia);
                        switch (props.objectSelected) {
                            case 'obj_lego':
                                return <Lego sendOver={{ annotation, SetAnnotation }}
                                currentAnnotation={stateObj.annotation}
                                soundOn={stateMedia.isSoundEnabled}
                                 position={[0, 0.2, 0]}
                                    //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_pencil':
                                return <Pencil sendOver={{ annotation, SetAnnotation }}
                                soundOn={stateMedia.isSoundEnabled}
                                currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_tent':
                                return <Tent sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation}
                                    position={[0, 0.1, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[0.5, 0.5, 0.5]}
                                    index={index} />
                            case 'obj_boots':
                                return <Boots sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation}
                                    position={[0, 0, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_bucket':
                                return <Bucket sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation}
                                    position={[0, 0, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_flag':
                                return <Flag sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_book':
                                return <Book sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_guitar':
                                return <Guitar sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_hats':
                                return <Hats sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_cards':
                                return <Cards sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_computer':
                                return <Computer sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            case 'obj_saucepan':
                                return <Saucepan sendOver={{ annotation, SetAnnotation }} soundOn={stateMedia.isSoundEnabled} currentAnnotation={stateObj.annotation} position={[0, 0.2, 0]} //scale={[0.3, 0.3, 0.3]} 
                                    //scale={stateFiber.helperScaleObj}
                                    scale={[2.8, 2.8, 2.8]}
                                    index={index} />
                            default:
                                return null
                        }
                    })()

                )}
            </FiberContext.Provider>
            </MediaContext.Provider>
        </AppContext.Provider>
    );
};

export default FocusedObject;